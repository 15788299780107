import type { ReactElement } from 'react';
import React from 'react';

import { isDateFormat, serverDateFormatWithoutTZChange, serverDateToLocal } from '~/DateTimeUtils';

import type { DocumentApiResponse, LegalActionLogApiResponse, NegotiationLogApiResponse } from '../types';

type DateCellProperty =
  | 'due_date'
  | 'date'
  | 'created_at'
  | 'document_date'
  | 'document_date_received'
  | 'datetime_updated';
export const DateCell = ({
  log,
  property,
}: {
  log: NegotiationLogApiResponse | DocumentApiResponse | LegalActionLogApiResponse;
  property: DateCellProperty;
}): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const dateValue = log?.[property];
  if (!dateValue) return <div />;

  // when value is a Date string rather than DateTime ISO string, we should not convert it to local time
  const isDateOnly = !isDateFormat(dateValue);
  return <div>{isDateOnly ? serverDateFormatWithoutTZChange(dateValue) : serverDateToLocal(dateValue)}</div>;
};
