import React, { useEffect, useMemo } from 'react';
import axios from 'axios';

import { useCmsEmbedded } from '~/components/AiChat/hooks/useCmsEmbedded';
import type { ActionFooterProps } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import { InvoiceExecuteButton } from '~/components/AiChat/SideBarDialog/Actions/Action/InvoiceAnalysis/InvoiceExecuteButton';
import { InvoiceRecommendationAnalysis } from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis';
import type {
  CoverageDecisionType,
  InvoiceAnalysisResponse,
  LineItemProps,
} from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis/types';
import type { InvoiceAnalysisRecommendationTaskData } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import mixpanel from '~/components/CmsMain/mixpanel';
import { CLIVE_MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { reportAxiosError } from '~/Utils';

export const InvoiceAnalysisFooter: React.FC<ActionFooterProps> = ({ action, onExecute, isDone }) => {
  const action_data = action.task_data as InvoiceAnalysisRecommendationTaskData;

  const [isOpen, setIsOpen] = React.useState(false);
  const [startTimeout, setStartTimeout] = React.useState<number>(Date.now());
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [filePublicUrl, setFilePublicUrl] = React.useState<string>();
  const { applyInvoiceItem, coInsuranceRates } = useCmsEmbedded();
  const { claim } = useClaim();

  useEffect(() => {
    const fetchFilePublicUrl = async () => {
      try {
        const { data } = await axios.get(
          `/api/v1/claims/${claim.id}/chat/documents/${action_data.claim_document_source_id}/public_file`
        );
        setFilePublicUrl(data?.file_public_url || null);
      } catch (error) {
        await reportAxiosError(error);
      }
    };
    if (!filePublicUrl) {
      action_data.claim_document_source_id ? fetchFilePublicUrl() : setFilePublicUrl(action_data.file_path);
    }
  }, [claim.id, action_data, filePublicUrl]);

  const coverages = (action_data.coverages || []).map(({ coverage_display_name, coverage_key }) => ({
    description: coverage_display_name,
    value: coverage_key,
  }));

  const coverageDecisionMap = useMemo(() => {
    return COVERAGE_DECISIONS.reduce((acc, { value, description }) => {
      acc[value] = description;
      return acc;
    }, {} as Record<string, string>);
  }, []);

  const coverageMap = useMemo(() => {
    return coverages.reduce((acc, { value, description }) => {
      acc[value] = description;
      return acc;
    }, {} as Record<string, string>);
  }, [coverages]);

  const handleAction = async (data: InvoiceAnalysisResponse) => {
    try {
      setIsSubmitting(true);

      const invoiceAnalysisUpdate = { ...data, task_id: action_data.task_id };
      invoiceAnalysisUpdate.task_id = action_data.task_id;
      invoiceAnalysisUpdate.line_items = invoiceAnalysisUpdate.line_items.map((item) => ({
        ...item,
        coverage_key: item.coverage || null,
      }));

      await axios.post(
        `/api/v1/claims/${claim.id}/ai_recommendations/invoice_analysis/${action_data.analysis_results_id}/update_results`,
        { invoice_analysis: invoiceAnalysisUpdate }
      );
    } catch (error) {
      await reportAxiosError(error);

      throw Error('Failed to update invoice analysis results');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);

    mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_INVOICE_PAGE_VIEWED, {
      time: Date.now() - startTimeout,
    });
  };

  return (
    <div className="flex">
      <InvoiceExecuteButton
        onExecute={() => {
          setIsOpen(true);
          setStartTimeout(Date.now());

          mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_OPEN_CLICKED, {
            is_done: isDone,
            type: AI_ACTION_TYPES.INVOICE_ANALYSIS_RECOMMENDATION,
          });
        }}
        isDone={isDone}
      />
      <InvoiceRecommendationAnalysis
        disabled={isDone || isSubmitting}
        open={isOpen}
        dialogTitle={`Claim ${claim.claim_id_display}`}
        invoiceData={{
          date: action_data.invoice_date,
          number: action_data.invoice_number,
          vendor: action_data.vendor_name,
          total_amount: action_data.total_amount,
        }}
        lineItems={action_data.line_items.map(
          (item): LineItemProps => ({
            id: item.id,
            description: item.description,
            coverage_decision: coverageDecisionMap[item.coverage_decision || ''] ? item.coverage_decision : '',
            coverage: coverageMap[item.coverage_key || ''] ? item.coverage_key : '',
            amount: item.total_price,
            explanation: item.explanation,
          })
        )}
        coverages={coverages}
        coverageDecisions={COVERAGE_DECISIONS}
        filePath={filePublicUrl}
        onClose={handleClose}
        onSubmit={async (data) => {
          try {
            await handleAction(data);
            if (onExecute) onExecute();
          } catch (error) {
            // do nothing
          }
        }}
        onSave={async (data) => {
          try {
            await handleAction(data);
          } catch (error) {
            // do nothing
          }
        }}
        onApplyCoveredItem={async (item, onUpdate) => {
          await applyInvoiceItem({
            item,
            analysisResultsId: action_data.analysis_results_id,
            onUpdate,
          });
        }}
        coInsuranceCoverageRates={coInsuranceRates}
      />
    </div>
  );
};

const COVERAGE_DECISIONS: { value: CoverageDecisionType; description: string }[] = [
  {
    value: 'covered',
    description: 'Covered',
  },
  {
    value: 'not_covered',
    description: 'Not Covered',
  },
];
