import React, { useEffect, useRef } from 'react';
import { sortBy } from 'lodash';

import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { useCms } from '~/components/hooks/useCms';
import NotificationKanbanCategoryColumn from '~/components/Notifications/Kanban/NotificationKanbanCategoryColumn/NotificationKanbanCategoryColumn';
import type {
  CombinedKanbanCategoryModel,
  NotificationKanbanConfiguration,
} from '~/components/Notifications/Kanban/types';
import useOrganization from '~/components/OrganizationContext';
import type { CategoryModel } from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';
import type { ExposureModel } from '~/components/types/exposure-types';
import useDataFetcher from '~/components/useDataFetcher';

const buildCategoriesList = ({
  notificationKanbanConfiguration,
  organizationCategories,
}: {
  notificationKanbanConfiguration?: NotificationKanbanConfiguration;
  organizationCategories?: CategoryModel[];
}): CombinedKanbanCategoryModel[] => {
  if (!notificationKanbanConfiguration?.categories || !organizationCategories) {
    return [];
  }

  const categoryDetailsMap = new Map<number, CategoryModel>();
  organizationCategories.forEach((category) => {
    categoryDetailsMap.set(category.id, category);
  });

  const combinedCategories = notificationKanbanConfiguration.categories
    .map((kanbanCategory) => {
      const categoryDetails = categoryDetailsMap.get(kanbanCategory.category_id);
      if (!categoryDetails) return null;

      return {
        ...kanbanCategory,
        ...categoryDetails,
      };
    })
    .filter(Boolean) as CombinedKanbanCategoryModel[];

  return sortBy(combinedCategories, 'kanban_column_index');
};

interface NotificationKanbanBoardProps {
  notifications: ClaimNotificationModel[];
  exposuresDict: { [id: number]: ExposureModel };
  onUpdateNotification: () => Promise<void> | undefined;
  setCategories: (categoryIds: number[]) => void;
  onShowNotification: (notificationId: number) => void;
}

const NotificationKanbanBoard: React.FC<NotificationKanbanBoardProps> = ({
  notifications,
  exposuresDict,
  onUpdateNotification,
  setCategories,
  onShowNotification,
}) => {
  const { userOrganization } = useCms();
  const categoriesSetRef = useRef(false);

  const { organizationCategories } = useOrganization() as { organizationCategories: CategoryModel[] };
  const {
    isLoading,
    isError,
    data: notificationKanbanConfiguration,
  } = useDataFetcher(`/api/v1/notification_kanban/organizations/${userOrganization.id}`) as {
    isLoading: boolean;
    isError: boolean;
    data: NotificationKanbanConfiguration | undefined;
    reloadData: () => Promise<void>;
  };

  const categories = buildCategoriesList({ notificationKanbanConfiguration, organizationCategories });

  const displayLoading = isLoading || isError || !notificationKanbanConfiguration;

  useEffect(() => {
    // Only set categories once when they're loaded and not empty
    if (categories.length && !categoriesSetRef.current) {
      const categoryIds = categories.map((category) => category.id);
      setCategories(categoryIds);
      categoriesSetRef.current = true;
    }
  }, [categories, setCategories]);

  return (
    <div className="w-full overflow-x-hidden overflow-y-scroll" style={{ zoom: '70%' }}>
      <div className="grid auto-cols-[minmax(350px,1fr)] grid-flow-col gap-16 p-16">
        {displayLoading ? (
          <SkeletonTable rowsCount={4} columnsCount={5} cellClassName="h-[120px]" isError={isError} />
        ) : null}
        {!displayLoading
          ? categories.map((category) => (
              <NotificationKanbanCategoryColumn
                key={category.key}
                categoryConfig={category}
                notifications={notifications}
                exposuresDict={exposuresDict}
                onUpdateNotification={onUpdateNotification}
                sortByField={notificationKanbanConfiguration.default_column_sort_by_type}
                onShowNotification={onShowNotification}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default NotificationKanbanBoard;
