import type { ReactNode } from 'react';
import React from 'react';

import type { ClaimActionPlanTaskStatus } from '~/components/AiChat/SideBarDialog/Insights/ClaimActionPlan/types';
import Chip from '~/components/core/Atomic/Chip/Chip';
import { QuestionCircleIcon, RejectedIcon, ValidateIcon, WarningCircleIcon } from '~/components/icons';
import colors from '~/theme/tailwind/colors';
import cn from '~/Utils/cn';

const PlanItemChip: React.FC<{
  className?: string;
  variant: ClaimActionPlanTaskStatus;
  status: string;
}> = ({ variant, status, className }) => {
  const VARIANTS: Record<string, { className: string; iconComponent: ReactNode }> = {
    to_do: {
      className: 'border-blueSky-700 bg-blueSky-100 text-blueSky-700',
      iconComponent: <QuestionCircleIcon iconColor={colors.blueSky[700]} />,
    },
    done: {
      className: 'border-green-700 bg-green-100 text-green-700',
      iconComponent: <ValidateIcon iconColor={colors.green[700]} />,
    },
    not_applicable: {
      className: 'border-grey-300 bg-grey-100 text-grey-300',
      iconComponent: <RejectedIcon iconColor={colors.grey[300]} />,
    },
    unknown: {
      className: 'border-yellow-800 bg-yellow-100 text-yellow-800',
      iconComponent: <WarningCircleIcon iconColor={colors.yellow[800]} />,
    },
  };
  return (
    <Chip
      className={cn('border-1 border-solid', VARIANTS[variant].className, className)}
      label={
        <div className="flex items-center">
          {VARIANTS[variant].iconComponent}
          <span className="ml-4">{status}</span>
        </div>
      }
      size="small"
    />
  );
};

export default PlanItemChip;
