import type { CLAIM_NOTIFICATIONS_PRIORITY } from '~/Types';

export const TARGET_CLAIM_HANDLER = 0;
export const TARGET_MYSELF = -1;
export const TARGET_OTHER_USER = -2;
export const TARGET_ORGANIZATION_UNIT_LEADER = -3;

export interface ReminderFormValues {
  title: string;
  details: string;
  due_date: string;
  target_id: number | string;
  other_adjuster_id?: string;
  organization_unit_id?: number;
  priority?: keyof typeof CLAIM_NOTIFICATIONS_PRIORITY;
  category_id?: number;
}

export interface ReminderRequestParams {
  title: string;
  details: string;
  due_date: string;
  recipient:
    | { type: 'claim_handler' | 'myself' }
    | { type: 'exposure_handler'; exposure_id: number }
    | { type: 'other_adjuster'; other_adjuster_id: string }
    | { type: 'organization_unit_leader'; organization_unit_id: number };
  priority?: keyof typeof CLAIM_NOTIFICATIONS_PRIORITY;
  category_id?: number;
}

export function reminderFormValuesToRequestParams(
  values: ReminderFormValues,
  isNotifications2Enabled: boolean
): ReminderRequestParams {
  const newValues: Partial<ReminderRequestParams> = {
    title: values.title,
    details: values.details,
    due_date: values.due_date,
  };
  if (isNotifications2Enabled) {
    newValues.priority = values.priority;
    newValues.category_id = values.category_id;
  }
  switch (values.target_id) {
    case TARGET_MYSELF:
      newValues.recipient = { type: 'myself' };
      break;
    case TARGET_CLAIM_HANDLER:
      newValues.recipient = { type: 'claim_handler' };
      break;
    case TARGET_OTHER_USER:
      newValues.recipient = { type: 'other_adjuster', other_adjuster_id: values.other_adjuster_id as string };
      break;
    case TARGET_ORGANIZATION_UNIT_LEADER:
      newValues.recipient = {
        type: 'organization_unit_leader',
        organization_unit_id: values.organization_unit_id as number,
      };
      break;
    default:
      if (typeof values.target_id === 'string' || values.target_id <= 0) {
        throw new Error(`Invalid target_id: ${values.target_id}`);
      }
      newValues.recipient = { type: 'exposure_handler', exposure_id: values.target_id };
  }
  return newValues as ReminderRequestParams;
}
