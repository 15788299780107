import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { capitalize, isEmpty, noop } from 'lodash';

import { JusterImage } from '~/components/AiChat/components/JusterImage';
import type { RelatedPolicyRecommendation } from '~/components/communications/GeneralEmailCommunicationAiAnalysis/generalEmailAnalysisExtra';
import { MainCard } from '~/components/core';
import Button from '~/components/core/Atomic/Buttons/Button';
import Divider from '~/components/core/Atomic/Divider/Divider';
import Caption from '~/components/core/TextComponents/Caption';
import Heading from '~/components/core/TextComponents/Heading';
import Label from '~/components/core/TextComponents/Label';
import Text from '~/components/core/TextComponents/Text';
import { PolicySelectionButton } from '~/components/Fnol/FnolAiInbox/PolicySelectionButton/PolicySelectionButton';
import { PolicyCard } from '~/components/Fnol/FnolAiInbox/PolicySelectionCard/PolicyCard';
import type { Policy } from '~/components/Fnol/FnolAiInbox/types';

interface CommunicationAiBannerProps {
  communicationType: string;
  recommendation: Recommendation | null;
  recommendedPolicy?: RelatedPolicyRecommendation;
  summary?: string;
}

interface SourceQuote {
  location_desc: string;
  quote: string;
}

export interface ClassificationReason {
  reason: string;
  quotes: SourceQuote[];
}

export interface Recommendation {
  title: string;
  explanation_component: React.ReactNode;
  classification_reasons: ClassificationReason[];
  actionComponent: React.ReactNode;
}

export const ActionButton: React.FC<{
  text: string;
  onClick?: () => void | Promise<void>;
}> = ({ text, onClick = noop }) => {
  const [isPerformingAction, setIsPerformingAction] = React.useState(false);

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsPerformingAction(true);
    try {
      await onClick();
    } finally {
      setIsPerformingAction(false);
    }
  };

  return (
    <Button variant="text" onClick={handleClick} disabled={isPerformingAction} color="primary">
      {text}
    </Button>
  );
};

export const CommunicationAiBanner: React.FC<CommunicationAiBannerProps> = ({
  communicationType,
  recommendation,
  recommendedPolicy,
  summary,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const bannerTitle = recommendation ? 'Clive’s Assessment' : `${capitalize(communicationType)} Summary`;

  return (
    <>
      <MainCard
        key="contained"
        collapsible
        className="border-pink-600"
        title={
          <CommunicationAiBannerTitle
            title={bannerTitle}
            subtitle={recommendation ? recommendation.title : undefined}
            extraComponent={recommendation ? recommendation.actionComponent : null}
          />
        }
        titleAction={<JusterImage width={32} height={32} />}
        type="contained"
        isOpen={isOpen}
        direction="right"
        onCollapseClick={(cardState) => {
          setIsOpen(cardState);
        }}
      >
        <div className="relative flex w-full flex-col gap-12">
          <Divider />
          {recommendedPolicy ? (
            <>
              <div className="flex items-center justify-between gap-12">
                <Text variant={Text.VARIANTS.SM}>Recommended Policy</Text>
                <div className="w-1/2">
                  <PolicySelectionButton
                    policyNumber={recommendedPolicy?.policy_number}
                    viewOnly
                    onClick={() => setShowPolicy(true)}
                  />
                </div>
              </div>
              <Divider />
            </>
          ) : null}

          {recommendation && (
            <>
              {recommendation.explanation_component ? recommendation.explanation_component : null}
              {recommendation.classification_reasons ? (
                <ClassificationReasons reasons={recommendation.classification_reasons} />
              ) : null}
              <Divider />
              <Heading>{`${capitalize(communicationType)} Summary`}</Heading>
            </>
          )}
          {summary}
        </div>
      </MainCard>
      {showPolicy && recommendedPolicy?.policy_data ? (
        <PolicyCard
          policy={recommendedPolicy?.policy_data as Policy}
          onChangePolicy={() => null}
          onRemove={() => null}
          onCancel={() => setShowPolicy(false)}
          viewOnly
        />
      ) : null}
    </>
  );
};

const CommunicationAiBannerTitle: React.FC<{
  title: string;
  subtitle?: string;
  extraComponent?: ReactNode;
}> = ({ title, subtitle, extraComponent }) => {
  const titleComponent = (
    <div>
      <Heading variant={subtitle ? 'h4' : 'h2'}>{title}</Heading>
      {subtitle && <Caption>{subtitle}</Caption>}
    </div>
  );
  return extraComponent ? (
    <div className="inline-flex w-full items-center justify-between">
      {titleComponent}
      {extraComponent}
    </div>
  ) : (
    <>{titleComponent}</>
  );
};

interface ClassificationReasonsProps {
  reasons: ClassificationReason[];
}

const ClassificationReasons: React.FC<ClassificationReasonsProps> = ({ reasons }) => {
  if (isEmpty(reasons)) return null;

  return (
    <div className="mb-12">
      <ul className="list-none p-0">
        {reasons.map((reason, index) => (
          <li key={index} className="mb-4">
            <Text variant={Text.VARIANTS.SM}>{reason.reason}</Text>
            <>
              {reason.quotes.map((quote) => (
                <>
                  <Label>{quote.location_desc}</Label>
                  <div className="border-gray-300 ml-8 border-l-2 pl-8">
                    <Caption className="text-gray-600 italic">&quot;{quote.quote}&quot;</Caption>
                  </div>
                </>
              ))}
            </>
          </li>
        ))}
      </ul>
    </div>
  );
};
