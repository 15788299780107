import React from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { Popover } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import AdjusterSelectTextFieldFormik from '../Adjuster/AdjusterSelectTextFieldFormik';

import CardDialog from './CardDialog';
import CheckboxFormik from './CheckboxFormik';
import LoadingIndicator from './LoadingIndicator';
import useOrganization from './OrganizationContext';
import { TextFieldFormik } from './TextFieldFormik';

import { useStyles } from '../assets/styles';

function AssignHandlingAdjusterPopover(props) {
  const { anchorEl, currentAdjusterId, onAssignAdjuster, onClose, noneText, noneValue, title, isClaimAssign } = props;
  const classes = useStyles();

  const { isLoading, isError } = useOrganization();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      placement="bottom-end"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {isLoading || isError ? (
        <LoadingIndicator isError={isError} />
      ) : (
        <Formik
          initialValues={{
            handling_adjuster_id: currentAdjusterId || (noneValue ? noneValue : ''),
            do_not_reassign_closed_exposures: true,
            note: '',
          }}
          validationSchema={Yup.object().shape({
            handling_adjuster_id: Yup.string().required('Required'),
            do_not_reassign_closed_exposures: isClaimAssign ? Yup.boolean().required('Required') : undefined,
            note: Yup.string().required('Required'),
          })}
          enableReinitialize
          onSubmit={(values, formikProps) => {
            let fixedValues = values;
            if (values.handling_adjuster_id === noneValue) {
              fixedValues = { ...fixedValues, handling_adjuster_id: '' };
            }
            onAssignAdjuster(fixedValues)
              .then(() => formikProps.resetForm())
              .catch(() => formikProps.setSubmitting(false));
          }}
        >
          {(formikProps) => {
            const { isSubmitting, handleSubmit } = formikProps;
            return (
              <CardDialog title={title} onClose={onClose} width="400px">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <AdjusterSelectTextFieldFormik
                      id="handling_adjuster_id"
                      label="Adjuster"
                      className={classes.textField}
                      fullWidth
                      extraValues={
                        noneValue
                          ? [
                              {
                                id: noneValue,
                                username: noneText,
                              },
                            ]
                          : []
                      }
                      checkLicenses
                    />
                  </Grid>

                  {isClaimAssign && (
                    <Grid item xs={12}>
                      <CheckboxFormik id="do_not_reassign_closed_exposures" label="Do not reassign closed exposures" />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="note"
                      label="Note"
                      rows={3}
                      className={classes.textField}
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
                <div className={classes.buttonsContainer}>
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                    Assign
                  </Button>
                </div>
              </CardDialog>
            );
          }}
        </Formik>
      )}
    </Popover>
  );
}

AssignHandlingAdjusterPopover.propTypes = {
  anchorEl: PropTypes.object,
  currentAdjusterId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onAssignAdjuster: PropTypes.func.isRequired,
  noneText: requiredIf(PropTypes.string, (props) => props.noneValue),
  noneValue: PropTypes.string,
  title: PropTypes.string.isRequired,
  isClaimAssign: PropTypes.bool,
};

export default AssignHandlingAdjusterPopover;
