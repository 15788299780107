import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';

import CardDialog from '../CardDialog';
import { FsRadio } from '../core';
import { TextFieldFormik } from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function AutoFireDepartmentField(props) {
  const { wereFireDepartmentInvolved, fireDepartmentDescription, onUpdate, disabled } = props;
  const [fireDepartmentInvolvedDialogOpen, setFireDepartmentInvolvedDialogOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <Grid item container xs={12}>
        <Grid item xs={7}>
          <span style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            Were the fire department involved?
          </span>
        </Grid>
        <Grid item xs={5}>
          <div style={{ height: '100%', display: 'inline-flex', alignItems: 'center' }}>
            <FormControl>
              <FormControlLabel
                value=""
                control={
                  <FsRadio
                    color="primary"
                    checked={wereFireDepartmentInvolved === false}
                    onChange={async () =>
                      await onUpdate({
                        fire_department_description: '',
                        were_fire_department_involved: false,
                      })
                    }
                    disabled={disabled}
                    size="small"
                  />
                }
                label="No"
                labelPlacement="end"
              />
            </FormControl>
            <FormControl>
              <FormControlLabel
                value=""
                control={
                  <FsRadio
                    color="primary"
                    checked={wereFireDepartmentInvolved === true}
                    onChange={() => setFireDepartmentInvolvedDialogOpen(true)}
                    onClick={() => setFireDepartmentInvolvedDialogOpen(true)}
                    disabled={disabled}
                    size="small"
                  />
                }
                label="Yes >"
                labelPlacement="end"
              />
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Formik
        initialValues={{ fire_department_description: fireDepartmentDescription || '' }}
        validationSchema={Yup.object().shape({ fire_department_description: Yup.string() })}
        enableReinitialize
        onSubmit={async (values, formikProps) => {
          await onUpdate({ ...values, were_fire_department_involved: true });
          setFireDepartmentInvolvedDialogOpen(false);
          formikProps.resetForm();
        }}
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit } = formikProps;
          return (
            <CardDialog
              isDialog
              open={fireDepartmentInvolvedDialogOpen}
              title="Fire Department Involvement Details"
              fullWidth
              maxWidth="sm"
              onClose={() => setFireDepartmentInvolvedDialogOpen(false)}
              preventClose={isSubmitting}
            >
              <div>
                <TextFieldFormik
                  id="fire_department_description"
                  label="Description"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                  multiline
                  rows={4}
                />
              </div>
              <div className={classes.buttonsContainer}>
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

AutoFireDepartmentField.propTypes = {
  wereFireDepartmentInvolved: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fireDepartmentDescription: PropTypes.string,
};

export default AutoFireDepartmentField;
