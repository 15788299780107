import React from 'react';
import { get, isEmpty } from 'lodash';

import AutocompleteFormik from '~/components/AutocompleteFormik';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Text from '~/components/core/TextComponents/Text';
import ExposureMultiSelectTextFieldFormik from '~/components/ExposureMultiSelectTextFieldFormik';
import useOrganization from '~/components/OrganizationContext';
import { DatePickerTextFieldFormik, TextFieldFormik } from '~/components/TextFieldFormik';
import type { ClaimModel } from '~/components/types/claim-types';
import { reportErrorInProductionOrThrow } from '~/Utils';

import { getAllowedDocumentTypes } from '../../DocumentCard';

export interface DocumentFieldsSectionProps {
  claim?: ClaimModel;
  showOnly?: boolean;
  fieldsTitle?: string;
  nameFieldAdditionalText?: string;
  hideExposuresLabels?: boolean;
  allowedDocumentTypes?: string[];
  disabled?: boolean;
}

const INPUT_LABEL_PROPS = {
  shrink: true,
};

const DocumentFieldsSection: React.FC<DocumentFieldsSectionProps> = ({
  claim,
  showOnly = false,
  fieldsTitle,
  nameFieldAdditionalText,
  hideExposuresLabels = false,
  allowedDocumentTypes = [],
  disabled = false,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { documentTypesDict = {} } = useOrganization();
  const title = fieldsTitle || 'Document';

  let documentTypes: string[] = [];

  if (!isEmpty(allowedDocumentTypes)) {
    documentTypes = allowedDocumentTypes;
  } else if (claim) {
    documentTypes = getAllowedDocumentTypes(documentTypesDict, claim.lob);
  } else {
    reportErrorInProductionOrThrow('If claim was not passed, allowedDocumentTypes must exists');
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <AutocompleteFormik
          id="type"
          label={`${title} Type`}
          options={documentTypes}
          getOptionLabel={(option) => get(documentTypesDict, `${option}.desc`, '')}
          showOnly={showOnly}
          disabled={disabled}
          sortAlphabetic
        />
      </Grid>
      <Grid item xs={12}>
        <TextFieldFormik
          id="document_name"
          label={`${title} Name`}
          className="my-4"
          InputLabelProps={INPUT_LABEL_PROPS}
          fullWidth
          disabled={disabled}
          showOnly={showOnly}
          rows={undefined}
        />
        {nameFieldAdditionalText && (
          <Text variant={Text.VARIANTS.XS} colorVariant={Text.COLOR_VARIANTS.SECONDARY} weight={Text.WEIGHTS.REGULAR}>
            {nameFieldAdditionalText}
          </Text>
        )}
      </Grid>
      <Grid item xs={12}>
        <DatePickerTextFieldFormik
          id="document_date"
          label={`${title} Date Created`}
          disableFuture
          className="my-4"
          fullWidth
          disabled={disabled}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={12}>
        <DatePickerTextFieldFormik
          id="document_date_received"
          label={`${title} Date Received`}
          disableFuture
          className="my-4"
          fullWidth
          disabled={disabled}
          showOnly={showOnly}
        />
      </Grid>
      {!hideExposuresLabels && (
        <Grid item xs={12}>
          <ExposureMultiSelectTextFieldFormik claim={claim} showOnly={showOnly} disabled={disabled} />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextFieldFormik
          id="summary"
          label="Summary"
          className="my-4"
          InputLabelProps={INPUT_LABEL_PROPS}
          fullWidth
          disabled={disabled}
          showOnly={showOnly}
          multiline
          rows={5}
        />
      </Grid>
    </Grid>
  );
};

export default DocumentFieldsSection;
