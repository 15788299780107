import React from 'react';
import { Typography } from '@material-ui/core';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import CancelButton from '~/components/core/Buttons/CancelButton';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import ConfiguredFields, {
  getBaseConfiguredFieldsValidations,
  getConfiguredFieldsEmptyFormikInitialValues,
} from '~/components/IncidentConfiguration/ConfiguredFields';
import { isoDateToUs } from '~/DateTimeUtils';
import cn from '~/Utils/cn';
import { getLobDescription } from '~/Utils/lobUtils';

import { useLobConfiguration } from './hooks/useLobConfiguration';
import { LobSelectFormik } from './TPA/LOB/LobSelectFormik';
import SubOrganizationSelectFormik from './TPA/SubOrganizations/SubOrganizationSelectFormik';
import { FsButton, PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from './core';
import useOrganization from './OrganizationContext';
import PlainTable from './PlainTable';

import { useStyles } from '../assets/styles';
import styles from './policySearch.module.scss';

interface PolicySearchDefaultValues {
  lob?: string;
  sub_org_id?: string;
}

interface PolicySearchInnerProps {
  disabled?: boolean;
  isSearchInProgress?: boolean;
  onSetManualPolicy?: () => void;
}

const PolicySearchInner: React.FC<PolicySearchInnerProps> = ({ disabled, isSearchInProgress, onSetManualPolicy }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { subOrganizationEnabled, policySearchConfig, subOrganizations, supportedClaimTypes } = useOrganization();
  const { handleSubmit, values, isSubmitting } = useFormikContext<PolicySearchDefaultValues>();
  const classes = useStyles();
  const isMultipleLobsExist = supportedClaimTypes.length > 1;

  return (
    <CollapsibleWrapper
      title="Search Policy"
      actionCard
      noBorder
      backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.WHITE}
      defaultState
    >
      <form onSubmit={(e) => e.preventDefault()}>
        <div className={classes.containerCentered}>
          <Grid container spacing={2}>
            {subOrganizationEnabled && subOrganizations.length > 1 ? (
              <Grid item xs={3}>
                <SubOrganizationSelectFormik fieldId="sub_org_id" disabled={disabled || isSubmitting} />
              </Grid>
            ) : null}
            {isMultipleLobsExist ? (
              <Grid item xs={3}>
                <LobSelectFormik
                  fieldId="lob"
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  subOrganizationIds={values?.sub_org_id ? [values.sub_org_id] : []}
                  disabled={disabled || isSubmitting}
                />
              </Grid>
            ) : null}

            <ConfiguredFields
              values={values}
              customFields={policySearchConfig.policy_api_configuration.configured_fields}
              idPrefix=""
              inline
              newLayoutEnabled
            />
          </Grid>
        </div>

        <div className={styles.btnContainer}>
          <Button
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onClick={handleSubmit}
            className={styles.btn}
            disabled={isSearchInProgress || disabled}
            variant="contained"
            color="primary"
          >
            Search
          </Button>
          {onSetManualPolicy && (
            <RestrictedPermissions action={PERMISSION_ACTIONS.MANUAL_POLICY} verb={PERMISSION_VERBS.WRITE}>
              <CancelButton
                classNameOverride={cn(styles.btn, styles.setPolicyBtn)}
                onClick={onSetManualPolicy}
                content="Set Policy Details Manually"
              />
            </RestrictedPermissions>
          )}
        </div>
      </form>
    </CollapsibleWrapper>
  );
};

interface PolicySearchProps {
  onSearch: (values: PolicySearchDefaultValues) => void;
  disabled?: boolean;
  isSearchInProgress?: boolean;
  onSetManualPolicy?: () => void;
}

const PolicySearchV2: React.FC<PolicySearchProps> = ({ onSearch, disabled, isSearchInProgress, onSetManualPolicy }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { subOrganizationEnabled, supportedClaimTypes, policySearchConfig, subOrganizations } = useOrganization();
  const isMultipleLobsExist = supportedClaimTypes.length > 1;
  const configuredFields = policySearchConfig?.policy_api_configuration?.configured_fields || [];

  return (
    <Formik
      initialValues={{
        sub_org_id: subOrganizationEnabled && subOrganizations.length === 1 ? subOrganizations[0]?.id : '',
        lob: isMultipleLobsExist ? '' : supportedClaimTypes[0],
        ...getConfiguredFieldsEmptyFormikInitialValues(configuredFields),
      }}
      validationSchema={Yup.object().shape({
        sub_org_id: subOrganizationEnabled ? Yup.string().required('Required') : Yup.string(),
        lob: isMultipleLobsExist ? Yup.string().required('Required') : Yup.string(),
        ...getBaseConfiguredFieldsValidations(configuredFields),
      })}
      onSubmit={async (values, formikProps) => {
        try {
          await onSearch(values);
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      <PolicySearchInner
        disabled={disabled}
        isSearchInProgress={isSearchInProgress}
        onSetManualPolicy={onSetManualPolicy}
      />
    </Formik>
  );
};

interface DisplaySchemaItem {
  desc: string;
  id: string;
  type: string;
}

interface DisplayFields {
  [key: string]: string;
}

interface ResultItem {
  display_fields: DisplayFields;
  retrieval_token: string;
}

export interface PolicyApiV2SearchResponse {
  display_schema: DisplaySchemaItem[];
  results: ResultItem[];
  total_results: number;
}

interface PolicySearchResults {
  [key: string]: PolicyApiV2SearchResponse;
}

interface SelectedPolicyItem extends ResultItem {
  lob: string;
  search_type: string;
  display_schema: DisplaySchemaItem[];
}

interface PolicySearchResultsProps {
  searchPoliciesResults: PolicySearchResults;
  onCancel: () => void;
  onSelectPolicy: (row: SelectedPolicyItem) => Promise<void>;
  policyTypes: string[];
  selectButtonText?: string;
  disableSelectButton?: boolean;
  isDialog?: boolean;
  noCardTitle?: boolean;
}

export const PolicySearchResultsV2: React.FC<PolicySearchResultsProps> = (props) => {
  const {
    searchPoliciesResults,
    onCancel,
    onSelectPolicy,
    policyTypes,
    selectButtonText,
    disableSelectButton,
    isDialog = true,
    noCardTitle,
  } = props;

  const [isPolicySelectInProgress, setIsPolicySelectInProgress] = React.useState(false);
  const classes = useStyles();
  const { lobConfigurationsDict } = useLobConfiguration();

  async function handleSelectPolicy(row: ResultItem, policyType: string) {
    setIsPolicySelectInProgress(true);
    await onSelectPolicy({
      ...row,
      lob: policyType.replace('_policy', '_claim'),
      search_type: 'API_V2',
      display_schema: searchPoliciesResults?.[policyType]?.display_schema,
    });
    setIsPolicySelectInProgress(false);
  }

  const getPolicyColumnData = (policyType: string) => {
    const columns =
      searchPoliciesResults?.[policyType]?.display_schema?.map((field: DisplaySchemaItem) => ({
        id: field.id,
        numeric: false,
        label: field.desc,
        specialCell: (row: ResultItem) =>
          field.type === 'date' ? (
            <span>{row?.display_fields?.[field.id] ? isoDateToUs(row.display_fields[field.id]) : ''}</span>
          ) : (
            <span>{row?.display_fields?.[field.id] || ''}</span>
          ),
      })) || [];

    columns.push({
      id: 'select_button',
      numeric: false,
      label: '',
      specialCell: (row: ResultItem) => (
        <FsButton
          color="primary"
          disabled={disableSelectButton || isPolicySelectInProgress}
          onClick={() => handleSelectPolicy(row, policyType)}
        >
          {selectButtonText || 'Select'}
        </FsButton>
      ),
    });

    return columns;
  };

  return (
    <CardDialog
      title="Policies"
      isDialog={isDialog}
      noCardTitle={noCardTitle}
      onClose={onCancel}
      maxWidth="lg"
      fullWidth
      preventClose={isPolicySelectInProgress}
    >
      {policyTypes.map((policyType) => {
        const searchPolicyResultsForType = searchPoliciesResults[policyType];

        return (
          <React.Fragment key={policyType}>
            {searchPolicyResultsForType.total_results > 0 && (
              <Grid container spacing={1} className="mt-8">
                <Grid item xs={12}>
                  <Typography display="block" variant="subtitle1" style={{ marginBottom: '6px' }}>
                    {getLobDescription(policyType.replace('_policy', '_claim'), lobConfigurationsDict)}
                  </Typography>

                  <PlainTable
                    classes={classes}
                    columns={getPolicyColumnData(policyType)}
                    rows={searchPolicyResultsForType?.results || []}
                    shouldRenderExpandAsTable
                  />
                </Grid>
              </Grid>
            )}
            {searchPolicyResultsForType.total_results === 10 && (
              <Typography variant="subtitle2" style={{ marginBottom: '6px' }}>
                Showing only the first 10 results. Refine your search query to find other policies
              </Typography>
            )}
          </React.Fragment>
        );
      })}
    </CardDialog>
  );
};

export type { SelectedPolicyItem };
export default PolicySearchV2;
