import React from 'react';
import PropTypes from 'prop-types';

import SumContainer from '~/components/exposures/ExposuresTable/SumContainer';
import roundFloat from '~/Utils/roundFloat';

const SubPercentageSum = ({ exposure, value }) => {
  const coinsurancePercentage = exposure?.coverage?.coinsurance_insurer_percentage;
  if (!coinsurancePercentage) return null;

  const calculatedValue = roundFloat(value / (0.01 * coinsurancePercentage));
  if (calculatedValue === undefined || calculatedValue === null) return <>-</>;

  return (
    <span className="text-sm text-grey-700">
      <SumContainer sum={calculatedValue} fontWeight={300} />
    </span>
  );
};

SubPercentageSum.propTypes = {
  exposure: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
};

export default SubPercentageSum;
