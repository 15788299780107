import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import { ShowOnlyTextField } from '~/components/TextFieldFormik';
import { isMarshmallowPolicy } from '~/Utils';

const PolicyCustomFields = ({ customFields, policy }) => {
  const classes = useStyles();
  return (
    <div>
      <CollapsibleWrapper actionCard noBorder title="Additional Details">
        <div className="flex flex-wrap pt-0">
          {customFields
            .map((f) => Object.values(f)[0])
            .map((field, i) => (
              <div className="mx-4" key={i}>
                <ShowOnlyTextField
                  classes={classes}
                  label={field.displayName ?? field.display_name}
                  showOnlyValueComponent={
                    <OverflowTextWithToolTip maxWidth={130}>{field.value}</OverflowTextWithToolTip>
                  }
                />
              </div>
            ))}
          {policy.underwriting_company && (
            <div className="mx-4">
              <ShowOnlyTextField
                classes={classes}
                label="Underwriting Company"
                showOnlyValueComponent={policy.underwriting_company}
              />
            </div>
          )}
          {isMarshmallowPolicy(policy) && (
            <div className="mx-4">
              <ShowOnlyTextField classes={classes} label="Package" showOnlyValueComponent={policy.policy_type} />
            </div>
          )}
        </div>
      </CollapsibleWrapper>
    </div>
  );
};

PolicyCustomFields.propTypes = {
  customFields: PropTypes.array.isRequired,
  policy: PropTypes.object.isRequired,
};

export default PolicyCustomFields;
