import React from 'react';

import Caption from '~/components/core/TextComponents/Caption';
import Text from '~/components/core/TextComponents/Text';
import type { ApprovalRequest } from '~/components/types/finances-types';
import { serverDateToLocal } from '~/DateTimeUtils';
import APPROVAL_REQUESTS from '~/server_shared/generated-types/APPROVAL_REQUESTS';

interface ApprovalRequestsProps {
  approvalRequests?: ApprovalRequest[];
}

const ApprovalRequests: React.FC<ApprovalRequestsProps> = ({ approvalRequests }) => {
  if (!approvalRequests) return null;
  return (
    <div>
      {approvalRequests
        .filter((ar) => ar.approval_status === APPROVAL_REQUESTS.STATUSES.INTERMEDIATE_APPROVAL)
        .map((approvalRequest) => (
          <div key={approvalRequest.id} className="mt-20 space-y-4">
            <div className="flex items-center justify-between">
              <Text
                variant={Text.VARIANTS.XS}
                colorVariant={Text.COLOR_VARIANTS.PRIMARY}
                weight={Text.WEIGHTS.SEMI_BOLD}
              >
                {approvalRequest.approving_user_name || ''}
              </Text>
              <Caption>
                {approvalRequest.approval_datetime ? serverDateToLocal(approvalRequest.approval_datetime) : ''}
              </Caption>
            </div>
            <Text variant={Text.VARIANTS.XS} colorVariant={Text.COLOR_VARIANTS.SECONDARY} weight={Text.WEIGHTS.REGULAR}>
              Approval Note
            </Text>
            <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR}>
              {approvalRequest.approval_note || ''}
            </Text>
          </div>
        ))}
    </div>
  );
};

export default ApprovalRequests;
