import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const OpenMailIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" disableStrokeColor iconColor={iconColor} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7432 2.10732L22.6255 8.91551C22.7102 8.9739 22.7796 9.05301 22.8276 9.1458C22.8755 9.2386 22.9005 9.3422 22.9004 9.4474V20.5107C22.9004 20.9057 22.7486 21.2845 22.4783 21.5638C22.208 21.8431 21.8414 22 21.4592 22H3.34157C2.95934 22 2.59278 21.8431 2.3225 21.5638C2.05223 21.2845 1.90039 20.9057 1.90039 20.5107V9.4474C1.90024 9.3422 1.92525 9.2386 1.97321 9.1458C2.02116 9.05301 2.09056 8.9739 2.17524 8.91551L12.0576 2.10732C12.1591 2.03735 12.2784 2 12.4004 2C12.5224 2 12.6417 2.03735 12.7432 2.10732ZM3.13569 20.0746L9.53657 15.4046L3.13569 10.6867V20.0746ZM20.4133 20.7235L14.0011 16.0428H10.7997L4.38745 20.7235H20.4133ZM21.6651 10.6867L15.2642 15.4046L21.6651 20.0746V10.6867ZM21.1947 9.46974L12.4004 3.4062L3.60613 9.46549L10.8017 14.7663H13.9991L21.1947 9.46974Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default OpenMailIcon;
