import { useCms } from '~/components/hooks/useCms';
import useDataFetcher from '~/components/useDataFetcher';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

export interface CountersView {
  unread_notifications_count: number | '-';
  general_queue_count: number | '-';
  fnol_queue_count: number | '-';
}

const useCountersView = (): {
  countersView: CountersView | undefined;
  isLoading: boolean;
  isError: boolean;
  reloadData: () => void;
} => {
  const { userOrganization } = useCms();

  const isNotificationKanbanBoardEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.NOTIFICATION_KANBAN_BOARD
  );

  const {
    data: countersView = {
      unread_notifications_count: '-',
      general_queue_count: '-',
      fnol_queue_count: '-',
    },
    isLoading,
    isError,
    reloadData,
  } = useDataFetcher(
    `/api/v1/organizations/${userOrganization.id}/counters_view`,
    {},
    isNotificationKanbanBoardEnabled
  );

  return { countersView, isLoading, isError, reloadData };
};

export default useCountersView;
