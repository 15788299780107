import React from 'react';
import { camelCase, isEmpty, isNil, startCase } from 'lodash';

import PlanItemChip from '~/components/AiChat/SideBarDialog/Insights/ClaimActionPlan/PlanItemChip';
import type {
  ClaimActionPlanInsightMetadata,
  ClaimActionPlanTaskResult,
  ClaimActionPlanTaskStatus,
} from '~/components/AiChat/SideBarDialog/Insights/ClaimActionPlan/types';
import { Caption } from '~/components/core';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import cn from '~/Utils/cn';

import type { Insight } from '../../../types';
import { InsightTitle } from '../Insight';

// Backward compatibility for demo data in AUW claims in UAT
const _update_old_insights = (insight: Insight, metadata: ClaimActionPlanInsightMetadata) => {
  if (!isNil(insight.insight_metadata?.actions)) {
    if (!insight.insight_metadata) {
      insight.insight_metadata = {};
    }

    insight.insight_metadata.claim_action_plan = insight.insight_metadata.actions;

    metadata.claim_action_plan.forEach((action) => {
      action.status = action.status.toLowerCase().replace(' ', '_') as ClaimActionPlanTaskStatus;
      action.status_reason = action.status_description;
    });
  }
};

export const ClaimActionPlan: React.FC<{ insight: Insight }> = ({ insight }) => {
  if (!insight.insight_metadata) {
    insight.insight_metadata = {};
  }

  if (isEmpty(insight.insight_metadata?.claim_action_plan)) {
    insight.insight_metadata.claim_action_plan = [];
  }

  const metadata = insight.insight_metadata as ClaimActionPlanInsightMetadata;

  _update_old_insights(insight, metadata);

  return (
    <div className="flex flex-col gap-8">
      {metadata.claim_action_plan.map((action) => (
        <PlanItem key={action.id} action={action} />
      ))}
    </div>
  );
};

const PlanItem: React.FC<{
  action: ClaimActionPlanTaskResult;
}> = ({ action }) => {
  return (
    <CollapsibleWrapper
      variant="slim"
      title={
        <InsightTitle
          title={
            <span
              className={cn({ 'text-grey-300': action.status === 'not_applicable' })}
            >{`${action.id}. ${action.title}`}</span>
          }
          extraComponent={<PlanItemStatusBadge status={action.status} />}
        />
      }
      chevronSide="RIGHT"
      noBorderLine
    >
      <div>{action.description}</div>
      <Caption className="pb-16 pt-8 text-grey-400">{action.status_reason}</Caption>
    </CollapsibleWrapper>
  );
};

const PlanItemStatusBadge: React.FC<{ status: ClaimActionPlanTaskStatus }> = ({ status }) => {
  const statusName = startCase(camelCase(status));

  return <PlanItemChip className="cursor-pointer" variant={status} status={statusName} />;
};
