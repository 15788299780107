import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const CloseIcon: React.FC<IconProps> = ({ iconColor, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" disableStrokeColor iconColor={iconColor} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3917 4.71967C19.6846 5.01256 19.6846 5.48744 19.3917 5.78033L5.89166 19.2803C5.59877 19.5732 5.12389 19.5732 4.831 19.2803C4.5381 18.9874 4.5381 18.5126 4.831 18.2197L18.331 4.71967C18.6239 4.42678 19.0988 4.42678 19.3917 4.71967Z"
        fill={iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.831 4.71967C5.12389 4.42678 5.59877 4.42678 5.89166 4.71967L19.3917 18.2197C19.6846 18.5126 19.6846 18.9874 19.3917 19.2803C19.0988 19.5732 18.6239 19.5732 18.331 19.2803L4.831 5.78033C4.5381 5.48744 4.5381 5.01256 4.831 4.71967Z"
        fill={iconColor}
      />
    </SvgIcon>
  );
};

export default CloseIcon;
