import type { ClassificationReason } from '~/components/communications/CommunicationAiBanner';

interface RelatedClaimRecommendation {
  claim_number: string;
  claim_internal_information: Record<string, unknown>;
  classification_reasons: ClassificationReason[];
  classification_reason_extra: Record<string, unknown>;
}

export interface RelatedPolicyRecommendation {
  policy_data: Record<string, unknown>;
  policy_number?: string;
  [key: string]: unknown;
}

export interface BundleClassification {
  category: string;
  classification_reasons: ClassificationReason[];
  classification_reason_extra: Record<string, unknown>;
}

export interface EmailClassificationTaskResponse {
  summary: string;
  related_claim_recommendation?: RelatedClaimRecommendation;
  related_policy_recommendation?: RelatedPolicyRecommendation;
  bundle_classification: BundleClassification[];
}

interface GeneralEmailAnalysisExtra {
  version: number;
  classification_response: EmailClassificationTaskResponse;
}

const CommunicationAiAnalysisVersion = 1;

export { CommunicationAiAnalysisVersion };
export type { GeneralEmailAnalysisExtra };
