import * as Yup from 'yup';

export const reminderValidationSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  due_date: Yup.date().required('Required'),
  target_id: Yup.number().required('Required'),
  other_adjuster_id: Yup.string().when('target_id', {
    is: -2,
    then: Yup.string().required('Required'),
  }),
  organization_unit_id: Yup.number().when('target_id', {
    is: -3,
    then: Yup.number().nullable(true).required('Required'),
    otherwise: Yup.number().nullable(true).notRequired(),
  }),
  priority: Yup.string().nullable().notRequired(),
  category_id: Yup.number().nullable().notRequired(),
});

export const getDefaultReminderTarget = (user, claim) => {
  if (claim.handling_adjuster_id === user.id) {
    return 0;
  }

  const relatedExposure = claim.exposures.find((exposure) => exposure.handling_adjuster_id === user.id);
  if (relatedExposure) {
    return relatedExposure.id;
  }

  return -1; // myself
};
