import React, { useCallback, useMemo, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { isEmpty, isNil, noop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useStyles } from '~/assets/styles';
import type {
  CoveredItemRow,
  InvoiceRecommendationAnalysisProps,
  LineItemRow,
  StatusType,
} from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis/types';
import {
  calcCoveredAmount,
  getAggregateCoverages,
  NO_COVERAGE,
  UNDECIDED,
} from '~/components/AiChat/SideBarDialog/InvoiceRecommendationAnalysis/utils';
import CardDialog from '~/components/CardDialog';
import mixpanel from '~/components/CmsMain/mixpanel';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { MonetaryValueTextField } from '~/components/core/Molecules/Fields/MonetaryValueTextField';
import TextField from '~/components/core/Molecules/Fields/TextField';
import type { COLORS } from '~/components/core/StatusChipCore';
import StatusChipCore from '~/components/core/StatusChipCore';
import SortableTable from '~/components/core/Tables/SortableTable';
import Heading from '~/components/core/TextComponents/Heading';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import ArrowDownwardIcon from '~/components/icons/ArrowDownwardIcon';
import TrashIcon from '~/components/icons/TrashIcon';
import InlineIconButton from '~/components/InlineIconButton';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import { CLIVE_MIXPANEL_EVENTS } from '~/pocs/mixpanel';

import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Summary } from './components/Summary';

const AmountSpecialCell: React.FC<{ amount: number }> = ({ amount }) => {
  const { currencyFormatter } = useCurrencyFormatter();

  return <>{currencyFormatter.format(amount || 0)}</>;
};

const generateId = uuidv4;

const TOOLTIP_TEXT_SIZE = '200px';

const statusToColorMap: Record<string, COLORS> = {
  success: 'green',
  error: 'red',
  warning: 'yellow',
  info: 'lightBlue',
};

interface onUpdateProps {
  status: StatusType;
  message?: string;
}

interface ApplyButtonProps {
  row: CoveredItemRow;
  onApply: (item: CoveredItemRow, onUpdate: (data: onUpdateProps) => void) => Promise<void>;
  coverageDecisionMap: Record<string, string>;
  disabled: boolean;
}

const ApplyButton: React.FC<ApplyButtonProps> = ({ row, onApply, coverageDecisionMap, disabled }) => {
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState<StatusType>('initial');
  const [message, setStatusMessage] = useState<string>('');

  const onUpdate = ({ status, message }: { status: StatusType; message?: string }) => {
    setStatus(status);
    setStatusMessage(message ?? '');
  };

  const handleOnClick = async () => {
    setSubmitting(true);
    await onApply(
      {
        id: row.id,
        coverage: row.id,
        amounts: Object.fromEntries(
          Object.entries(row.amounts).filter(([key]) => Object.keys(coverageDecisionMap).includes(key))
        ),
      },
      onUpdate
    );
    setSubmitting(false);
  };

  return status === 'initial' ? (
    <Button key={row.id} disabled={disabled || submitting} color="primary" onClick={handleOnClick}>
      Apply
    </Button>
  ) : (
    <Tooltip key={row.id} title={message}>
      <span>
        <StatusChipCore status={status} color={statusToColorMap[status]} />
      </span>
    </Tooltip>
  );
};

export const InvoiceRecommendationAnalysis: React.FC<InvoiceRecommendationAnalysisProps> = ({
  coverages = [],
  lineItems = [],
  invoiceData = {},
  coverageDecisions = [],
  coInsuranceCoverageRates = {},
  filePath,
  onClose = noop,
  onSave = noop,
  onSubmit = noop,
  onApplyCoveredItem = () => Promise.resolve(),
  open = false,
  disabled = false,
  dialogTitle,
}) => {
  const initialLineItems = lineItems.map((row, index) => {
    const updatedRow = { ...row, order: index };
    updatedRow.covered_amount = calcCoveredAmount(updatedRow, row.amount);

    return updatedRow;
  });
  const [currentLineItems, setCurrentLineItems] = useState<LineItemRow[]>(initialLineItems);
  const [isFileLoaded, setIsFileLoaded] = useState(false);

  const classes = useStyles();
  const updateRow = (row: LineItemRow, field: string, value: string | number | undefined) => {
    let updatedRow;

    setCurrentLineItems((prevState) =>
      prevState.map((currentRow) => {
        if (currentRow.id !== row.id) {
          return currentRow;
        }
        updatedRow = { ...row, [field]: value };
        return updatedRow;
      })
    );
    return updatedRow;
  };

  const coverageKeys = useMemo(() => coverages.map(({ value }) => value), [coverages]);

  const mixpanelFocus = useCallback((field) => {
    mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_INVOICE_PAGE_LINE_ITEM_FOCUSED, {
      focus: 'on',
      field,
    });
  }, []);

  const mixpanelBlur = useCallback((field) => {
    mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_INVOICE_PAGE_LINE_ITEM_FOCUSED, {
      focus: 'off',
      field,
    });
  }, []);

  const coverageDecisionMap = useMemo(() => {
    return coverageDecisions.reduce((acc, { value, description }) => {
      acc[value] = description;
      return acc;
    }, {} as Record<string, string>);
  }, [coverageDecisions]);

  const coverageMap = useMemo(() => {
    return coverages.reduce((acc, { value, description }) => {
      acc[value] = description;
      return acc;
    }, {} as Record<string, string>);
  }, [coverages]);

  const lineItemsColumns = useMemo(
    () => [
      { id: 'order', label: 'Order', isHidden: true },
      {
        id: 'description',
        disableSort: true,
        label: 'Description',
        specialCell: (row: LineItemRow) =>
          disabled ? (
            <OverflowTextWithToolTip maxWidth={TOOLTIP_TEXT_SIZE}>{row.description ?? ''}</OverflowTextWithToolTip>
          ) : (
            <TextField
              inlineEditableField
              onChange={(value) => {
                updateRow(row, 'description', value);
              }}
              onFocus={() => mixpanelFocus('description')}
              onBlur={() => mixpanelBlur('description')}
              value={row.description}
              className={classes.textFieldRow}
            />
          ),
      },
      {
        id: 'amount',
        disableSort: true,
        label: 'Amount',
        specialCell: (row: LineItemRow) =>
          disabled ? (
            <AmountSpecialCell amount={row.amount ?? 0} />
          ) : (
            <MonetaryValueTextField
              value={row.amount}
              onChange={(value) => {
                updateRow(row, 'amount', value);
              }}
              inlineEditableField
              className="w-[100px]"
              onFocus={() => mixpanelFocus('amount')}
              onBlur={() => mixpanelBlur('amount')}
            />
          ),
      },
      {
        id: 'coverage_decision',
        disableSort: true,
        label: 'Coverage Decision',
        specialCell: (row: LineItemRow) => {
          const coverageDecision = row.coverage_decision;

          const sanitizedCoverageDecision = (coverageDecision && coverageDecisionMap[coverageDecision]) ?? '';
          if (disabled) {
            return (
              <OverflowTextWithToolTip maxWidth={TOOLTIP_TEXT_SIZE}>
                {sanitizedCoverageDecision}
              </OverflowTextWithToolTip>
            );
          }
          return (
            <TextField
              variant="outlined"
              inlineEditableField
              onChange={(value) => {
                const updatedRow = updateRow(row, 'coverage_decision', value);

                if (value === 'covered') {
                  updatedRow && updateRow(updatedRow, 'covered_amount', row.amount);
                } else {
                  updatedRow && updateRow(updatedRow, 'covered_amount', 0);
                }
              }}
              onFocus={() => mixpanelFocus('coverage_decision')}
              onBlur={() => mixpanelBlur('coverage_decision')}
              className={classes.textFieldRow}
              label={coverageDecision ? undefined : 'Set Decision'}
              value={coverageDecision || ''}
              select
            >
              {coverageDecisions.map(({ value, description }) => (
                <MenuItem key={value} value={value}>
                  {description}
                </MenuItem>
              ))}
            </TextField>
          );
        },
      },
      {
        id: 'coverage',
        disableSort: true,
        label: 'Coverage',
        specialCell: (row: LineItemRow) => {
          const sanitizedCoverage = row.coverage && coverageKeys.includes(row.coverage) ? row.coverage : '';

          if (disabled) {
            return (
              <OverflowTextWithToolTip maxWidth={TOOLTIP_TEXT_SIZE}>
                {coverageMap[sanitizedCoverage] || ''}
              </OverflowTextWithToolTip>
            );
          }

          return (
            <TextField
              variant="outlined"
              inlineEditableField
              onChange={(value) => {
                updateRow(row, 'coverage', value);
              }}
              className={classes.textFieldRow}
              select
              label={row.coverage ? undefined : 'Select coverage'}
              value={sanitizedCoverage}
              onFocus={() => mixpanelFocus('coverage')}
              onBlur={() => mixpanelBlur('coverage')}
            >
              {coverages.map(({ value, description }) => (
                <MenuItem key={value} value={value}>
                  {description}
                </MenuItem>
              ))}
            </TextField>
          );
        },
      },
      {
        id: 'covered_amount',
        disableSort: true,
        label: 'Covered Amount',
        specialCell: (row: LineItemRow) =>
          disabled ? (
            <AmountSpecialCell amount={row.covered_amount ?? 0} />
          ) : (
            <MonetaryValueTextField
              disabled={row.coverage_decision !== 'covered' || isNil(row.amount)}
              value={row.covered_amount}
              onChange={(value) => {
                updateRow(row, 'covered_amount', calcCoveredAmount(row, value ?? 0));
              }}
              fullWidth
              inlineEditableField
              className="w-[100px]"
              onFocus={() => mixpanelFocus('covered_amount')}
              onBlur={() => mixpanelBlur('covered_amount')}
            />
          ),
      },
      {
        id: 'explanation',
        disableSort: true,
        label: 'Explanation',
        specialCell: (row: LineItemRow) =>
          disabled ? (
            <OverflowTextWithToolTip maxWidth={TOOLTIP_TEXT_SIZE}>{row.explanation ?? ''}</OverflowTextWithToolTip>
          ) : (
            <TextField
              variant="outlined"
              placeholder="-"
              inlineEditableField
              onChange={(value) => {
                updateRow(row, 'explanation', value);
              }}
              className={classes.textFieldRow}
              value={row.explanation}
              onFocus={() => mixpanelFocus('explanation')}
              onBlur={() => mixpanelBlur('explanation')}
            />
          ),
      },
      {
        id: 'actions',
        disableSort: true,
        label: 'Actions',
        align: 'center',
        specialCell: (row: LineItemRow) => (
          <InlineIconButton
            disabled={disabled}
            className={classes.hoverableNonFilledIcon}
            icon={TrashIcon}
            tooltipTitle="Delete"
            onClick={() => setCurrentLineItems((currentRows) => currentRows.filter(({ id }) => id !== row.id))}
          />
        ),
      },
    ],
    [
      classes,
      coverageDecisions,
      coverageKeys,
      coverageDecisionMap,
      coverages,
      disabled,
      coverageMap,
      mixpanelFocus,
      mixpanelBlur,
    ]
  );

  const postCoInsuranceCalc = (amount: number, coverage: string) => amount * (coInsuranceCoverageRates[coverage] || 1);

  const coInsuranceColumns = !isEmpty(coInsuranceCoverageRates)
    ? [
        {
          id: 'co_insurance',
          label: 'Co-Insurance',
          specialCell: (row: CoveredItemRow) => {
            const amount = row.amounts.covered;

            return amount !== undefined ? (
              <AmountSpecialCell amount={amount - postCoInsuranceCalc(amount, row.coverage)} />
            ) : (
              ''
            );
          },
        },
        {
          id: 'post_co_insurance',
          label: 'Post Co-Insurance',
          specialCell: (row: CoveredItemRow) => {
            const amount = row.amounts.covered;

            return amount !== undefined ? <AmountSpecialCell amount={postCoInsuranceCalc(amount, row.coverage)} /> : '';
          },
        },
      ]
    : [];

  const coverageSummaryColumns = [
    {
      id: 'coverage',
      label: 'Coverage',
      specialCell: (row: CoveredItemRow): React.ReactNode =>
        row.coverage !== NO_COVERAGE ? coverages.find(({ value }) => value === row.coverage)?.description : '-',
    },
    {
      id: 'covered',
      label: 'Covered',
      specialCell: (row: CoveredItemRow) =>
        row.amounts.covered !== undefined ? <AmountSpecialCell amount={row.amounts.covered} /> : '',
    },
    {
      id: 'not_covered',
      label: 'Not Covered',
      specialCell: (row: CoveredItemRow) => {
        const totalAmount = coverageDecisions
          .filter(({ value }) => value !== 'covered')
          .reduce((sum, coverageDecision) => {
            return sum + (row.amounts[coverageDecision.value] ?? 0);
          }, 0);

        return <AmountSpecialCell amount={totalAmount} />;
      },
    },
  ]
    .concat(coInsuranceColumns)
    .concat([
      {
        id: 'undecided',
        label: 'Undecided',
        specialCell: (row: CoveredItemRow) =>
          row.amounts[UNDECIDED] !== undefined ? <AmountSpecialCell amount={row.amounts[UNDECIDED]} /> : <></>,
      },
      {
        id: 'action',
        label: 'Action',
        specialCell: (row: CoveredItemRow) =>
          !isEmpty(row.coverage) ? (
            <ApplyButton
              row={row}
              onApply={async (...args) => {
                mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_INVOICE_PAGE_COVERAGE_APPLY_CLICKED);
                onSave({
                  line_items: lineItems,
                  coverages: aggregateCoverages,
                });
                await onApplyCoveredItem(...args);
              }}
              coverageDecisionMap={coverageDecisionMap}
              disabled={disabled}
            />
          ) : (
            <></>
          ),
      },
    ]);

  const aggregateCoverages = getAggregateCoverages(currentLineItems);

  return (
    <CardDialog
      title="Invoice Analysis"
      isDialog
      onClose={onClose}
      fullScreen
      hideTitleContainer
      open={open}
      containerClassName="first:p-0 bg-slate-100"
      footerActions={
        <Footer
          aggregateCoverages={aggregateCoverages}
          disabled={disabled}
          onClose={onClose}
          onSubmit={onSubmit}
          onSave={onSave}
          lineItems={currentLineItems}
        />
      }
    >
      <Header dialogTitle={dialogTitle} />
      <div>
        <Heading variant={Heading.TYPES.H1} className="p-20 pb-0">
          <IconButton
            onClick={() => {
              mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_INVOICE_PAGE_BACK_CLICKED);

              onClose();
            }}
          >
            <ArrowDownwardIcon className="rotate-[90deg]" size={20} />
          </IconButton>
          Invoice Payment
        </Heading>
      </div>
      <div className="p-20">
        <Summary invoiceData={invoiceData} />
        <div className="mb-20 inline-flex w-full gap-20">
          <PanelGroup direction="horizontal" id="group">
            <Panel id="table-display" defaultSize={70}>
              <div className="max-h-screen overflow-auto rounded-lg bg-white p-32 pt-20">
                <Heading variant={Heading.TYPES.H1} className="mb-8">
                  Invoice Line Items
                </Heading>
                <div className="mb-8 inline-flex w-full justify-end">
                  <Button
                    color="primary"
                    disabled={disabled}
                    onClick={() => {
                      mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_INVOICE_PAGE_LINE_ITEM_ADD_ROW_CLICKED);

                      setCurrentLineItems((previousRows) =>
                        previousRows.concat({
                          id: generateId(),
                          order: Math.max(...previousRows.map(({ order }) => order)),
                        })
                      );
                    }}
                  >
                    <AddIcon />
                    Add Item
                  </Button>
                </div>
                <SortableTable columns={lineItemsColumns} rows={currentLineItems} stickyHeader />
              </div>
            </Panel>
            <PanelResizeHandle id="resize-handle" className="mx-12 w-2 rounded-lg bg-slate-500" />
            <Panel id="file-display" className="min-h-40 flex-1 rounded-lg">
              {filePath ? (
                <object
                  className={`${isFileLoaded ? 'h-full min-h-[40vh] w-full' : ''}`}
                  data={filePath}
                  onLoad={() => setIsFileLoaded(true)}
                />
              ) : null}
            </Panel>
          </PanelGroup>
        </div>
        <div className="mb-32 rounded-lg bg-white p-32 pt-20">
          <Heading variant={Heading.TYPES.H1} className="mb-8">
            Coverage Summary
          </Heading>
          <SortableTable columns={coverageSummaryColumns} rows={aggregateCoverages} stickyHeader />
        </div>
      </div>
    </CardDialog>
  );
};
