import React from 'react';

import SvgIcon from '../core/SvgIcon';

const CloseIcon_Deprecated = (props) => (
  <SvgIcon fill="none" {...props}>
    <path d="M2.12817 13.7539L14 2.36428" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M2.12817 2L13.6258 13.7639" strokeWidth="1.5" strokeLinecap="round" />
  </SvgIcon>
);

export default CloseIcon_Deprecated;
