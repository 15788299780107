const APPROVAL_REQUESTS = {
  STATUSES: {
    PENDING: 'pending',
    INTERMEDIATE_APPROVAL: 'intermediate_approval',
    FINAL_APPROVAL: 'final_approval',
    CANCELLED: 'cancelled',
    DECLINED: 'declined',
  },
} as const;

export default APPROVAL_REQUESTS;
