import type { ReactElement } from 'react';
import axios from 'axios';

import {
  getClaimNotificationIcon,
  notificationTypeToLabelDict,
} from '~/components/ClaimNotificationsCard/ClaimNotificationCardUtils';
import type { ClaimNotificationModel, NotificationsType } from '~/components/ClaimNotificationsCard/types';
import type { NotificationTypeCount } from '~/components/Notifications/types';
import type { CLAIM_NOTIFICATIONS_PRIORITY } from '~/Types';
import { reportAxiosError } from '~/Utils';

export const changeClaimNotificationState = async (
  notification: ClaimNotificationModel,
  setNotificationAsSubmitting: (id: number) => void,
  setNotificationAsNotSubmitting: (id: number) => void,
  onUpdate: () => Promise<void> | undefined
): Promise<void> => {
  const action = notification.datetime_dismissed ? 'undismiss' : 'dismiss';
  setNotificationAsSubmitting(notification.id);

  const notificationsResourceLocation = notification.claim_id
    ? `${notification.claim_id}/notifications`
    : 'claims_notifications';

  try {
    await axios.post(`/api/v1/claims/${notificationsResourceLocation}/${notification.id}/${action}`);
    await onUpdate();
    setNotificationAsNotSubmitting(notification.id);
  } catch (error) {
    await reportAxiosError(error);
    setNotificationAsNotSubmitting(notification.id);
    throw error;
  }
};

export const isRestrictedPredicate = (notification: ClaimNotificationModel): boolean | undefined =>
  notification.is_restricted;

export const restrictedContentMessage = `This is attached to a sub-org you don't have permissions for.
  Please contact your system administrator`;

export const getNotificationPriorityOrDefault = (
  notification?: ClaimNotificationModel
): keyof typeof CLAIM_NOTIFICATIONS_PRIORITY => notification?.priority ?? 'low';

export const getNotificationTypeId = (notificationTypeCount: NotificationTypeCount): string =>
  notificationTypeCount.type + '.' + (notificationTypeCount.subtype || '');

export interface PossibleNotificationTypeById {
  type: NotificationsType;
  label: string;
  icon: ReactElement;
  subtype: string;
  id: NotificationsType;
}

export const mapPossibleNotificationsTypesToIds = (
  typesCounts: NotificationTypeCount[]
): { sortedIds: NotificationsType[]; map: { [id: string]: PossibleNotificationTypeById } } => {
  const possibleNotificationTypeById: { [id: string]: PossibleNotificationTypeById } = typesCounts.reduce(
    (acc, notificationTypeCount) => {
      const id = getNotificationTypeId(notificationTypeCount);
      return id in acc
        ? acc
        : {
            ...acc,
            [id]: {
              type: notificationTypeCount.type,
              label: notificationTypeToLabelDict[notificationTypeCount.type],
              icon: getClaimNotificationIcon(notificationTypeCount, '', 20),
              subtype: notificationTypeCount.subtype,
              id,
            },
          };
    },
    {}
  );

  const possibleNotificationTypesDataList = Object.values(possibleNotificationTypeById).sort((type1, type2) =>
    type1.id.localeCompare(type2.id)
  );

  return {
    sortedIds: possibleNotificationTypesDataList.map((notificationData) => notificationData.id),
    map: possibleNotificationTypeById,
  };
};
