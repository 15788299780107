import React from 'react';

import MenuItem from '~/components/core/Atomic/MenuItem';
import SingleSelectField from '~/components/core/Molecules/Fields/SingleSelectField';
import { ChevronDownIcon } from '~/components/icons';
import type { IconProps } from '~/components/icons/types';
import cn from '~/Utils/cn';

export interface EditableChipOption {
  id: string;
  description: string;
  color: EditableChipColors;
  disabled?: boolean;
}
export interface EditableChipProps {
  value?: string;
  onChange: (id: string) => void;
  options: EditableChipOption[];
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}

export type EditableChipColors =
  | 'berry-600'
  | 'berry-700'
  | 'blue-600'
  | 'blueSky-700'
  | 'green-600'
  | 'green-700'
  | 'grey-600'
  | 'slate-800'
  | 'orange-700'
  | 'pink-600'
  | 'purple-600'
  | 'purple-700'
  | 'red-700'
  | 'teal-600'
  | 'yellow-700'
  | 'yellow-800';

const colorsToBgBorderAndTextMap: { [K in EditableChipColors]: string } = {
  'berry-600': 'bg-berry-100 border-berry-600 text-berry-600',
  'berry-700': 'bg-berry-100 border-berry-700 text-berry-700',
  'blue-600': 'border-blue-600 bg-blue-100 text-blue-600',
  'blueSky-700': 'border-blueSky-700 bg-blueSky-100 text-blueSky-700',
  'green-600': 'border-green-600 bg-green-100 text-green-600',
  'green-700': 'border-green-700 bg-green-100 text-green-700',
  'grey-600': 'border-grey-600 bg-slate-100 text-grey-600',
  'slate-800': 'border-slate-800 bg-slate-200 text-slate-800',
  'orange-700': 'border-orange-700 bg-orange-100 text-orange-700',
  'pink-600': 'border-pink-600 bg-pink-100 text-pink-600',
  'purple-600': 'border-purple-600 bg-purple-100 text-purple-600',
  'purple-700': 'border-purple-700 bg-purple-200 text-purple-700',
  'red-700': 'border-red-700 bg-red-100 text-red-700',
  'teal-600': 'border-teal-600 bg-teal-100 text-teal-600',
  'yellow-700': 'border-yellow-700 bg-yellow-100 text-yellow-700',
  'yellow-800': 'border-yellow-800 bg-yellow-100 text-yellow-700',
};

const colorsToStrokeMap: { [K in EditableChipColors]: string } = {
  'berry-600': 'stroke-berry-600',
  'berry-700': 'stroke-berry-700',
  'blue-600': 'stroke-blue-600',
  'blueSky-700': 'stroke-blueSky-700',
  'green-600': 'stroke-green-600',
  'green-700': 'stroke-green-700',
  'grey-600': 'stroke-grey-600',
  'slate-800': 'stroke-slate-800',
  'orange-700': 'stroke-orange-700',
  'pink-600': 'stroke-pink-600',
  'purple-600': 'stroke-purple-600',
  'purple-700': 'stroke-purple-700',
  'red-700': 'stroke-red-700',
  'teal-600': 'stroke-teal-600',
  'yellow-700': 'stroke-yellow-700',
  'yellow-800': 'stroke-yellow-800',
};
const SmallChevronDownIcon: React.FC<IconProps> = (props) => <ChevronDownIcon size={12} {...props} />;

const EditableChip: React.FC<EditableChipProps> = ({ value, onChange, options, className, disabled = false }) => {
  const color = options.find((option) => option.id === value)?.color ?? 'slate-800';

  return (
    <div>
      <div
        id="editable-chip"
        className={cn(
          'max-h-24 max-w-[120px] rounded-full border-1 border-solid px-8 pt-2 outline outline-0',
          {
            [colorsToBgBorderAndTextMap[color]]: colorsToBgBorderAndTextMap[color],
            'pointer pointer-events-none opacity-50': disabled,
          },
          className
        )}
      >
        <SingleSelectField
          disabled={disabled}
          value={value || 'placeholder'}
          onChange={onChange}
          variant="standard"
          InputProps={{
            disableUnderline: true,
            classes: {
              input: `text-${color} focus:bg-transparent text-sm p-0`,
            },
          }}
          SelectProps={{
            IconComponent: SmallChevronDownIcon,
            classes: {
              icon: cn({
                [colorsToStrokeMap[color]]: color,
              }),
              root: 'pr-16',
            },
          }}
          fullWidthField
        >
          <MenuItem key="placeholder" value="placeholder" disabled>
            Select...
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id} disabled={option.disabled}>
              {option.description}
            </MenuItem>
          ))}
        </SingleSelectField>
      </div>
    </div>
  );
};

export default EditableChip;
