import React from 'react';

import type { ActionsPanelProps } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import { RoundedPanel } from '~/components/AiChat/SideBarDialog/components/RoundedPanel/RoundedPanel';
import { replaceDatesWithLocaleString } from '~/components/AiChat/SideBarDialog/utils';
import type { ReserveUpdateTaskData } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { MarkdownSection } from '~/components/core/MarkdownSection';
import { reportErrorInProductionOrThrow } from '~/Utils';

export const UpdateReserveActionInner: React.FC<ActionsPanelProps> = ({ action }) => {
  if (!action?.task_data || action?.task_data?.task_type !== AI_ACTION_TYPES.UPDATE_RESERVE) {
    reportErrorInProductionOrThrow(new Error('Invalid action task data for UpdateReserveActionInner'));
  }

  const taskData = action.task_data as ReserveUpdateTaskData;

  return (
    <RoundedPanel variant="clear">
      <MarkdownSection markdown={replaceDatesWithLocaleString(`#### ${taskData.task_text}`)} />
      <MarkdownSection markdown={replaceDatesWithLocaleString(taskData.explanation)} />
    </RoundedPanel>
  );
};
