import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import type { ClaimModel } from '~/components/types/claim-types';

import UploadDocumentInner from './UploadDocumentsInner/UploadDocumentsInner';
import type { PartialValuesFormik } from './types';

const documentFieldsValidation = () => ({
  claim_id: Yup.number().required(),
  files: Yup.array()
    .required('Required')
    .min(1, 'Required')
    .test('no-failed-uploads', 'Please remove all failed upload documents', (files) => {
      return files ? !files.some((file) => file.isError === true) : true;
    }),
});

interface UploadDocumentProps {
  claim: ClaimModel;
  onCancel: () => void;
  onSubmitDocument: (documents: DocumentFile[]) => Promise<void>;
  alternativeUploadUrl?: string;
}

const UploadDocument: React.FC<UploadDocumentProps> = ({ claim, onCancel, onSubmitDocument }) => {
  const handleSubmitUploadedFiles = async (
    values: PartialValuesFormik,
    {
      setSubmitting,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
      setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => void;
    }
  ) => {
    setSubmitting(true);
    const documents: DocumentFile[] = [];
    (values?.files ?? []).map((fileData) => {
      const storedFileId: number | undefined = fileData?.stored_file_id;
      const payload = { stored_file_id: storedFileId };
      documents.push(payload);
    });
    await onSubmitDocument(documents);
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ claim_id: claim.id, files: [] }}
      validationSchema={Yup.object().shape(documentFieldsValidation())}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {
        await handleSubmitUploadedFiles(values, { setSubmitting, setFieldValue });
      }}
      enableReinitialize
    >
      <UploadDocumentInner
        claim={claim}
        onCancel={onCancel}
        urlPrefix={`/api/v1/claims/${claim.id}/documents`}
        showFileBoxOnly
        trackAlt="clive-upload-documents-modal"
      />
    </Formik>
  );
};

export type DocumentFile = { stored_file_id: number | undefined };
export default UploadDocument;
