import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { keyBy } from 'lodash';

import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import EditableChip from '~/components/core/Molecules/EditableChip';
import useOrganization from '~/components/OrganizationContext';
import type { CategoryModel } from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';
import { reportAxiosError } from '~/Utils';

interface CategoryEditableChipProps {
  claimNotification: ClaimNotificationModel;
  disabled?: boolean;
  setIsUpdating: (isUpdating: boolean) => void;
  onUpdate: () => void | Promise<void>;
  className?: string;
}

const CategoryEditableChip: React.FC<CategoryEditableChipProps> = ({
  claimNotification,
  disabled,
  setIsUpdating,
  onUpdate,
  className,
}) => {
  const notificationCategory = claimNotification.categories?.[0]?.key;
  const [categoryToDisplay, setCategoryToDisplay] = useState(notificationCategory);
  const { organizationCategories, isLoadingCategories, isErrorCategories } = useOrganization() as {
    organizationCategories: CategoryModel[];
    isLoadingCategories: boolean;
    isErrorCategories: boolean;
  };

  useEffect(() => {
    if (!disabled && notificationCategory) {
      setCategoryToDisplay(notificationCategory);
    }
  }, [disabled, notificationCategory]);

  const categoryByKeyMap = keyBy(organizationCategories, 'key');

  // eslint-disable-next-line require-await
  const handleCategoryUpdate = async (category_key: string) => {
    const prevCategory = categoryToDisplay;
    try {
      setIsUpdating(true);
      setCategoryToDisplay(category_key);
      await axios.put(`/api/v1/notifications/${claimNotification.id}/categories`, {
        claim_id: claimNotification.claim_id,
        categories: [categoryByKeyMap[category_key].id],
      });
      await onUpdate();
    } catch (err) {
      await reportAxiosError(err);
      setCategoryToDisplay(prevCategory);
    } finally {
      setIsUpdating(false);
    }
  };

  return !isLoadingCategories && !isErrorCategories ? (
    <EditableChip
      value={categoryToDisplay}
      onChange={handleCategoryUpdate}
      options={organizationCategories.map(({ key, color, label, is_disabled }) => ({
        id: key,
        color,
        description: label,
        disabled: is_disabled,
      }))}
      disabled={disabled}
      className={className}
    />
  ) : null;
};

export default CategoryEditableChip;
