import React from 'react';

import { JusterImage } from '~/components/AiChat/components/JusterImage';
import cn from '~/Utils/cn';

export interface CliveChipProps {
  content: string | React.ReactNode | null;
}

export const CliveChip: React.FC<CliveChipProps> = ({ content }) => {
  return (
    <div
      className={cn(
        'flex w-fit items-center gap-8 rounded-full bg-cliveLightGradiant py-8 pl-8',
        content ? 'pr-16' : 'pr-8',
        'bg-blend-hard-light'
      )}
    >
      <JusterImage width={28} height={28} />
      {content ? <span className="text-base">{content}</span> : <></>}
    </div>
  );
};
