import React from 'react';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '~/components/core';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Divider from '~/components/core/Atomic/Divider/Divider';
import Tooltip from '~/components/core/Atomic/Tooltip';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import ExposureOwnerContainer from '~/components/exposures/ExposureOwnerContainer';
import ExposuresMoreActionsContainer from '~/components/exposures/ExposuresMoreActionsContainer';
import { adjustColumnByClaim } from '~/components/exposures/ExposuresTable/exposuresTableUtils';
import SumContainer from '~/components/exposures/ExposuresTable/SumContainer';
import { GeneralRecoveryPayableWithReserveSum } from '~/components/exposures/GeneralRecoveryDialog';
import PaidSumContainer from '~/components/exposures/PaidSumContainer';
import { RecoveryStatusContainer } from '~/components/exposures/Recovery';
import SubPercentageSum from '~/components/exposures/ReinsuranceTable/SubPercentageSum';
import ReserveContainer from '~/components/exposures/ReserveContainer';
import useFetchExposures from '~/components/exposures/useFetchExposures';
import { useCms } from '~/components/hooks/useCms';
import { LegalActionPriorityIcon } from '~/components/LegalActions/components/LegalActionPriorityIcon';
import { useLegalActions } from '~/components/LegalActions/hooks/useLegalActions';
import { getExposureHighestPriorityLegalAction } from '~/components/LegalActions/LegalActionsUtils';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import PlainTable from '~/components/PlainTable';

const getPayableReserveToShow = (payable) => {
  return payable?.are_pending_reserve_change_requests_exist
    ? payable?.reserve_change_request_pending?.request_amount
    : payable?.reserve;
};

function getPayablePaidSumToShow(payable) {
  return (payable?.paid_sum || 0) + (payable?.pending_sum || 0);
}

const ReinsuranceTable = () => {
  const { claim } = useClaim();

  const classes = useStyles();
  const { userOrganization } = useCms();
  const { legalActions, isLegalActionsLoading, hasLegalActionsPermissions, isLegalActionsFeatureEnabled } =
    useLegalActions();
  const { isExposuresLoading, exposures } = useFetchExposures();

  const shouldDisplayLegalActionsData = !isLegalActionsLoading && hasLegalActionsPermissions;

  const isMarkedAsAdvised = (row) => {
    const { exposure_extra } = row;
    return exposure_extra?.mark_as_advised === true;
  };

  let columnData = [
    {
      id: 'advised',
      numeric: false,
      label: 'Advised',
      align: 'center',
      specialCell: (row) => (
        <Chip
          size="small"
          color={isMarkedAsAdvised(row) ? 'primary' : 'default'}
          label={isMarkedAsAdvised(row) ? 'Advised' : 'Not Advised'}
        />
      ),
    },
    {
      id: 'coverage_type',
      label: 'Layer',
      numeric: false,
      specialCell: (row) => {
        const highestPriorityLegalAction = shouldDisplayLegalActionsData
          ? getExposureHighestPriorityLegalAction(legalActions, row)
          : null;

        return (
          <div style={{ display: 'inline-flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <div>
              <OverflowTextWithToolTip maxWidth="150px">{row.coverage_type_desc}</OverflowTextWithToolTip>
              <Divider className="my-8" />
              <OverflowTextWithToolTip maxWidth="150px" textWrapperClassName="text-sm">{`${
                row?.coverage?.coinsurance_insurer_percentage
                  ? `${row.coverage.coinsurance_insurer_percentage}% of 100%`
                  : '-'
              }`}</OverflowTextWithToolTip>
            </div>
            <span style={{ whiteSpace: 'nowrap', paddingLeft: '6px' }}>
              {row.is_closed && (
                <Tooltip title={row.close_reason}>
                  <Chip size="small" color="primary" label="closed" />
                </Tooltip>
              )}
              {row.is_coverage_issue_exists && (
                <Tooltip title={row.coverage_issue_desc}>
                  <span>
                    <Chip
                      size="small"
                      color="default"
                      label="Coverage Issue"
                      className={`${classes.chip} ${classes.warningBackground}`}
                    />
                  </span>
                </Tooltip>
              )}
              {row.is_in_litigation && !row.is_litigation_closed && !isLegalActionsFeatureEnabled ? (
                <Chip
                  size="small"
                  color="default"
                  label="Litigation"
                  className={`${classes.chip} ${classes.warningBackground}`}
                />
              ) : null}
              {highestPriorityLegalAction ? (
                <Chip
                  size="small"
                  color="default"
                  label="Legal Action"
                  className={classes.chip}
                  icon={<LegalActionPriorityIcon priority={highestPriorityLegalAction.priority} className="ml-4" />}
                />
              ) : null}
            </span>
          </div>
        );
      },
    },
    { id: 'claim_internal_id', label: 'Id', isHidden: true },
    {
      id: 'coverage_deductible',
      numeric: false,
      label: 'Attachment Point',
      specialCell: (row) => {
        return (
          <div>
            -<Divider className="my-8" />
            {row?.coverage?.deductible ? <SumContainer sum={row.coverage.deductible} /> : '-'}
          </div>
        );
      },
    },
    {
      id: 'limit',
      numeric: false,
      label: 'Limit',
      specialCell: (row) => (
        <div>
          {row?.coverage?.limit_per_incident ? (
            <>
              <SumContainer sum={row.coverage.limit_per_incident} fontWeight={700} />
              <Divider className="my-8" />
              <SubPercentageSum exposure={row} value={row?.coverage?.limit_per_incident} />
            </>
          ) : (
            '-'
          )}
        </div>
      ),
    },
    {
      id: 'indemnity_paid_sum',
      numeric: false,
      label: 'Paid',
      specialCell: (row) => {
        const paidIncludingPending = getPayablePaidSumToShow(row?.indemnity);

        return (
          <div>
            <RestrictedPermissions action={PERMISSION_ACTIONS.INDEMNITY_PAYMENT} verb={PERMISSION_VERBS.WRITE}>
              <PaidSumContainer exposure={row} payableWithReserve={row.indemnity} />
              <GeneralRecoveryPayableWithReserveSum payableWithReserve={row.indemnity} />
              <RecoveryStatusContainer exposure={row} />
              <Divider className="my-8" />
              <SubPercentageSum exposure={row} value={paidIncludingPending} />
            </RestrictedPermissions>
          </div>
        );
      },
    },
    {
      id: 'indemnity_reserve',
      numeric: false,
      label: 'Outstanding',
      specialCell: (row) => {
        return (
          <RestrictedPermissions action={PERMISSION_ACTIONS.INDEMNITY_RESERVE} verb={PERMISSION_VERBS.WRITE}>
            <ReserveContainer exposure={row} payableWithReserve={row.indemnity} title="Update Outstanding" />
            <Divider className="my-8" />
            <SubPercentageSum exposure={row} value={getPayableReserveToShow(row?.indemnity)} />
          </RestrictedPermissions>
        );
      },
    },
    {
      id: 'incurred',
      numeric: false,
      label: 'Incurred',
      specialCell: (row) => {
        // For regular rows, show the sum of paid and outstanding
        const paidAmount = getPayablePaidSumToShow(row?.indemnity) || 0;
        const outstandingAmount = getPayableReserveToShow(row?.indemnity) || 0;
        const amount = paidAmount + outstandingAmount;

        return (
          <>
            <SumContainer sum={amount} fontWeight={700} />
            <Divider className="my-8" />
            <SubPercentageSum exposure={row} value={amount} />
          </>
        );
      },
    },
    {
      id: 'expenses_reserve',
      numeric: false,
      label: 'ACR',
      specialCell: (row) => {
        const action = row.isGeneralExpenses
          ? PERMISSION_ACTIONS.GENERAL_EXPENSES_RESERVE
          : PERMISSION_ACTIONS.EXPENSES_RESERVE;

        return (
          <RestrictedPermissions action={action} verb={PERMISSION_VERBS.WRITE}>
            <ReserveContainer exposure={row} payableWithReserve={row.expenses} title="Update ACR" />
            <Divider className="my-8" />
            <SubPercentageSum exposure={row} value={getPayableReserveToShow(row?.expenses)} />
          </RestrictedPermissions>
        );
      },
    },
    {
      id: 'ultimate',
      numeric: false,
      label: 'Ultimate',
      specialCell: (row) => {
        // For regular rows, calculate ultimate (paid + outstanding + ACR)
        const paidAmount = getPayablePaidSumToShow(row.indemnity) || 0;
        const outstandingAmount = getPayableReserveToShow(row.indemnity) || 0;
        const acrAmount = getPayableReserveToShow(row.expenses) || 0;
        const ultimateAmount = paidAmount + outstandingAmount + acrAmount;

        return (
          <>
            <SumContainer sum={ultimateAmount} fontWeight={700} />
            <Divider className="my-8" />
            <SubPercentageSum exposure={row} value={ultimateAmount} />
          </>
        );
      },
    },
    {
      id: 'owner',
      label: 'Owner',
      numeric: false,
      specialCell: (row) => <ExposureOwnerContainer exposure={row} />,
    },
    {
      id: 'more_actions',
      rightPaddingOnly: true,
      numeric: false,
      width: '30px',
      specialCell: (row) => <ExposuresMoreActionsContainer exposure={row} />,
    },
  ];

  columnData = adjustColumnByClaim(columnData, claim, userOrganization);

  // The default order column should be the claim_internal_id column (1), not the coverage_type column (0)
  const claimInternalIdColumnIndex = columnData?.findIndex((col) => col?.id === 'claim_internal_id') || 1;
  const displayLoading = isExposuresLoading || (hasLegalActionsPermissions && isLegalActionsLoading);

  return (
    <CardDialog title="Claim Management">
      {displayLoading && <SkeletonTable rowsCount={5} columnsCount={12} />}
      {!displayLoading && (
        <PlainTable
          defaultOrderColumn={claimInternalIdColumnIndex}
          columns={columnData}
          rows={exposures}
          shouldRenderExpandAsTable
          hasExpandableRows
        />
      )}
    </CardDialog>
  );
};

export default ReinsuranceTable;
