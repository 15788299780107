import React from 'react';

import ClaimLink from '~/components/ClaimLink';
import type { Recommendation } from '~/components/communications/CommunicationAiBanner';
import { ActionButton, CommunicationAiBanner } from '~/components/communications/CommunicationAiBanner';
import type {
  BundleClassification,
  GeneralEmailAnalysisExtra,
  RelatedPolicyRecommendation,
} from '~/components/communications/GeneralEmailCommunicationAiAnalysis/generalEmailAnalysisExtra';
import { CommunicationAiAnalysisVersion } from '~/components/communications/GeneralEmailCommunicationAiAnalysis/generalEmailAnalysisExtra';
import {
  doesFnolCategoryExists,
  getFnolCategoryClassification,
} from '~/components/communications/GeneralEmailCommunicationAiAnalysis/GeneralEmailCommunicationAiAnalysisUtils';
import type { FnolAiInboxRecord } from '~/components/Fnol/FnolAiInbox/types';

interface GeneralEmailCommunicationAiAnalysisActions {
  onAttachToClaim?: (claimId: number, extraParams: Record<string, unknown>) => void;
  onMoveToFnolQueue?: () => void;
}

interface GeneralEmailCommunicationAiAnalysisBannerProps {
  communicationType: string;
  communicationAiAnalysis: GeneralEmailAnalysisExtra;
  communicationAiAnalysisActions: GeneralEmailCommunicationAiAnalysisActions;
  record?: FnolAiInboxRecord;
}

const retrievePolicyRecommendation = (analysis: GeneralEmailAnalysisExtra): RelatedPolicyRecommendation => {
  const { related_policy_recommendation } = analysis.classification_response;
  return related_policy_recommendation as RelatedPolicyRecommendation;
};

const retrieveRecommendation = (
  analysis: GeneralEmailAnalysisExtra,
  communicationType: string,
  communicationAiAnalysisActions: GeneralEmailCommunicationAiAnalysisActions
): Recommendation | null => {
  if (analysis.version !== CommunicationAiAnalysisVersion) {
    return null;
  }

  const { related_claim_recommendation } = analysis.classification_response;

  if (related_claim_recommendation?.claim_number) {
    const onAttachToClaim = communicationAiAnalysisActions.onAttachToClaim;
    return {
      title: `This ${communicationType} is a claim update - ${related_claim_recommendation.claim_number}`,
      classification_reasons: related_claim_recommendation.classification_reasons,
      explanation_component: (
        <ClaimLink
          claimId={related_claim_recommendation.claim_internal_information['claim_id'] as number}
          linkText={related_claim_recommendation.claim_number}
        />
      ),
      actionComponent: onAttachToClaim ? (
        <ActionButton
          text="Attach to Claim"
          onClick={() =>
            onAttachToClaim(related_claim_recommendation.claim_internal_information.claim_id as number, {})
          }
        />
      ) : null,
    };
  }

  if (doesFnolCategoryExists(analysis)) {
    const fnol_classification = getFnolCategoryClassification(analysis) as BundleClassification;
    return {
      title: `This ${communicationType} is a new claim`,
      explanation_component: <></>,
      classification_reasons: fnol_classification.classification_reasons,
      actionComponent: communicationAiAnalysisActions.onAttachToClaim ? (
        <ActionButton text="Move to FNOL Inbox" onClick={communicationAiAnalysisActions.onMoveToFnolQueue} />
      ) : null,
    };
  }

  return null;
};

export const GeneralEmailCommunicationAiAnalysisBanner: React.FC<GeneralEmailCommunicationAiAnalysisBannerProps> = ({
  communicationAiAnalysis,
  communicationType,
  communicationAiAnalysisActions,
}) => {
  if (!communicationAiAnalysis || communicationAiAnalysis.version !== CommunicationAiAnalysisVersion) {
    return null;
  }

  const recommendation = retrieveRecommendation(
    communicationAiAnalysis,
    communicationType,
    communicationAiAnalysisActions
  );

  const recommendedPolicy = retrievePolicyRecommendation(communicationAiAnalysis);

  return (
    <CommunicationAiBanner
      communicationType={communicationType}
      recommendation={recommendation}
      recommendedPolicy={recommendedPolicy}
      summary={communicationAiAnalysis.classification_response.summary}
    />
  );
};
export default GeneralEmailCommunicationAiAnalysisBanner;
