import React from 'react';

import Text from '~/components/core/TextComponents/Text';

const ICON_COLOR_MAPPING = {
  azure: 'border-azure-700 bg-azure-200 text-azure-700',
  gold: 'border-gold-700 bg-gold-200 text-gold-700',
  pink: 'border-pink-700 bg-pink-200 text-pink-700',
};

interface CounterViewProps {
  icon: React.ReactNode;
  label: string;
  count: number | '-';
  iconColor: keyof typeof ICON_COLOR_MAPPING;
}

const CounterView: React.FC<CounterViewProps> = ({ icon, label, count, iconColor }) => {
  return (
    <div className="mb-16 flex w-full space-y-4 rounded-md border-1 border-solid border-slate-500 bg-white p-16 shadow-sm">
      <div className="flex items-center justify-center">
        <div className={`flex items-center justify-center rounded-md p-12 ${ICON_COLOR_MAPPING[iconColor]}`}>
          {icon}
        </div>
      </div>
      <div className="flex flex-col items-start justify-start pl-20">
        <Text variant={Text.VARIANTS.XS} weight={Text.WEIGHTS.REGULAR}>
          {label}
        </Text>
        <Text variant={Text.VARIANTS.LG} weight={Text.WEIGHTS.SEMI_BOLD}>
          {`${count}`}
        </Text>
      </div>
    </div>
  );
};

export default CounterView;
