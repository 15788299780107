import React from 'react';

import { CliveChip } from '~/components/AiChat/CliveChip/CliveChip';
import type { GeneralEmailAnalysisExtra } from '~/components/communications/GeneralEmailCommunicationAiAnalysis/generalEmailAnalysisExtra';
import { CommunicationAiAnalysisVersion } from '~/components/communications/GeneralEmailCommunicationAiAnalysis/generalEmailAnalysisExtra';
import { doesFnolCategoryExists } from '~/components/communications/GeneralEmailCommunicationAiAnalysis/GeneralEmailCommunicationAiAnalysisUtils';

interface GeneralEmailCommunicationAiAnalysisColumnIndicatorProps {
  gqAnalysisExtra: GeneralEmailAnalysisExtra;
}

export const GeneralEmailCommunicationAiAnalysisColumnIndicator: React.FC<
  GeneralEmailCommunicationAiAnalysisColumnIndicatorProps
> = ({ gqAnalysisExtra }) => {
  if (
    !gqAnalysisExtra ||
    !gqAnalysisExtra.classification_response ||
    gqAnalysisExtra.version !== CommunicationAiAnalysisVersion
  ) {
    return null;
  }

  let chipContent;
  if (gqAnalysisExtra.classification_response.related_claim_recommendation) {
    chipContent = 'Claim Update';
  } else if (doesFnolCategoryExists(gqAnalysisExtra)) {
    chipContent = 'New FNOL';
  }

  return <CliveChip content={chipContent} />;
};
