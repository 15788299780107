import React from 'react';
import { useFormikContext } from 'formik';

import MenuItem from '~/components/core/Atomic/MenuItem';
import useOrganization from '~/components/OrganizationContext';
import type { CategoryModel } from '~/components/SystemConfiguration/NotificationsConfiguration/NotificationsCategories';
import TextFieldFormik from '~/components/TextFieldFormik';
import { CLAIM_NOTIFICATIONS_PRIORITY } from '~/Types';

const PriorityCategoryFieldsFormikInner: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organizationCategories, isLoadingCategories, isErrorCategories } = useOrganization();
  const { isSubmitting } = useFormikContext();

  return (
    <div className="grid grid-cols-2 gap-8">
      <TextFieldFormik id="priority" label="Priority" disabled={isSubmitting} className="my-4" fullWidth select>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/*@ts-ignore*/}
        {Object.keys(CLAIM_NOTIFICATIONS_PRIORITY).map((priority: keyof typeof CLAIM_NOTIFICATIONS_PRIORITY) => (
          <MenuItem key={priority} value={priority}>
            {CLAIM_NOTIFICATIONS_PRIORITY[priority]}
          </MenuItem>
        ))}
      </TextFieldFormik>
      {!isLoadingCategories && !isErrorCategories && organizationCategories ? (
        <TextFieldFormik id="category_id" label="Category" disabled={isSubmitting} className="my-4" fullWidth select>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*@ts-ignore*/}
          {organizationCategories.map((category: CategoryModel) => (
            <MenuItem key={category.key} value={category.id}>
              {category.label}
            </MenuItem>
          ))}
        </TextFieldFormik>
      ) : null}
    </div>
  );
};

export default PriorityCategoryFieldsFormikInner;
