import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';

import CardDialog from '~/components/CardDialog';
import EmptyState from '~/components/core/EmptyState';
import RoundEmptyStateWrapper from '~/components/core/EmptyState/RoundEmptyStateWrapper';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import TablePaginationSection from '~/components/core/Tables/SortableTable/TablePaginationSection';
import type { PaginationProps } from '~/components/core/Tables/SortableTable/TablePaginationSection/types';
import CreateEditNoteDialog from '~/components/Notes/CreateEditNoteDialog';
import ExpandCollapseAllButtons from '~/components/Notes/ExpandCollapseAllButtons';
import type { Note, NotesProps } from '~/components/Notes/types';
import useDataFetcher from '~/components/useDataFetcher';
import { DateFormat, stringDateIsOfFormat } from '~/DateTimeUtils';
import { NOTE_SUBJECT } from '~/Types';

import NoteIcon from '../icons/NoteIcon';

import NoteMiniCard from './NoteMiniCard';
import type { NotesFiltersValues } from './NotesFilter';
import NotesFilter from './NotesFilter';

export const rowsPerPageOptions: { value: number; label: string }[] = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 100, label: '100' },
  { value: -1, label: 'All' },
];

const NotesInner: React.FC<NotesProps> = ({
  isActive = true,
  noCard,
  hideTypeFilter,
  claim,
  exposureOptionsIds,
  notesConfiguration,
}) => {
  const [pagination, setPagination] = useState<Omit<PaginationProps, 'onChangePage' | 'count'>>({
    page: 0,
    rowsPerPage:
      notesConfiguration.is_default_notes_per_page_enabled && notesConfiguration.default_notes_per_page
        ? notesConfiguration.default_notes_per_page
        : 20,
  });
  const { page, rowsPerPage } = pagination;

  const [filters, setFilters] = useState<NotesFiltersValues>({
    exposure_ids: [],
    adjuster_id: null,
    creation_mode_type: null,
    start_date: null,
    end_date: null,
  });
  const [noteToEdit, setNoteToEdit] = useState<Note | null>(null);
  const [expandedNotesMap, setExpandedNotesMap] = useState<{ [id: number]: boolean }>({});

  const params = useMemo(
    () => ({
      params: {
        page_number: page + 1,
        results_per_page: rowsPerPage,
        ...filters,
      },
    }),
    [filters, page, rowsPerPage]
  );

  const handleEditClicked = (note: Note) => setNoteToEdit(note);

  const handleEditDialogClosed = () => {
    setNoteToEdit(null);
  };

  const handleNoteSubmitted = () => {
    handleEditDialogClosed();
    reloadData();
  };

  const {
    isLoading,
    isError,
    data = { notes: [], total_notes: 0, adjuster_ids: {}, note_subjects: [] },
    reloadData,
  }: {
    isLoading: boolean;
    isError: boolean;
    data?: {
      notes: Note[];
      total_notes: number;
      adjuster_ids: { [id: string]: string };
      note_subjects: (keyof typeof NOTE_SUBJECT)[];
    };
    reloadData: () => void;
  } = useDataFetcher(`/api/v1/claims/${claim.id}/notes/paginated`, params);

  const isFirstRun = React.useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    reloadData();
  }, [claim, reloadData]);

  const displayLoading = isLoading || isError;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    debounce((values) => {
      values.start_date = stringDateIsOfFormat(values.start_date, DateFormat.ISO) ? values.start_date : null;
      values.end_date = stringDateIsOfFormat(values.end_date, DateFormat.ISO) ? values.end_date : null;
      setFilters(values);
      setPagination({ rowsPerPage, page: 0 });
    }, 300),
    [setFilters, rowsPerPage]
  );

  if (!isActive) {
    return null;
  }

  return (
    <CardDialog title="Notes" passThrough={noCard}>
      <NotesFilter
        hideTypeFilter={hideTypeFilter}
        onSearch={onSearch}
        adjusterOptionsIds={data.adjuster_ids}
        noteSubjectsOptions={data.note_subjects.filter((option) => !!NOTE_SUBJECT[option])}
        exposureOptionsIds={exposureOptionsIds}
      />
      <div className="flex items-center justify-between">
        <ExpandCollapseAllButtons
          setForceExpandCollapse={(shouldOpen) => {
            const notesExpandMap: { [id: number]: boolean } = {};
            data.notes.forEach((note) => {
              notesExpandMap[note.id] = shouldOpen;
            });

            setExpandedNotesMap(notesExpandMap);
          }}
        />
        <TablePaginationSection
          combinedPaginationProps={{
            rowsPerPage,
            page,
            rowsPerPageOptions,
            count: data.total_notes,
            onChangePage: (_e, nextPage) => setPagination({ rowsPerPage, rowsPerPageOptions, page: nextPage }),
            onChangeRowsPerPage: (e) => setPagination({ rowsPerPageOptions, page: 0, rowsPerPage: e.target.value }),
          }}
        />
      </div>
      {displayLoading && <SkeletonTable rowsCount={10} columnsCount={1} cellClassName="h-[120px]" />}
      {!displayLoading &&
        data.notes.length > 0 &&
        data.notes.map((note) => (
          <NoteMiniCard
            key={note.id}
            note={note}
            claim={claim}
            onNoteUpdate={reloadData}
            configuration={notesConfiguration}
            onEdit={() => handleEditClicked(note)}
            isExpanded={
              expandedNotesMap?.[note.id] === undefined
                ? !!notesConfiguration?.is_notes_expanded_by_default
                : expandedNotesMap?.[note.id]
            }
            onCollapseClick={(isExpanded: boolean) =>
              setExpandedNotesMap({ ...expandedNotesMap, [note.id]: isExpanded })
            }
            isCollapsible
          />
        ))}
      {!displayLoading && data.notes.length === 0 && (
        <EmptyState
          title="No notes were found, try to update the filters"
          illustration={
            <RoundEmptyStateWrapper>
              <NoteIcon iconColor="inherit" />
            </RoundEmptyStateWrapper>
          }
        />
      )}
      <TablePaginationSection
        combinedPaginationProps={{
          rowsPerPage,
          page,
          rowsPerPageOptions,
          count: data.total_notes,
          onChangePage: (_e, nextPage) => setPagination({ rowsPerPage, rowsPerPageOptions, page: nextPage }),
          onChangeRowsPerPage: (e) => setPagination({ rowsPerPageOptions, page: 0, rowsPerPage: e.target.value }),
        }}
      />
      {noteToEdit && (
        <CreateEditNoteDialog note={noteToEdit} onClose={handleEditDialogClosed} onSubmitNote={handleNoteSubmitted} />
      )}
    </CardDialog>
  );
};

export default NotesInner;
