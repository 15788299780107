import React, { useEffect } from 'react';

import useCountersView from '~/components/hooks/useCountersView';
import { EmailIcon } from '~/components/icons/notifications';
import BellRingIcon from '~/components/Notifications/Kanban/NotificationCountersView/BellRingIcon';

import CounterView from './CounterView';
import PlusCircleIcon from './PlusCircleIcon';

interface NotificationCountersViewProps {
  reloadingUuid: string | undefined;
}

const NotificationCountersView: React.FC<NotificationCountersViewProps> = ({ reloadingUuid }) => {
  const { reloadData, countersView: { unread_notifications_count, general_queue_count, fnol_queue_count } = {} } =
    useCountersView();

  useEffect(() => {
    reloadData();
  }, [reloadingUuid, reloadData]);

  return (
    <div className="flex w-full items-center justify-center space-x-20">
      <CounterView
        label="Unread Notifications"
        count={unread_notifications_count ?? '-'}
        icon={<BellRingIcon size={24} iconColor="currentColor" />}
        iconColor="azure"
      />
      <CounterView
        label="General Queue"
        count={general_queue_count ?? '-'}
        icon={<EmailIcon size={24} iconColor="currentColor" />}
        iconColor="gold"
      />
      <CounterView
        label="FNOL Queue"
        count={fnol_queue_count ?? '-'}
        icon={<PlusCircleIcon size={24} iconColor="currentColor" />}
        iconColor="pink"
      />
    </div>
  );
};

export default NotificationCountersView;
