import React, { useEffect, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Snackbar } from '@material-ui/core';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import Grid from '~/components/core/Atomic/Grid/Grid';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';

import CardDialog from '../CardDialog';
import { useCms } from '../hooks/useCms';
import useDataFetcher from '../useDataFetcher';

import CommunicationsTable from './CommunicationsTable';
import ViewCommunicationCardContainer from './ViewCommunicationCardContainer';

const COMMS_PER_PAGE = 20;

const GeneralEmailSentCommunicationTab = () => {
  const classes = useStyles();

  const { user, setPageTitle } = useCms();
  const [selectedCommunicationId, setSelectedCommunicationId] = useState();
  const [page, setPage] = useState(0);

  useEffect(
    () => setPageTitle('General Mail Classification', 'General Mail Classification - Five Sigma CMS'),
    [setPageTitle]
  );
  const {
    isLoading,
    data: communicationResult = {},
    reloadData,
  } = useDataFetcher(`/api/v1/organizations/${user.organization_id}/general_email_queue_outgoing_emails`, {
    params: {
      page: page + 1, // page in the flask-SQLAlchemy is 1-based,
      rows_per_page: COMMS_PER_PAGE,
    },
  });

  const displayLoading = isLoading && isEmpty(communicationResult);

  const { email_communications: emailCommunications = [], count = 0 } = communicationResult;
  return (
    <div className={classes.pageBody}>
      <Grid container direction="row" style={{ padding: '20px' }}>
        <Grid container spacing={1}>
          <PanelGroup direction="horizontal" id="general-mail-classification">
            <Panel id="table-display" defaultSize={70}>
              {displayLoading && <SkeletonTable rowsCount={15} columnsCount={5} />}
              {!displayLoading && (
                <CommunicationsTable
                  communications={emailCommunications}
                  selectedCommunicationId={selectedCommunicationId}
                  onSelectCommunication={(communicationId) => setSelectedCommunicationId(communicationId)}
                  paginationProps={{
                    page,
                    rowsPerPage: COMMS_PER_PAGE,
                    onChangePage: (_, newPage) => setPage(newPage),
                    count: count || 0,
                    rowsPerPageOptions: [COMMS_PER_PAGE],
                  }}
                  removeExposuresColumn
                  addClaimColumn={false}
                  disableSortByUser
                  removeDirectionColumn
                  removeAdjusterColumn
                  showCLabel={false}
                  addSummaryColumn={false}
                  addSubjectColumn
                />
              )}
            </Panel>
            {selectedCommunicationId && (
              <>
                <PanelResizeHandle id="resize-handle" className="mx-12 w-2 rounded-lg bg-slate-500" />
                <Panel id="communication-display" style={{ overflow: 'auto' }} minSize={35} maxSize={80}>
                  <CardDialog
                    headerStyle={{ padding: '0' }}
                    contentStyle={{ padding: '8px 0 0 0' }}
                    containerStyle={{
                      marginLeft: '16px',
                      maxHeight: `${window.innerHeight - 200}px`,
                      overflow: 'auto',
                    }}
                    noCardTitle
                  >
                    <ViewCommunicationCardContainer
                      key={selectedCommunicationId}
                      communicationId={selectedCommunicationId}
                      onUpdate={reloadData}
                      displayAttachClaim={false}
                      hideEmailCommunicationAction
                    />
                  </CardDialog>
                </Panel>
              </>
            )}
          </PanelGroup>

          {isLoading && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open message="Loading..." />}
        </Grid>
      </Grid>
    </div>
  );
};

export default GeneralEmailSentCommunicationTab;
