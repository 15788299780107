import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CheckboxFormik from '~/components/CheckboxFormik';
import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { useCms } from '~/components/hooks/useCms';
import { InfoIcon } from '~/components/icons';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

import CardDialog from '../CardDialog';
import { ConfirmModal } from '../ConfirmModal';
import { LoadingSwitch } from '../core';
import TooltipIcon from '../core/TooltipIcon';
import LoadingDialog from '../LoadingDialog';
import TextFieldFormik from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import { useStyles } from '../../assets/styles';

function CloseClaimDialog({ title, claim, onReasonChosen, onClose }) {
  const classes = useStyles();
  const { userOrganization } = useCms();
  const { isLoading, isError, data: closingReasons } = useDataFetcher(`/api/v1/claims/${claim.id}/closing_reasons`);
  const {
    isLoading: isCloseRequirementsLoading,
    isError: isCloseRequirementsError,
    data: mandatoryUnansweredFields,
  } = useDataFetcher(`/api/v1/claims/${claim.id}/close_requirements`);
  const reasonDict = Object.fromEntries(closingReasons?.map((x) => [x.closing_reason_key, x]) || []);

  if (isCloseRequirementsLoading || isCloseRequirementsError) {
    return <LoadingDialog onClose={onClose} isError={isCloseRequirementsError} track="" />;
  }

  const isNotifications2Enabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2);

  if (mandatoryUnansweredFields?.length > 0) {
    const mandatoryUnansweredFieldsDisplayNames = mandatoryUnansweredFields?.map((field) => (
      <>
        <br />
        {field.desc}
      </>
    ));
    return (
      <ConfirmModal
        isOpen={true}
        title="Claim Cannot be Closed"
        contentText={
          <>
            The following details must be filled to close the claim:
            {mandatoryUnansweredFieldsDisplayNames}
          </>
        }
        onClose={onClose}
        primaryButtonName="OK"
        onPrimaryBtnClick={onClose}
        removeSecondaryButton
      />
    );
  }

  return (
    <Formik
      initialValues={{ claim_reason: '', claim_extra: '', should_dismiss_all_claim_notifications: false }}
      validationSchema={Yup.object().shape({
        claim_reason: Yup.string().required('Required'),
        claim_extra: Yup.string().when('claim_reason', {
          is: (val) => val && reasonDict?.[val].is_note_required,
          then: Yup.string().required('Required'),
        }),
        should_dismiss_all_claim_notifications: Yup.boolean(),
      })}
      enableReinitialize
      onSubmit={onReasonChosen}
    >
      {(formikProps) => {
        const { values, isSubmitting, handleSubmit } = formikProps;
        const isNoteEnabled = values.claim_reason && reasonDict?.[values.claim_reason]?.is_note_enabled;

        return (
          <CardDialog
            isDialog={true}
            open={true}
            title={title}
            fullWidth
            maxWidth="sm"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <LoadingSwitch isLoading={isLoading} isError={isError}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextFieldFormik id="claim_reason" select label="Reason" className={classes.textField} fullWidth>
                    {Object.keys(reasonDict || {})
                      .sort((a, b) =>
                        (reasonDict?.[a]?.display_name || '').localeCompare(reasonDict?.[b]?.display_name || '')
                      )
                      .map((reason) => (
                        <MenuItem key={reason} value={reason}>
                          {reasonDict?.[reason]?.display_name}
                        </MenuItem>
                      ))}
                  </TextFieldFormik>
                </Grid>
                <Grid item xs={6}>
                  <TextFieldFormik
                    id="claim_extra"
                    label="Note"
                    disabled={!isNoteEnabled}
                    fullWidth
                    className={classes.textField}
                  />
                </Grid>
                {isNotifications2Enabled ? (
                  <Grid item xs={12}>
                    <CheckboxFormik
                      id="should_dismiss_all_claim_notifications"
                      label={
                        <div className="inline-flex items-baseline">
                          Dismiss all claim notifications
                          <TooltipIcon
                            placement="right"
                            arrow
                            title="Only notifications you are authorized to dismiss will be affected"
                          >
                            <InfoIcon className="ml-4" size={18} />
                          </TooltipIcon>
                        </div>
                      }
                    />
                  </Grid>
                ) : null}
              </Grid>
              <Grid />
              <div className={classes.buttonsContainer}>
                <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
                  Close Claim
                </Button>
              </div>
            </LoadingSwitch>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

CloseClaimDialog.propTypes = {
  title: PropTypes.string,
  claim: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onReasonChosen: PropTypes.func.isRequired,
};

export default CloseClaimDialog;
