import type { ReactNode } from 'react';
import React from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';
import Text from '~/components/core/TextComponents/Text';
import { PencilIcon, SearchIcon } from '~/components/icons';
import EyeIcon from '~/components/icons/EyeIcon';
import cn from '~/Utils/cn';

export interface PolicySelectionButtonProps {
  policyNumber?: string;
  disabled?: boolean;
  viewOnly?: boolean;
  onClick?: () => void;
}

export const PolicySelectionButton: React.FC<PolicySelectionButtonProps> = ({
  policyNumber,
  disabled = false,
  viewOnly = false,
  onClick,
}) => {
  const onClickHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (disabled) return;
    onClick && onClick();
  };

  const Icon = React.useMemo<ReactNode | undefined>(() => {
    if (viewOnly) return <EyeIcon className="ml-auto" />;
    return disabled ? (
      policyNumber ? (
        <EyeIcon className="ml-auto" />
      ) : undefined
    ) : policyNumber ? (
      <PencilIcon className="ml-auto" />
    ) : (
      <SearchIcon className="ml-auto stroke-slate-800" size={13} />
    );
  }, [disabled, policyNumber, viewOnly]);

  return (
    <Button
      onClick={onClickHandler}
      className={cn('h-24 w-full select-text rounded-full bg-slate-400 hover:bg-slate-500', {
        'cursor-default hover:bg-slate-300': disabled,
      })}
      disableRipple={disabled}
    >
      <span className="flex w-full items-center gap-8 text-nowrap">
        <Text className="" weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.XS}>
          <>{policyNumber || 'Not Found'}</>
        </Text>
        {Icon}
      </span>
    </Button>
  );
};
