import React from 'react';
import type { DropzoneRef } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import { FormHelperText } from '@material-ui/core';
import type { AxiosError } from 'axios';
import axios from 'axios';
import { useFormikContext } from 'formik';
import { get, isEmpty } from 'lodash';

import CardDialog from '~/components/CardDialog';
import AssistanceAiBanner from '~/components/core/AssistanceAiBanner';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import Text from '~/components/core/TextComponents/Text';
import type { ClaimModel } from '~/components/types/claim-types';
import { reportGenericException } from '~/Utils';

import ChooseFilesSection from '../ChooseFilesSection/ChooseFilesSection';
import DocumentFieldsSection from '../DocumentFieldsSection/DocumentFieldsSection';
import SelectedFilesList from '../SelectedFilesList/SelectedFilesList';
import type { FileData, PartialValuesFormik } from '../types';
import useGenAiDoc from '../useGenAiDoc/useGenAiDoc';
import { fileNameToDocumentName, uploadStoredFile } from '../utils';

export interface UploadDocumentsInnerProps {
  claim?: ClaimModel;
  showFileBoxOnly?: boolean;
  uploadOutsideOfClaim?: boolean;
  allowedDocumentTypes?: string[];
  onCancel?: () => void;
  files?: FileData[];
  onSubmit?: (values: PartialValuesFormik) => void;
  urlPrefix: string;
  trackAlt?: string;
}

const UploadDocumentsInner: React.FC<UploadDocumentsInnerProps> = ({
  claim,
  showFileBoxOnly,
  uploadOutsideOfClaim,
  allowedDocumentTypes,
  onCancel,
  urlPrefix,
  trackAlt,
}) => {
  const { handleSubmit, isSubmitting, setFieldValue, setFieldTouched, values, errors } =
    useFormikContext<PartialValuesFormik>();
  const dropzoneRef = React.createRef() as React.RefObject<DropzoneRef>;
  const files = get(values, 'files', []);
  const { onFileSelectGenAiDoc, displayAIBanner } = useGenAiDoc(claim);

  const newFileNameAdditionalText =
    files?.length > 1 ? `files will be named ${values['document_name']} (1), ${values['document_name']} (2)...` : '';

  const isUploading = files?.some((file) => file?.isLoading);

  const onFilesSelect = async (files: File[]) => {
    const existingFiles = values?.files || [];
    const newFiles = files.filter(
      (newFile) =>
        !existingFiles.some((existingFile) => existingFile.name === newFile.name && existingFile.size === newFile.size)
    );

    if (newFiles.length === 0) return;

    const updatedFiles = [
      ...existingFiles,
      ...newFiles.map((file) => ({
        file,
        file_size: file.size,
        size: file.size,
        name: file.name,
        file_last_modified: new Date(file.lastModified).toISOString(),
        isLoading: false,
        percent: 0,
        isError: false,
      })),
    ];

    setFieldValue('files', updatedFiles);
    setFieldTouched('files', true);
    newFiles?.forEach((file, i) => {
      const newFileIndex = existingFiles.length + i;
      setFieldValue(`files[${newFileIndex}].file`, file);
      setFieldValue(`files[${newFileIndex}].file_last_modified`, new Date(file.lastModified).toISOString());
      setFieldValue(`files[${newFileIndex}].file_size`, file.size);
      setFieldTouched(`files[${newFileIndex}].file_size`, true);

      if (!get(values, `files[${newFileIndex}].document_name`)) {
        setFieldValue(`files[${newFileIndex}].document_name`, fileNameToDocumentName(file.name));
        setFieldTouched(`files[${newFileIndex}].document_name`, true);
      }
    });
    if (existingFiles.length === 0 && newFiles.length === 1) {
      onFileSelectGenAiDoc({ files: newFiles, setFieldValue });
    }

    const uploadPromises = newFiles?.map(async (file, i) => {
      const newFileIndex = existingFiles.length + i;
      try {
        setFieldValue(`files[${newFileIndex}].isLoading`, true);
        setFieldValue(`files[${newFileIndex}].isError`, false);
        setFieldValue(`files[${newFileIndex}].percent`, 0);

        const onUpdatePercent = (progressEvent: ProgressEvent) => {
          const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setFieldValue(`files[${newFileIndex}].percent`, percent);
        };

        const storageFileName = await uploadStoredFile({ urlPrefix, file, onUpdatePercent });

        setFieldValue(`files[${newFileIndex}].storage_file_name`, storageFileName);

        const { data } = await axios.post(`${urlPrefix}/upload_stored_file_finished_callback`, {
          storage_filename: storageFileName,
          file_type: file.type,
          file_name: file.name,
        });

        setFieldValue(`files[${newFileIndex}].stored_file_id`, data);
      } catch (error) {
        const axiosError = error as AxiosError;
        const errorMessage = axiosError?.response?.data?.message?.replace('Notify User:', '');

        setFieldValue(`files[${newFileIndex}].isError`, true);
        setFieldValue(`files[${newFileIndex}].errorMessage`, errorMessage);
        reportGenericException(error);
      } finally {
        setFieldValue(`files[${newFileIndex}].isLoading`, false);
      }
    });

    // Wait for all uploads to complete
    await Promise.all(uploadPromises);
  };

  const onDelete = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFieldValue('files', newFiles);
  };

  return (
    <CardDialog
      isDialog
      title="Upload Documents"
      fullWidth
      onClose={onCancel}
      preventClose={isSubmitting}
      trackAlt={trackAlt}
      footerActions={
        <DialogFooterActions
          disabled={isSubmitting || isUploading}
          onClickPrimary={handleSubmit}
          onClickSecondary={onCancel}
          primaryLabel="UPLOAD"
          showSecondary={!isSubmitting}
        />
      }
    >
      {displayAIBanner && files?.length === 1 && (
        <AssistanceAiBanner
          title="AI Assistant"
          subTitle="is working on filling up these forms for you."
          iconSize={32}
          size={AssistanceAiBanner.SIZE_VARIANTS.LG}
          weight={AssistanceAiBanner.WEIGHTS.REGULAR}
          hasBackground
        />
      )}
      <>
        <Dropzone multiple onDrop={onFilesSelect} ref={dropzoneRef}>
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <ChooseFilesSection
                getInputProps={getInputProps}
                getRootProps={getRootProps}
                dropzoneRef={dropzoneRef}
                isDragActive={isDragActive}
                disabled={false}
              />
            );
          }}
        </Dropzone>
        {!isEmpty(files) && (
          <>
            <Text variant={Text.VARIANTS.SM} className="py-12">
              Files Chosen
            </Text>
            <SelectedFilesList onDelete={onDelete} filesData={files} />
          </>
        )}
        <FormHelperText error>{errors?.files}</FormHelperText>

        {!showFileBoxOnly && (
          <>
            <Text variant={Text.VARIANTS.SM} className="pt-12">
              Files Information
            </Text>
            <DocumentFieldsSection
              claim={claim}
              hideExposuresLabels={uploadOutsideOfClaim}
              allowedDocumentTypes={allowedDocumentTypes}
              showOnly={false}
              fieldsTitle=""
              disabled={false}
              nameFieldAdditionalText={
                values['document_name'] ? newFileNameAdditionalText : 'Enter name to change files original file names'
              }
            />
          </>
        )}
      </>
    </CardDialog>
  );
};

export default UploadDocumentsInner;
