import React from 'react';

import type { CollapsibleCardProps } from '~/components/core/Molecules/Collapsible/types';

import { CardBase } from '../CardBase';
import type { CardBaseExternalProps, CardType } from '../types';
import { CardTypeToStyleAttributesMap } from '../types';

export interface MainCardProps extends CardBaseExternalProps, CollapsibleCardProps {
  type: CardType;
  titleAction?: React.ReactNode;
  className?: string;
}

export const MainCard: React.FC<MainCardProps> = ({
  type,
  title,
  titleAction,
  collapsible,
  isOpen,
  action,
  openByDefault,
  onCollapseClick,
  children,
  direction,
  className,
}) => (
  <CardBase
    className={className}
    paddingX={24}
    paddingY={20}
    contentGap={20}
    {...CardTypeToStyleAttributesMap[type]}
    titleProps={{
      variant: 'LG',
      weight: 'SEMI_BOLD',
      colorVariant: 'PRIMARY',
    }}
    direction={direction}
    title={title}
    collapsible={collapsible}
    isOpen={isOpen}
    action={action}
    openByDefault={openByDefault}
    onCollapseClick={onCollapseClick}
    titleAction={titleAction}
  >
    {children}
  </CardBase>
);
