import React from 'react';

import CardDialog from '~/components/CardDialog';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import { PreDefinedField } from '~/components/IncidentConfiguration/ConfiguredFields';

export const FnolTriage: React.FC = () => {
  const { incidentConfiguration } = useIncidentConfiguration();

  return (
    <CardDialog title="Triage">
      <Grid container spacing={2}>
        <PreDefinedField
          id="triage.complexity"
          fields={incidentConfiguration}
          inline
          value={undefined}
          onUpdate={undefined}
        />
      </Grid>
    </CardDialog>
  );
};
