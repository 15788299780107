import React from 'react';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import Button from '~/components/core/Atomic/Buttons/Button';
import { trackAction } from '~/Utils';

export const LoadingButton: React.FC<{
  text: string;
  onClick: () => Promise<void> | void;
  variant?: 'text' | 'outlined' | 'contained';
  icon?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  trackingInfo?: string;
}> = ({ text, onClick, variant, icon, className, disabled, trackingInfo }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();

  const onClickHandler = async () => {
    setIsLoading(true);
    if (trackingInfo) {
      trackAction(location, trackingInfo);
    }

    await onClick();
    setIsLoading(false);
  };

  return (
    <Button
      onClick={onClickHandler}
      variant={variant}
      color="primary"
      disabled={disabled || isLoading}
      className={className}
      classes={{ label: 'leading-none' }}
      startIcon={isLoading ? <CircularProgress classes={{ colorPrimary: 'text-slate-700' }} size={14} /> : icon}
    >
      {text}
    </Button>
  );
};
