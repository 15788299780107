import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useFormikContext } from 'formik';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import useFnolStoredFiles, { StoredFileDictTypes } from '~/components/hooks/useFnolStoredFiles';

import CardDialog from '../../../CardDialog';
import { UploadMultipleMedia } from '../../../Documents/DocumentCard';
import { useCms } from '../../../hooks/useCms';
import { useIncidentConfiguration } from '../../../hooks/useIncidentConfiguration';
import { useLob } from '../../../hooks/useLob';

import { useStyles } from '../../../../assets/styles';

const getFileName = (file) => file.filename || file.stored_file_extra?.document_name || file.original_filename;

const UploadContainer = ({ label, mediaType, storedFiles = [] }) => {
  const { setFieldValue, values } = useFormikContext();
  const classes = useStyles();
  const { lob } = useLob();
  const { userOrganization } = useCms();
  const [showUploadFiles, setShowUploadFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  useEffect(() => {
    if (uploadedFiles.length === 0 && storedFiles.length > 0) {
      setUploadedFiles([...storedFiles]);
    }
  }, [storedFiles, uploadedFiles]);
  return (
    <>
      <Grid item xs={4}>
        <Button color="primary" onClick={() => setShowUploadFiles(true)}>
          <CloudUploadIcon className={classes.leftButtonIcon} />
          {label}
        </Button>
        {uploadedFiles.length > 0 && (
          <List dense>
            {uploadedFiles.map((file) => (
              <ListItem key={file.id}>
                <ListItemText primary={getFileName(file)} />
              </ListItem>
            ))}
          </List>
        )}
      </Grid>
      {showUploadFiles && (
        <UploadMultipleMedia
          onClose={() => setShowUploadFiles(false)}
          alternativeUploadUrl={`/api/v1/organizations/${userOrganization.id}/stored_files`}
          mediaType={mediaType}
          onSubmit={(files) => {
            setUploadedFiles([...uploadedFiles, ...files]);
            setFieldValue('stored_files_ids', [...values['stored_files_ids'], ...files.map((file) => file.id)]);
            setShowUploadFiles(false);
          }}
          lob={lob}
          uploadOutsideOfClaim
        />
      )}
    </>
  );
};

UploadContainer.propTypes = {
  label: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  storedFiles: PropTypes.array,
};

const FnolFiles = () => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const isDocumentUploadActive = incidentConfiguration?.documents?.active;
  const { values } = useFormikContext();
  const { storedFilesDict } = useFnolStoredFiles(values.stored_files_ids);

  if (!isDocumentUploadActive) {
    return <></>;
  }
  return (
    <>
      <CardDialog title="Files" containerStyle={{ borderRadius: '6px' }}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Typography display="block" variant="body1" color="textSecondary">
              Add supporting documents, photos or videos
            </Typography>
          </Grid>
          <Grid container spacing={4}>
            <UploadContainer
              label="UPLOAD DOCUMENTS"
              mediaType="other"
              storedFiles={storedFilesDict[StoredFileDictTypes.DOC] || []}
            />
            <UploadContainer
              label="UPLOAD PHOTOS"
              mediaType="damage_photo"
              storedFiles={storedFilesDict[StoredFileDictTypes.IMAGE] || []}
            />
            <UploadContainer
              label="UPLOAD VIDEOS"
              mediaType="video"
              storedFiles={storedFilesDict[StoredFileDictTypes.VIDEO] || []}
            />
          </Grid>
          <Grid item xs={12} />
          <Grid />
        </Grid>
      </CardDialog>
    </>
  );
};

export default FnolFiles;
