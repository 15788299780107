import React from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';

import MenuItem from '~/components/core/Atomic/MenuItem';

import { useClaim } from '../ClaimContainer';
import { Text } from '../core';
import { getPayeeContacts } from '../exposures/PaymentRequestContainer/paymentContainerUtils';
import TextFieldFormik from '../TextFieldFormik';

import { MAX_PAYEES_PER_CLAIMSPAY_TYPE, ONE_INC_CLAIMSPAY_METHODS, ONE_INC_CLAIMSPAY_TYPES } from './consts';

import { useStyles } from '../../assets/styles';

export const ClaimsPayMethodTextFieldFormik = ({ paymentRequestStore, showOnly = false }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { claim } = useClaim();
  const [isCheckForced, setIsCheckForced] = React.useState(false);
  const [doNotShowCheckEnforcedNotice, setDoNotShowCheckEnforcedNotice] = React.useState(false);

  const CLAIMSPAY_METHOD_FIELD_KEY = 'method_specific.one_inc_claimspay_method';

  const claimsPayType = getIn(values, 'method_specific.one_inc_claimspay_type');

  const payees = paymentRequestStore?.data ? getPayeeContacts(values, paymentRequestStore.data, claim) : 0;
  const numberOfPayees = payees.length;

  React.useEffect(() => {
    const maxPayeesPerCurrentClaimsPayType = MAX_PAYEES_PER_CLAIMSPAY_TYPE[claimsPayType];

    if (claimsPayType !== ONE_INC_CLAIMSPAY_TYPES.CONTACTS_AND_VENDOR) {
      if (!isCheckForced && numberOfPayees > maxPayeesPerCurrentClaimsPayType) {
        setFieldValue(CLAIMSPAY_METHOD_FIELD_KEY, ONE_INC_CLAIMSPAY_METHODS.CHECK);
        setIsCheckForced(true);
        setDoNotShowCheckEnforcedNotice(false);
      }

      if (isCheckForced && numberOfPayees <= maxPayeesPerCurrentClaimsPayType) {
        setIsCheckForced(false);
        setDoNotShowCheckEnforcedNotice(false);
      }
    } else {
      setFieldValue(CLAIMSPAY_METHOD_FIELD_KEY, ONE_INC_CLAIMSPAY_METHODS.CHECK);
      setIsCheckForced(true);
      setDoNotShowCheckEnforcedNotice(true);
    }
  }, [numberOfPayees, setFieldValue, isCheckForced, claimsPayType]);

  return (
    <>
      <TextFieldFormik
        id={CLAIMSPAY_METHOD_FIELD_KEY}
        label="ClaimsPay Method"
        className={classes.textField}
        fullWidth
        select
        disabled={isCheckForced}
        showOnly={showOnly}
      >
        {Object.values(ONE_INC_CLAIMSPAY_METHODS).map((method) => (
          <MenuItem key={method} value={method}>
            {method}
          </MenuItem>
        ))}
      </TextFieldFormik>
      {isCheckForced && !showOnly && !doNotShowCheckEnforcedNotice && (
        <Text variant={Text.VARIANTS.XS} colorVariant={Text.COLOR_VARIANTS.NOTICE} weight={Text.WEIGHTS.MEDIUM}>
          Check enforced due to the number of payees
        </Text>
      )}
    </>
  );
};

ClaimsPayMethodTextFieldFormik.propTypes = {
  paymentRequestStore: PropTypes.object,
  showOnly: PropTypes.bool,
};
