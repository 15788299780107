import React, { useEffect, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Snackbar } from '@material-ui/core';
import axios from 'axios';
import { isEmpty } from 'lodash';

import { useStyles } from '~/assets/styles';
import MenuItem from '~/components/core/Atomic/MenuItem';
import SingleSelectField from '~/components/core/Molecules/Fields/SingleSelectField';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import { COMMUNICATION_CHANNEL_DICT, CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isUserImpersonated } from '~/UserUtils';
import { isFeatureEnabled, isProductionEnv, reportErrorInProductionOrThrow } from '~/Utils';

import CardDialog from '../CardDialog';
import { useCms } from '../hooks/useCms';
import useDataFetcher from '../useDataFetcher';

import CommunicationsTable from './CommunicationsTable';
import ViewCommunicationCardContainer from './ViewCommunicationCardContainer';

export default function AdjusterCommunications() {
  const classes = useStyles();
  const { user, setPageTitle, userOrganization } = useCms();
  const [selectedCommunicationId, setSelectedCommunicationId] = useState();
  const [communicationChannelFilter, setCommunicationChannelFilter] = useState('any');
  const [page, setPage] = useState(0);

  const isNotification2Enabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2);

  const COMMS_PER_PAGE = 20;

  useEffect(() => setPageTitle('Communications', 'Adjuster Communications - Five Sigma CMS'), [setPageTitle]);
  const {
    isLoading,
    isError,
    data: communicationResult = {},
    reloadData,
  } = useDataFetcher(`/api/v1/organizations/${user.organization_id}/users/${user.id}/communications`, {
    params: {
      page: page + 1, // page in the flask-SQLAlchemy is 1-based,
      rows_per_page: COMMS_PER_PAGE,
      channel_type: communicationChannelFilter !== 'any' ? communicationChannelFilter : undefined,
      with_read_status: isNotification2Enabled ? true : undefined,
    },
  });

  const displayLoading = isLoading && isEmpty(communicationResult);

  const {
    communications = [],
    count = 0,
    available_channels: availableChannels = [],
    communications_read_status: communicationsReadStatusDict = {},
  } = communicationResult;

  const unreadRowsIds = isNotification2Enabled
    ? communications?.filter((communication) => !communicationsReadStatusDict?.[communication.id]).map((n) => n.id) ||
      []
    : [];

  const handleSelectCommunication = async (communicationId) => {
    setSelectedCommunicationId(communicationId);

    try {
      if (isProductionEnv() && isUserImpersonated(user)) return;

      if (communicationsReadStatusDict?.[communicationId]) return;

      await axios.post(`/api/v1/communications/${communicationId}/mark_as_read`);
      await reloadData();
    } catch (error) {
      reportErrorInProductionOrThrow(error);
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <div className="p-8">
          <SingleSelectField
            id="channel"
            label="Filter Communication Type"
            className={classes.textFieldRow}
            value={communicationChannelFilter}
            onChange={(value) => {
              setPage(0);
              setCommunicationChannelFilter(value || '');
            }}
          >
            <MenuItem key="none" value="any">
              Any Channel
            </MenuItem>
            {availableChannels.map((channel) => (
              <MenuItem key={channel} value={channel}>
                {COMMUNICATION_CHANNEL_DICT[channel]}
              </MenuItem>
            ))}
          </SingleSelectField>
        </div>
      </div>
      <PanelGroup direction="horizontal" id="group-adjuster-communications" autoSaveId style={{ height: 'initial' }}>
        <Panel id="table-display" defaultSize={selectedCommunicationId ? 60 : 100} style={{ overflow: 'auto' }}>
          {displayLoading && (
            <SkeletonTable rowsCount={COMMS_PER_PAGE} columnsCount={5} maxHeight="45vh" isError={isError} />
          )}
          {!displayLoading && (
            <CommunicationsTable
              communications={communications}
              selectedCommunicationId={selectedCommunicationId}
              onSelectCommunication={handleSelectCommunication}
              paginationProps={{
                page,
                rowsPerPage: COMMS_PER_PAGE,
                onChangePage: (_, newPage) => setPage(newPage),
                count: count || 0,
                rowsPerPageOptions: [COMMS_PER_PAGE],
              }}
              removeExposuresColumn
              removeAdjusterColumn
              addClaimColumn
              disableSortByUser
              unreadRowsIds={unreadRowsIds}
            />
          )}
        </Panel>
        {selectedCommunicationId && (
          <>
            <PanelResizeHandle id="resize-handle" className="mx-12 w-2 rounded-lg bg-slate-500" />

            <Panel id="comm-display" className="h-full">
              <CardDialog
                headerStyle={{ padding: '0' }}
                contentStyle={{ padding: '0', height: '100%' }}
                containerStyle={{ height: '100%' }}
                noCardTitle
              >
                <ViewCommunicationCardContainer
                  key={selectedCommunicationId}
                  communicationId={selectedCommunicationId}
                  onUpdate={reloadData}
                  displayAttachClaim={false}
                />
              </CardDialog>
            </Panel>
          </>
        )}
      </PanelGroup>
      {isLoading && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open message="Loading..." />}
    </div>
  );
}
