import React from 'react';

import SvgIcon from '~/components/core/SvgIcon';
import type { IconProps } from '~/components/icons/types';

const PlusCircleIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" disableStrokeColor iconColor={iconColor} {...props}>
    <path
      d="M13.5 0.5C10.9288 0.5 8.41543 1.26244 6.27759 2.6909C4.13975 4.11935 2.47351 6.14968 1.48957 8.52512C0.505633 10.9006 0.248189 13.5144 0.749797 16.0362C1.25141 18.5579 2.48953 20.8743 4.30762 22.6924C6.1257 24.5105 8.44208 25.7486 10.9638 26.2502C13.4856 26.7518 16.0995 26.4944 18.4749 25.5104C20.8503 24.5265 22.8807 22.8603 24.3091 20.7224C25.7376 18.5846 26.5 16.0712 26.5 13.5C26.4964 10.0533 25.1256 6.74882 22.6884 4.31163C20.2512 1.87445 16.9467 0.50364 13.5 0.5ZM13.5 24.5C11.3244 24.5 9.19767 23.8549 7.38873 22.6462C5.57979 21.4375 4.16989 19.7195 3.33733 17.7095C2.50477 15.6995 2.28693 13.4878 2.71137 11.354C3.13581 9.22022 4.18345 7.2602 5.72183 5.72183C7.26021 4.18345 9.22022 3.1358 11.354 2.71136C13.4878 2.28692 15.6995 2.50476 17.7095 3.33733C19.7195 4.16989 21.4375 5.57979 22.6462 7.38873C23.8549 9.19767 24.5 11.3244 24.5 13.5C24.4967 16.4164 23.3367 19.2123 21.2745 21.2745C19.2123 23.3367 16.4164 24.4967 13.5 24.5ZM19.5 13.5C19.5 13.7652 19.3946 14.0196 19.2071 14.2071C19.0196 14.3946 18.7652 14.5 18.5 14.5H14.5V18.5C14.5 18.7652 14.3946 19.0196 14.2071 19.2071C14.0196 19.3946 13.7652 19.5 13.5 19.5C13.2348 19.5 12.9804 19.3946 12.7929 19.2071C12.6054 19.0196 12.5 18.7652 12.5 18.5V14.5H8.50001C8.23479 14.5 7.98044 14.3946 7.7929 14.2071C7.60536 14.0196 7.50001 13.7652 7.50001 13.5C7.50001 13.2348 7.60536 12.9804 7.7929 12.7929C7.98044 12.6054 8.23479 12.5 8.50001 12.5H12.5V8.5C12.5 8.23478 12.6054 7.98043 12.7929 7.79289C12.9804 7.60536 13.2348 7.5 13.5 7.5C13.7652 7.5 14.0196 7.60536 14.2071 7.79289C14.3946 7.98043 14.5 8.23478 14.5 8.5V12.5H18.5C18.7652 12.5 19.0196 12.6054 19.2071 12.7929C19.3946 12.9804 19.5 13.2348 19.5 13.5Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default PlusCircleIcon;
