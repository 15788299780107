import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from '~/assets/styles';
import { useClaim } from '~/components/ClaimContainer';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isBoostOrganization, isFeatureEnabled } from '~/Utils';
import { getFirstPartyFromClaim, getThirdPartyVehiclesFromClaim, isClaimWriteDisabled } from '~/Utils/ClaimUtils';

import AutoInvolvedCard from './Auto/AutoInvolved/AutoInvolved';
import ExposuresCard from './exposures/ExposuresCard';
import ReinsuranceTable from './exposures/ReinsuranceTable/ReinsuranceTable';
import { NewUIWitnessesSummaryContainer } from './Fnol/NewFnolUI/FnolWitnesses/WitnessSummary';
import NewFnolAutoInvolvedCard from './Fnol/NewFnolUI/InvolvedParties/AutoParties/AutoInvolved';
import NewFnolGeneralInvolvedCard from './Fnol/NewFnolUI/InvolvedParties/GeneralClaimParties/Generallnvolved';
import GLInvolvedCard from './GL/GLInvolvedCard';
import HomeInvolvedCard from './Home/HomeInvolvedCard';
import { useCms } from './hooks/useCms';
import { useIncidentConfiguration } from './hooks/useIncidentConfiguration';
import { PetInvolvedCard } from './Pet/PetInvolvedCard';
import { PetAccumulatedFinancialContainer } from './Pet/PetManagementFinancial';
import { EmployeeInvolvedCard } from './WC/EmployeeInvolved';
import { LightDutyTrackCard } from './WC/LightDutyTrack';
import { WitnessesSummaryContainer } from './Witness';

const ClaimManagementTab = ({ isTabActive = true }) => {
  const { claim, onClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();
  const classes = useStyles();
  const claimType = claim.type;
  const isReinsuranceClaim =
    claim.lob === 'reinsurance_claim' &&
    isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.REINSURANCE_MANAGEMENT_TAB);
  const { incidentConfiguration } = useIncidentConfiguration();
  const isNewFnolUIEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.CONFIGURABLE_FNOL);
  const isNewWitnessCardEnabled = isNewFnolUIEnabled && incidentConfiguration?.witnesses?.active;
  const isLightDutyTrackEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.ENABLE_LIGHT_DUTY_TRACK
  );

  // GCDEBT - leave only isNewFnolUIEnabled - auto + GC

  return isTabActive ? (
    <>
      <div className={classes.cardDivRow}>{isReinsuranceClaim ? <ReinsuranceTable /> : <ExposuresCard />}</div>
      <Grid container>
        <Grid item xs={12}>
          {claimType === 'auto_claim' && isNewFnolUIEnabled && (
            <div className={classes.cardDivRow}>
              <NewFnolAutoInvolvedCard onUpdate={onClaimUpdate} claim={claim} />
            </div>
          )}
          {claimType === 'auto_claim' && !isNewFnolUIEnabled && (
            <div className={classes.cardDivRow}>
              <AutoInvolvedCard
                classes={classes}
                onUpdate={onClaimUpdate}
                claim={claim}
                policy={claim.policy}
                firstParty={getFirstPartyFromClaim(claim)}
                thirdPartyVehicles={getThirdPartyVehiclesFromClaim(claim)}
                thirdPartyNonMotorists={claim.incident.non_motorist_parties.filter((party) => !party.is_first_party)}
                thirdPartyOtherProperties={claim.incident.other_properties_parties}
              />
            </div>
          )}
          {claimType === 'general_claim' && isNewFnolUIEnabled && (
            <div className={classes.cardDivRow}>
              <NewFnolGeneralInvolvedCard />
            </div>
          )}
          {claimType === 'home_claim' && !isNewFnolUIEnabled && (
            <div className={classes.cardDivRow}>
              <HomeInvolvedCard />
            </div>
          )}

          {claimType === 'wc_claim' && (
            <div className={classes.cardDivRow}>
              <EmployeeInvolvedCard />
            </div>
          )}

          {claimType === 'wc_claim' && isLightDutyTrackEnabled && (
            <div className={classes.cardDivRow}>
              <LightDutyTrackCard />
            </div>
          )}

          {claimType === 'gl_claim' && (
            <div className={classes.cardDivRow}>
              <GLInvolvedCard />
            </div>
          )}
        </Grid>

        {claimType === 'pet_claim' && (
          <>
            <Grid item xs={6}>
              <div className={classes.cardDivRow}>
                <PetInvolvedCard />
              </div>
            </Grid>
            {isBoostOrganization(userOrganization) &&
              !isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.PET_DEDUCTIBLE_UI) && (
                <Grid item xs={6}>
                  <div className={classes.cardDivRow}>
                    <PetAccumulatedFinancialContainer />
                  </div>
                </Grid>
              )}
          </>
        )}

        {isNewWitnessCardEnabled && (
          <Grid item xs={12}>
            <div className={classes.cardDivRow}>
              <NewUIWitnessesSummaryContainer
                classes={classes}
                witnesses={claim.incident.witnesses}
                onUpdateWitnesses={onClaimUpdate}
                disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
              />
            </div>
          </Grid>
        )}
        {!isNewFnolUIEnabled && ['auto_claim', 'gl_claim', 'wc_claim'].includes(claimType) && (
          <Grid item xs={12}>
            <div className={classes.cardDivRow}>
              <WitnessesSummaryContainer
                classes={classes}
                witnesses={claim.incident.witnesses}
                onUpdateWitnesses={onClaimUpdate}
                disabled={isClaimWriteDisabled(claim, user, { allowOnClosedClaim: true })}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </>
  ) : null;
};

ClaimManagementTab.propTypes = {
  isTabActive: PropTypes.bool,
};

export default ClaimManagementTab;
