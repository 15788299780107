import React from 'react';

import type { COLORS } from '~/components/core/StatusChipCore';
import StatusChipCore from '~/components/core/StatusChipCore';
import type { FNOL_INBOX_STATUS } from '~/components/Fnol/FnolAiInbox/types';
import CheckIcon from '~/components/icons/CheckIcon';
import ClockIcon from '~/components/icons/ClockIcon';
import CloseIcon_Deprecated from '~/components/icons/CloseIcon_Deprecated';
import FilesIcon from '~/components/icons/FilesIcon';
import InProgressIcon from '~/components/icons/InProgressIcon';
import PencilIcon from '~/components/icons/PencilIcon';
import type { IconProps } from '~/components/icons/types';

export interface FnolAiInboxStatusChipProps {
  status: FNOL_INBOX_STATUS;
}

type StatusColorsAndIcons = {
  [key in FNOL_INBOX_STATUS]: {
    color?: COLORS;
    icon?: React.FC<IconProps>;
    name: string;
  };
};

const STATUS_COLORS_AND_ICONS: StatusColorsAndIcons = {
  processing: { color: 'orange', icon: InProgressIcon, name: 'processing' },
  new: { color: 'blue', icon: ClockIcon, name: 'new' },
  draft_created: { color: 'blue', icon: PencilIcon, name: 'draft created' },
  created: { color: 'green', icon: CheckIcon, name: 'created' },
  duplicate: { color: 'yellow', icon: FilesIcon, name: 'duplicate' },
  dismissed: { color: 'grey', icon: CloseIcon_Deprecated, name: 'dismissed' },
  // This is intentional: items with status `processing_failed` should be displayed as `new`
  processing_failed: { color: 'blue', icon: ClockIcon, name: 'new' },
};

export const FnolAiInboxStatusChip: React.FC<FnolAiInboxStatusChipProps> = ({ status }) => {
  const statusInfo = STATUS_COLORS_AND_ICONS[status];
  if (!statusInfo) throw Error('FnolAiInboxStatusChip: status is missing or invalid');

  const { name, color, icon } = statusInfo;
  return <StatusChipCore status={name} color={color} Icon={icon} />;
};
