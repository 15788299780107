import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { useCms } from '~/components/hooks/useCms';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

type ValueOf<T> = T[keyof T];

interface ActionsFeatureFlags {
  isActionsTabEnabled: boolean;
  isActionsDemoEnabled: boolean;
  actionFlagEnabledMapping: Record<ValueOf<typeof AI_ACTION_TYPES>, boolean>;
}

export const useActionsFeatureFlags = (): ActionsFeatureFlags => {
  const { userOrganization } = useCms();

  const isPaymentEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_ACTIONS_PAYMENT_DISPLAY
  );
  const isReserveEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_ACTIONS_RESERVE_DISPLAY
  );
  const isActionsDemoEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_ACTIONS_DEMO_DISPLAY
  );
  const isCoverageDecisionEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_ACTIONS_COVERAGE_DECISION_DISPLAY
  );
  const isInvoiceAnalysisEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_ACTIONS_INVOICE_ANALYSIS_DISPLAY
  );
  const isActionsTabEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.AI_SERVICE_UI_ACTIONS_TAB_DISPLAY
  );

  const isReinsuranceManagementTabEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.REINSURANCE_MANAGEMENT_TAB
  );

  return {
    isActionsTabEnabled,
    isActionsDemoEnabled,
    actionFlagEnabledMapping: {
      [AI_ACTION_TYPES.COVERAGE_DECISION]: isCoverageDecisionEnabled,
      [AI_ACTION_TYPES.PAYMENT]: isPaymentEnabled,
      [AI_ACTION_TYPES.INVOICE_ANALYSIS_RECOMMENDATION]: isInvoiceAnalysisEnabled,
      // todo: remove "isReserveEnabled" once the demo env updated with the new AI_SERVICE_ACTIONS_DEMO_DISPLAY FF
      //  (https://fivesigma.atlassian.net/browse/NGTPA-18017)
      [AI_ACTION_TYPES.UPDATE_RESERVE]: isActionsDemoEnabled || isReserveEnabled,
      [AI_ACTION_TYPES.COMMUNICATE]: isActionsDemoEnabled,
      [AI_ACTION_TYPES.ADJUSTER_ASSIGNMENT]: isActionsDemoEnabled,
      [AI_ACTION_TYPES.OPEN_EXPOSURE]: isActionsDemoEnabled,
      [AI_ACTION_TYPES.SET_RESERVE]: isActionsDemoEnabled,
      [AI_ACTION_TYPES.LAYER_ADVICE]: isReinsuranceManagementTabEnabled,
    },
  };
};
