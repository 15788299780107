import React from 'react';

import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import Text from '~/components/core/TextComponents/Text';
import type { ExposureModel } from '~/components/types/exposure-types';

import NotificationKanbanCard from '../NotificationKanbanCard/NotificationKanbanCard';
import type { CombinedKanbanCategoryModel } from '../types';

// Sort by due_date in ascending order (earlier dates first)
const sortByDueDate = (a: ClaimNotificationModel, b: ClaimNotificationModel) => {
  if (a.due_date && b.due_date) {
    const dateComparison = new Date(a.due_date).getTime() - new Date(b.due_date).getTime();
    if (dateComparison !== 0) return dateComparison;
  } else if (a.due_date) {
    return -1; // a has due_date, b doesn't, so a comes first
  } else if (b.due_date) {
    return 1; // b has due_date, a doesn't, so b comes first
  }
  return 0; // No due dates to compare
};

// Sort by priority (high -> medium -> low)
const sortByPriority = (a: ClaimNotificationModel, b: ClaimNotificationModel) => {
  const priorityOrder = { high: 0, medium: 1, low: 2 };
  const aPriority = a.priority || 'low';
  const bPriority = b.priority || 'low';

  return priorityOrder[aPriority] - priorityOrder[bPriority];
};

// Combined sort function that can prioritize either due_date or priority
const sortNotifications = (
  notifications: ClaimNotificationModel[],
  sortByField: 'priority' | 'due_date'
): ClaimNotificationModel[] => {
  return [...notifications].sort((a, b) => {
    if (sortByField === 'due_date') {
      const dueDateComparison = sortByDueDate(a, b);
      return dueDateComparison !== 0 ? dueDateComparison : sortByPriority(a, b);
    } else {
      const priorityComparison = sortByPriority(a, b);
      return priorityComparison !== 0 ? priorityComparison : sortByDueDate(a, b);
    }
  });
};

interface NotificationKanbanCategoryColumnProps {
  categoryConfig: CombinedKanbanCategoryModel;
  notifications: ClaimNotificationModel[];
  exposuresDict: { [id: number]: ExposureModel };
  onUpdateNotification: () => Promise<void> | undefined;
  sortByField: 'priority' | 'due_date';
  onShowNotification: (notificationId: number) => void;
}

const NotificationKanbanCategoryColumn: React.FC<NotificationKanbanCategoryColumnProps> = ({
  categoryConfig,
  notifications,
  exposuresDict,
  onUpdateNotification,
  sortByField,
  onShowNotification,
}) => {
  const categoryNotifications =
    notifications?.filter((notification) => notification?.categories?.[0]?.key === categoryConfig?.key) || [];

  const sortedCategoryNotifications = sortNotifications(categoryNotifications, sortByField);

  return (
    <div className="flex h-[calc(100vh)] min-w-[350px] flex-col rounded-lg bg-slate-100 p-12">
      <div>
        <Text
          variant={Text.VARIANTS.SM}
          weight={Text.WEIGHTS.SEMI_BOLD}
          colorVariant={Text.COLOR_VARIANTS.SECONDARY}
          className={`flex items-center gap-8 border-0 border-b-2 p-12 border-${categoryConfig?.color} border-solid`}
        >
          {`${categoryConfig.label} (${sortedCategoryNotifications.length})`}
        </Text>
      </div>
      <div className="flex-1 space-y-8 overflow-y-visible p-16">
        {sortedCategoryNotifications.map((notification) => (
          <NotificationKanbanCard
            key={notification.id}
            notification={notification}
            exposuresDict={exposuresDict}
            onUpdateNotification={onUpdateNotification}
            onShowNotification={onShowNotification}
          />
        ))}
      </div>
    </div>
  );
};

export default NotificationKanbanCategoryColumn;
