import React, { useEffect, useState } from 'react';
import axios from 'axios';

import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import HoverChangeField from '~/components/HoverChangeField';
import { isoDateToUs } from '~/DateTimeUtils';
import { reportAxiosError } from '~/Utils';

interface DueDateProps {
  notification: ClaimNotificationModel;
  disabled?: boolean;
  setIsUpdating: (isUpdating: boolean) => void;
  onUpdate: () => void | Promise<void>;
}

const NotificationDueDate: React.FC<DueDateProps> = ({ notification, disabled, setIsUpdating, onUpdate }) => {
  const [dueDateToDisplay, setDueDateToDisplay] = useState<string | null>();

  const notificationDueDate = notification?.due_date;
  useEffect(() => {
    if (notificationDueDate) {
      setDueDateToDisplay(notificationDueDate);
    }
  }, [notificationDueDate]);

  const handleDueDateUpdate = async ({ due_date }: { due_date: string }) => {
    try {
      setIsUpdating(true);
      setDueDateToDisplay(due_date);

      await axios.patch(`/api/v1/notifications/${notification.id}/due_date`, {
        claim_id: notification.claim_id,
        due_date,
      });
      await onUpdate();
      setIsUpdating(false);
    } catch (err) {
      await reportAxiosError(err);
      setDueDateToDisplay(notification.due_date);
    }
  };

  return dueDateToDisplay ? (
    <HoverChangeField
      name="due_date"
      specialFieldType="date"
      specialFieldAdditionalProps={{ disablePast: true }}
      value={dueDateToDisplay}
      onUpdate={handleDueDateUpdate}
      label="Due Date"
      permanent
      disabled={disabled}
    >
      <div>{isoDateToUs(dueDateToDisplay)}</div>
    </HoverChangeField>
  ) : null;
};

export default NotificationDueDate;
