import React from 'react';

import CardDialog from '~/components/CardDialog';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import type { ClivePolicyV2ResponsePayload, Policy, PolicyData } from '~/components/Fnol/FnolAiInbox/types';
import { PolicySearchResults } from '~/components/PolicySearch';
import { PolicySearchResultsV2 } from '~/components/PolicySearchV2';

interface PolicyCardProps {
  policy: Policy;
  onRemove: () => void;
  onChangePolicy: () => void;
  onCancel: () => void;
  viewOnly: boolean;
}

function ClivePolicyV2SearchResults({
  removed,
  policyData,
  onCancel,
  onSelectPolicy,
  disableSelectButton,
}: {
  removed: boolean;
  policyData: unknown;
  onCancel: () => void;
  onSelectPolicy: () => Promise<void>;
  disableSelectButton: boolean;
}) {
  const clivePolicyV2ResponsePayload = policyData as ClivePolicyV2ResponsePayload;
  const lob = (clivePolicyV2ResponsePayload.policy_api_data as PolicyData).lob;

  return (
    <PolicySearchResultsV2
      isDialog={false}
      noCardTitle
      searchPoliciesResults={removed ? {} : { [lob]: clivePolicyV2ResponsePayload.policy_search_response }}
      onCancel={onCancel}
      onSelectPolicy={onSelectPolicy}
      policyTypes={[lob]}
      selectButtonText="REMOVE"
      disableSelectButton={disableSelectButton}
    />
  );
}

export const PolicyCard: React.FC<PolicyCardProps> = ({
  policy,
  onRemove,
  onChangePolicy,
  onCancel,
  viewOnly = false,
}: PolicyCardProps) => {
  const [isRemoved, setIsRemoved] = React.useState(false);
  const resultsDict = {
    [policy.policy_type]: [policy],
  };

  const removePolicyHandler = async () => {
    setIsRemoved(true);
    await Promise.resolve();
  };

  function isPolicyV1() {
    // "effective_time" only exists on PolicyData type and not on ClivePolicyV2ResponsePayload ¯\_(ツ)_/¯

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !!policy.policy_data?.effective_time;
  }

  return (
    <CardDialog isDialog title="Policy Found" onClose={onCancel} maxWidth="lg" fullWidth>
      <div className="flex flex-col gap-12">
        {isRemoved ? (
          <AlertBanner
            alertType={AlertBanner.ALERT_TYPES.WARNING}
            note="Please note that by removing the policy found all relevant information will be removed, also from the FNOL draft created."
            withIcon
          />
        ) : isPolicyV1() ? (
          <PolicySearchResults
            isDialog={false}
            noCardTitle
            classes={{}}
            searchPoliciesResults={isRemoved ? {} : resultsDict}
            onSelectPolicy={removePolicyHandler}
            policyTypes={[policy.policy_type]}
            selectButtonText="REMOVE"
            disableSelectButton={viewOnly}
          />
        ) : (
          <ClivePolicyV2SearchResults
            removed={isRemoved}
            policyData={policy}
            onCancel={onCancel}
            onSelectPolicy={removePolicyHandler}
            disableSelectButton={viewOnly}
          />
        )}

        <div className="mt-12 flex flex-row-reverse items-center gap-12">
          <Button
            onClick={() => onRemove()}
            className=" bg-blue-700 text-white"
            variant="contained"
            disabled={viewOnly || !isRemoved}
          >
            Save
          </Button>
          <Button onClick={onCancel} className="ml-auto " variant="outlined">
            Cancel
          </Button>
          <Button onClick={onChangePolicy} className="mr-auto text-blue-700" variant="text" disabled={viewOnly}>
            Change Policy
          </Button>
        </div>
      </div>
    </CardDialog>
  );
};
