import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import CoveredEntityAccumulatedFinancePerCoverageContainer from '~/components/Policy/GenericPolicy/CoveredEntity/CoveredEntityAccumulatedFinancePerCoverage';
import { getReinsuranceTextIfNeeded, isReinsuranceManagementClaim } from '~/Utils/ClaimUtils';
import { isLocaleRegionIsUK } from '~/Utils/regionUtils';

import { COVERAGE_CONFIGURATION_LIMITS } from '../Types';
import { reportAxiosError, stringCmp } from '../Utils';

import { useCms } from './hooks/useCms';
import CardDialog from './CardDialog';
import CheckboxFormik from './CheckboxFormik';
import { useClaim } from './ClaimContainer';
import { WithConfirmFormik } from './ConfirmModal';
import { FsButton } from './core';
import { useCurrencyFormatter } from './CurrencyFormatterContext';
import { PencilIcon } from './icons';
import InlineIconButton from './InlineIconButton';
import LoadingIndicator from './LoadingIndicator';
import useOrganization from './OrganizationContext';
import PlainTable from './PlainTable';
import {
  MonetaryValueTextFieldFormik,
  NumericTextFieldFormik,
  ShowOnlyTextField,
  TextFieldFormik,
} from './TextFieldFormik';
import useDataFetcher from './useDataFetcher';

import { useStyles } from '../assets/styles';

function CoveredEntityCoveragesTable({
  coveredEntity,
  isLoadingCoveredEntity,
  shouldNotConfirmOnSave,
  disableEditing,
  onCoverageUpdate,
  onSharedCoverageUpdate,
  entityDesc,
}) {
  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const isReinsuranceEnabled = isReinsuranceManagementClaim(claim, userOrganization);
  const { exposures = [] } = claim;

  const coverageConfigsParams = {
    params: {
      sub_organization_ids: claim.policy.sub_organization?.id ? [claim.policy.sub_organization.id] : [],
      lob: claim.lob,
      include_disabled: true,
    },
  };
  const {
    isLoading: isCoverageConfigsLoading,
    isError: isCoverageConfigsError,
    data: coverageConfigs = [],
  } = useDataFetcher(`/api/v1/organizations/${claim.organization_id}/coverage_configs`, coverageConfigsParams);
  const {
    isLoading: isSharedCoveragesLoading,
    isError: isSharedCoveragesError,
    data: sharedCoverageConfigs = [],
  } = useDataFetcher(`/api/v1/organizations/${claim.organization_id}/shared_coverage_configs`, coverageConfigsParams);

  const isLoading = isLoadingCoveredEntity || isCoverageConfigsLoading || isSharedCoveragesLoading;

  const activeCoverageConfigs = coverageConfigs.filter((coverageConfig) => {
    if (!coverageConfig?.is_disabled) {
      return true;
    }

    return exposures.some(
      (exposure) =>
        exposure.coverage_type === coverageConfig.coverage_key && exposure?.covered_entity_id === coveredEntity?.id
    );
  });

  return (
    <>
      <CollapsibleWrapper
        noBackground
        actionCard
        title={getReinsuranceTextIfNeeded({
          key: 'coverages',
          isReinsuranceEnabled,
          defaultText: 'Coverages',
        })}
      >
        {isLoading ? (
          <LoadingIndicator isError={isCoverageConfigsError || isSharedCoveragesError} />
        ) : (
          <>
            <CoveredEntityCoveragesTableInternal
              coveredEntity={coveredEntity}
              coverageConfigs={activeCoverageConfigs}
              sharedCoverageConfigs={sharedCoverageConfigs}
              shouldNotConfirmOnSave={shouldNotConfirmOnSave}
              disableEditing={disableEditing}
              onCoverageUpdate={onCoverageUpdate}
              onSharedCoverageUpdate={onSharedCoverageUpdate}
              entityDesc={entityDesc}
            />
          </>
        )}
      </CollapsibleWrapper>
      {!isLoading && coveredEntity && coveredEntity.entity_id && (
        <CoveredEntityAccumulatedFinancePerCoverageContainer
          coveredEntity={coveredEntity}
          coverageConfigs={activeCoverageConfigs}
        />
      )}
    </>
  );
}

CoveredEntityCoveragesTable.propTypes = {
  coveredEntity: PropTypes.object.isRequired,
  isLoadingCoveredEntity: PropTypes.bool,
  disableEditing: PropTypes.bool,
  shouldNotConfirmOnSave: PropTypes.bool,
  onCoverageUpdate: PropTypes.func.isRequired,
  onSharedCoverageUpdate: PropTypes.func.isRequired,
  entityDesc: PropTypes.string,
};

function CoveredEntityCoveragesTableInternal({
  coveredEntity,
  coverageConfigs,
  sharedCoverageConfigs,
  shouldNotConfirmOnSave,
  disableEditing,
  onCoverageUpdate,
  onSharedCoverageUpdate,
  entityDesc,
}) {
  const { userOrganization } = useCms();
  const { claim } = useClaim();
  const isReinsuranceEnabled = isReinsuranceManagementClaim(claim, userOrganization);
  const { coverageConfiguration } = useOrganization();
  const classes = useStyles();
  const { currencyFormatter } = useCurrencyFormatter();

  const isCoverageExists = (coverageConfigKey) => coverageConfigKey in coveredEntity.coverages_dict;
  const isSharedCoverageExists = (sharedCoverageKey) => sharedCoverageKey in coveredEntity.shared_coverages_dict;
  const isSharedCoverageNotCovered = (sharedCoverageConfig) =>
    sharedCoverageConfig.coverage_config_keys.every(
      (coverageConfigKey) => !coveredEntity.coverages_dict[coverageConfigKey]?.is_covered
    );

  const deductibleFilter = (column) =>
    column.id !== 'deductible' || coverageConfigs?.some((coverageConfig) => coverageConfig.is_deductible);
  const coInsuranceFilter = (column) =>
    column.id !== 'coinsurance' || coverageConfigs?.some((coverageConfig) => coverageConfig.is_coinsurance);

  const editCoverageFilter = (column) => column.id !== 'edit_coverage' || !disableEditing;

  const coverageCmpFunc = (row1, row2) => {
    if (
      coveredEntity.coverages_dict[row1.coverage_key]?.is_covered !==
      coveredEntity.coverages_dict[row2.coverage_key]?.is_covered
    ) {
      return coveredEntity.coverages_dict[row1.coverage_key]?.is_covered ? -1 : 1;
    }
    return stringCmp(row1.display_name, row2.display_name);
  };

  const sharedCoverageCmpFunc = (row1, row2) => {
    if (isSharedCoverageNotCovered(row1) !== isSharedCoverageNotCovered(row2)) {
      return isSharedCoverageNotCovered(row1) ? 1 : -1;
    }
    return stringCmp(row1.display_name, row2.display_name);
  };

  const coverageConfigsColumns = [
    {
      id: 'coverage',
      label: getReinsuranceTextIfNeeded({
        key: 'coverage',
        isReinsuranceEnabled,
        defaultText: 'Coverage',
      }),
      specialCmpFunc: coverageCmpFunc,
      specialCell: (coverageConfig) => <span>{coverageConfig.display_name}</span>,
    },
    {
      id: 'limit',
      label: 'Limit',
      specialCell: (coverageConfig) => {
        if (!isCoverageExists(coverageConfig.coverage_key)) {
          return '';
        }

        const coverage = coveredEntity.coverages_dict[coverageConfig.coverage_key];
        const coverageInfo = [];

        const isCovered = coverage.is_covered;
        if (isCovered) {
          coverageInfo.push(<div key="covered">Covered</div>);
        } else {
          coverageInfo.push(<div key="not_covered">Not Covered</div>);
        }

        if (coverageConfig.is_limit_per_person) {
          const limitPerPerson = coverage.limit_per_person;
          if (limitPerPerson !== null) {
            coverageInfo.push(
              <div key="limit_per_person">
                {currencyFormatter.format(limitPerPerson)} per {entityDesc || 'person'}
              </div>
            );
          }
        }

        if (coverageConfig.is_limit_per_incident) {
          const limitPerIncident = coverage.limit_per_incident;
          if (limitPerIncident !== null) {
            coverageInfo.push(
              <div key="limit_per_incident">
                {currencyFormatter.format(limitPerIncident)}{' '}
                {getReinsuranceTextIfNeeded({
                  key: 'per_incident',
                  isReinsuranceEnabled,
                  defaultText: 'per incident',
                })}
              </div>
            );
          }
        }

        if (coverageConfig.is_limit_per_policy_per_entity) {
          const limitPerPolicyPerEntity = coverage.limit_per_policy_per_entity;
          if (limitPerPolicyPerEntity !== null) {
            coverageInfo.push(
              <div key="limit_per_policy_per_entity">
                {currencyFormatter.format(limitPerPolicyPerEntity)}{' '}
                {getReinsuranceTextIfNeeded({
                  key: 'per_policy',
                  isReinsuranceEnabled,
                  defaultText: 'per policy per entity',
                })}
              </div>
            );
          }
        }

        return coverageInfo;
      },
    },
    {
      id: 'deductible',
      label: getReinsuranceTextIfNeeded({
        key: 'deductible',
        isReinsuranceEnabled,
        defaultText: isLocaleRegionIsUK() ? 'Excess' : 'Deductible',
      }),
      specialCell: (coverageConfig) => {
        if (!isCoverageExists(coverageConfig.coverage_key) || !coverageConfig.is_deductible) {
          return '';
        } else {
          const deductible = coveredEntity.coverages_dict[coverageConfig.coverage_key].deductible;
          return deductible !== null ? currencyFormatter.format(deductible) : '';
        }
      },
    },
    {
      id: 'coinsurance',
      label: getReinsuranceTextIfNeeded({
        key: 'coinsurance',
        isReinsuranceEnabled,
        defaultText: 'Co‑Insurance', // NOTE: this label has a non-breaking hyphen (‑) instead of a regular hyphen (-)
      }),
      fullWidth: true,
      specialCell: (coverageConfig) => {
        if (!isCoverageExists(coverageConfig.coverage_key) || !coverageConfig.is_coinsurance) {
          return '';
        } else {
          const coinsurance_insurer_percentage =
            coveredEntity.coverages_dict[coverageConfig.coverage_key].coinsurance_insurer_percentage;
          return coinsurance_insurer_percentage !== null ? `${coinsurance_insurer_percentage}%` : '';
        }
      },
    },
    {
      id: 'edit_coverage',
      label: 'Actions',
      numeric: false,
      width: 30,
      specialCell: (coverageConfig) => {
        return (
          <EditConfigurableCoverage
            coverageConfig={coverageConfig}
            coveredEntity={coveredEntity}
            onCoverageUpdate={onCoverageUpdate}
            shouldNotConfirmOnSave={shouldNotConfirmOnSave}
            triggerButton={(onClick) => (
              <InlineIconButton
                icon={PencilIcon}
                className={classes.hoverableNonFilledIcon}
                onClick={onClick}
                tooltipTitle="Edit Coverage"
              />
            )}
          />
        );
      },
    },
  ]
    .filter(deductibleFilter)
    .filter(coInsuranceFilter)
    .filter(editCoverageFilter);

  const sharedCoverageConfigsColumns = [
    {
      id: 'coverage',
      label: 'Coverage',
      specialCmpFunc: sharedCoverageCmpFunc,
      specialCell: (sharedCoverageConfig) => <span>{sharedCoverageConfig.display_name}</span>,
    },
    {
      id: 'limit',
      label: 'Limit',
      specialCell: (sharedCoverageConfig) => {
        if (!isSharedCoverageExists(sharedCoverageConfig.shared_coverage_key)) {
          return '';
        }

        const sharedCoverage = coveredEntity.shared_coverages_dict[sharedCoverageConfig.shared_coverage_key];
        const sharedCoverageInfo = [];

        const isNotCovered = isSharedCoverageNotCovered(sharedCoverageConfig);
        if (isNotCovered) {
          sharedCoverageInfo.push(<div key="not_covered">Not Covered</div>);
        }

        if (sharedCoverageConfig.is_limit_per_incident) {
          const limitPerPerson = sharedCoverage.limit_per_incident;
          if (limitPerPerson !== null) {
            sharedCoverageInfo.push(
              <div key="limit_per_incident">{currencyFormatter.format(limitPerPerson)} per incident</div>
            );
          }
        }

        return sharedCoverageInfo;
      },
    },
    {
      id: 'edit_coverage',
      numeric: false,
      disablePadding: true,
      width: 20,
      specialCell: (sharedCoverageConfig) => {
        return (
          <EditConfigurableSharedCoverage
            sharedCoverageConfig={sharedCoverageConfig}
            coveredEntity={coveredEntity}
            onSharedCoverageUpdate={onSharedCoverageUpdate}
            shouldNotConfirmOnSave={shouldNotConfirmOnSave}
            triggerButton={(onClick) => (
              <InlineIconButton
                icon={PencilIcon}
                className={classes.hoverableNonFilledIcon}
                onClick={onClick}
                tooltipTitle="Edit Limit"
              />
            )}
          />
        );
      },
    },
  ].filter(editCoverageFilter);

  const existingCoverageConfigs = coverageConfigs.filter((config) => isCoverageExists(config.coverage_key));
  const missingCoverageConfigs = coverageConfigs.filter((config) => !isCoverageExists(config.coverage_key));

  const existingSharedCoverages = sharedCoverageConfigs.filter((config) =>
    isSharedCoverageExists(config.shared_coverage_key)
  );
  const missingSharedCoverages = sharedCoverageConfigs.filter(
    (config) => !isSharedCoverageExists(config.shared_coverage_key)
  );

  return (
    <>
      {existingCoverageConfigs.length > 0 && (
        <PlainTable columns={coverageConfigsColumns} rows={existingCoverageConfigs} defaultOrderColumn={0} />
      )}
      {missingCoverageConfigs.length > 0 && (
        <EditConfigurableCoverage
          coveredEntity={coveredEntity}
          onCoverageUpdate={onCoverageUpdate}
          missingCoverageConfigs={missingCoverageConfigs}
          shouldNotConfirmOnSave={shouldNotConfirmOnSave}
          triggerButton={(onClick) => (
            <div className={classes.buttonRightAligned}>
              <FsButton color="primary" onClick={onClick}>
                <AddIcon className={classes.leftButtonIcon} />
                {getReinsuranceTextIfNeeded({
                  key: 'add_missing_coverage',
                  isReinsuranceEnabled,
                  defaultText: 'Add a missing coverage',
                })}
              </FsButton>
            </div>
          )}
        />
      )}
      {sharedCoverageConfigs.length > 0 && (
        <Typography variant="subtitle2" className={classes.sharedLimits}>
          Shared Limits
        </Typography>
      )}
      {existingSharedCoverages.length > 0 && (
        <PlainTable columns={sharedCoverageConfigsColumns} rows={existingSharedCoverages} defaultOrderColumn={0} />
      )}
      {missingSharedCoverages.length > 0 && (
        <EditConfigurableSharedCoverage
          coveredEntity={coveredEntity}
          onSharedCoverageUpdate={onSharedCoverageUpdate}
          missingSharedCoverages={missingSharedCoverages}
          shouldNotConfirmOnSave={shouldNotConfirmOnSave}
          triggerButton={(onClick) => (
            <div className={classes.buttonRightAligned}>
              <FsButton color="primary" onClick={onClick}>
                <AddIcon className={classes.leftButtonIcon} />
                Add a Shared Limit
              </FsButton>
            </div>
          )}
        />
      )}
      {coverageConfiguration.limit_per_policy_per_covered_entity &&
        Number.isFinite(coveredEntity.limit_per_policy_per_covered_entity) && (
          <ShowOnlyTextField
            label={COVERAGE_CONFIGURATION_LIMITS.limit_per_policy_per_covered_entity.desc}
            classes={classes}
            showOnlyValueComponent={currencyFormatter.format(coveredEntity.limit_per_policy_per_covered_entity)}
          />
        )}
      {coverageConfiguration.limit_per_covered_entity && Number.isFinite(coveredEntity.limit_per_covered_entity) && (
        <ShowOnlyTextField
          label={COVERAGE_CONFIGURATION_LIMITS.limit_per_covered_entity.desc}
          classes={classes}
          showOnlyValueComponent={currencyFormatter.format(coveredEntity.limit_per_covered_entity)}
        />
      )}
    </>
  );
}

CoveredEntityCoveragesTableInternal.propTypes = {
  coveredEntity: PropTypes.object.isRequired,
  coverageConfigs: PropTypes.array.isRequired,
  sharedCoverageConfigs: PropTypes.array.isRequired,
  disableEditing: PropTypes.bool,
  shouldNotConfirmOnSave: PropTypes.bool,
  onCoverageUpdate: PropTypes.func.isRequired,
  onSharedCoverageUpdate: PropTypes.func.isRequired,
  entityDesc: PropTypes.string,
};

function EditConfigurableCoverage(props) {
  const { missingCoverageConfigs = [], coveredEntity, onCoverageUpdate, shouldNotConfirmOnSave, triggerButton } = props;
  const { claim, onAsyncClaimUpdate } = useClaim();
  const classes = useStyles();
  const { user, userOrganization } = useCms();
  const isReinsuranceEnabled = isReinsuranceManagementClaim(claim, userOrganization);
  const [showEditCoverage, setShowEditCoverage] = useState(false);
  let { coverageConfig } = props;
  const isAddingMissing = missingCoverageConfigs.length > 0;

  const [coverageConfigKey, setCoverageConfigKey] = useState(coverageConfig?.coverage_key ?? '');
  if (isAddingMissing) {
    coverageConfig = missingCoverageConfigs.find((coverageConfig) => coverageConfig.coverage_key === coverageConfigKey);
  }
  const isCovered = coveredEntity.coverages_dict[coverageConfigKey]?.is_covered;
  const limitPerPerson = coveredEntity.coverages_dict[coverageConfigKey]?.limit_per_person;
  const limitPerIncident = coveredEntity.coverages_dict[coverageConfigKey]?.limit_per_incident;
  const deductible = coveredEntity.coverages_dict[coverageConfigKey]?.deductible;
  const coinsurance_insurer_percentage =
    coveredEntity.coverages_dict[coverageConfigKey]?.coinsurance_insurer_percentage;
  const limitPerPolicyPerEntity = coveredEntity.coverages_dict[coverageConfigKey]?.limit_per_policy_per_entity;

  const yupNumberIfIsCovered = Yup.number()
    .nullable()
    .when('is_covered', (is_covered, schema) => {
      return is_covered ? schema.required('Required') : schema;
    });

  let initialValues = {};
  let yupShape = {};

  initialValues['is_covered'] = isCovered !== undefined ? isCovered : false;
  yupShape['is_covered'] = Yup.boolean();

  if (isAddingMissing) {
    initialValues['coverage_key'] = coverageConfigKey;
    yupShape['coverage_key'] = Yup.string().required('Required');
  }

  if (coverageConfig?.is_limit_per_person) {
    initialValues['limit_per_person'] = limitPerPerson;
    yupShape['limit_per_person'] = yupNumberIfIsCovered;
  }

  if (coverageConfig?.is_limit_per_incident) {
    initialValues['limit_per_incident'] = limitPerIncident;
    yupShape['limit_per_incident'] = yupNumberIfIsCovered;
  }

  if (coverageConfig?.is_deductible) {
    initialValues['deductible'] = deductible;
    yupShape['deductible'] = yupNumberIfIsCovered;
  }

  if (coverageConfig?.is_coinsurance) {
    initialValues['coinsurance_insurer_percentage'] = coinsurance_insurer_percentage;
    yupShape['coinsurance_insurer_percentage'] = yupNumberIfIsCovered
      .min(0, 'Percentage must be at least 0')
      .max(100, 'Percentage must be at most 100');
  }

  if (coverageConfig?.is_limit_per_policy_per_entity) {
    initialValues['limit_per_policy_per_entity'] = limitPerPolicyPerEntity;
    yupShape['limit_per_policy_per_entity'] = yupNumberIfIsCovered;
  }

  return (
    <>
      {!user.role.is_view_only && triggerButton(() => setShowEditCoverage(true))}
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(yupShape)}
        onSubmit={async (values, formikProps) => {
          try {
            if (values.coverage_key) {
              delete values.coverage_key;
            }
            if (!values.is_covered) {
              values = {
                ...values,
                limit_per_person: null,
                limit_per_incident: null,
                deductible: null,
                coinsurance_insurer_percentage: null,
                limit_per_policy_per_entity: null,
              };
            }
            await onCoverageUpdate(values, coverageConfigKey);
            await onAsyncClaimUpdate();
            if (isAddingMissing) {
              setCoverageConfigKey('');
            }
            setShowEditCoverage(false);
            formikProps.setSubmitting(false);
          } catch (error) {
            formikProps.setSubmitting(false);
            reportAxiosError(error);
          }
        }}
        enableReinitialize
      >
        {(formikProps) => {
          const { values, isSubmitting, handleSubmit, resetForm } = formikProps;

          return (
            <CardDialog
              isDialog
              open={showEditCoverage}
              title={
                isAddingMissing
                  ? getReinsuranceTextIfNeeded({
                      key: 'add_missing_coverage',
                      isReinsuranceEnabled,
                      defaultText: 'Add Missing Coverage',
                    })
                  : isReinsuranceEnabled
                  ? `Edit ${coverageConfig.display_name}`
                  : `Edit ${coverageConfig.display_name} coverage`
              }
              maxWidth="xs"
              fullWidth
              onClose={() => {
                setShowEditCoverage(false);
                resetForm();
                if (isAddingMissing) {
                  setCoverageConfigKey('');
                }
              }}
              preventClose={isSubmitting}
            >
              <Grid container spacing={1}>
                {isAddingMissing && (
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="coverage_key"
                      label="Select a Coverage"
                      select
                      fullWidth
                      onChange={(evt) => setCoverageConfigKey(evt.target.value)}
                    >
                      {missingCoverageConfigs
                        .sort((a, b) => a.display_name.localeCompare(b.display_name))
                        .map((missingCoverage, i) => (
                          <MenuItem key={i} value={missingCoverage.coverage_key}>
                            {missingCoverage.display_name}
                          </MenuItem>
                        ))}
                    </TextFieldFormik>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <CheckboxFormik
                    id="is_covered"
                    label="Is Covered"
                    fullWidth
                    disabled={isAddingMissing && !coverageConfigKey}
                  />
                </Grid>
                {coverageConfig?.is_limit_per_person && (
                  <Grid item xs={12}>
                    <MonetaryValueTextFieldFormik
                      id="limit_per_person"
                      label="Limit Per Person"
                      disabled={!values.is_covered}
                      fullWidth
                    />
                  </Grid>
                )}
                {coverageConfig?.is_limit_per_incident && (
                  <Grid item xs={12}>
                    <MonetaryValueTextFieldFormik
                      id="limit_per_incident"
                      label={`Limit Per ${getReinsuranceTextIfNeeded({
                        key: 'incident',
                        isReinsuranceEnabled,
                        defaultText: 'Incident',
                      })}`}
                      disabled={!values.is_covered}
                      fullWidth
                    />
                  </Grid>
                )}
                {coverageConfig?.is_deductible && (
                  <Grid item xs={12}>
                    <MonetaryValueTextFieldFormik
                      id="deductible"
                      label={getReinsuranceTextIfNeeded({
                        key: 'deductible',
                        isReinsuranceEnabled,
                        defaultText: isLocaleRegionIsUK() ? 'Excess' : 'Deductible',
                      })}
                      disabled={!values.is_covered}
                      fullWidth
                    />
                  </Grid>
                )}
                {coverageConfig?.is_coinsurance && (
                  <Grid item xs={12}>
                    <NumericTextFieldFormik
                      id="coinsurance_insurer_percentage"
                      label="Coinsurance Insurer Percentage"
                      disabled={!values.is_covered}
                      fullWidth
                      suffix="%"
                      decimalScale={2}
                      allowNegative={false}
                      isAllowed={({ floatValue }) => floatValue === undefined || (floatValue >= 0 && floatValue <= 100)}
                    />
                  </Grid>
                )}
                {coverageConfig?.is_limit_per_policy_per_entity && (
                  <Grid item xs={12}>
                    <MonetaryValueTextFieldFormik
                      id="limit_per_policy_per_entity"
                      label={getReinsuranceTextIfNeeded({
                        key: 'limit_per_policy_per_entity',
                        isReinsuranceEnabled,
                        defaultText: 'Limit Per Policy Per Entity',
                      })}
                      disabled={!values.is_covered}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
              <div className={classes.buttonsContainer}>
                {shouldNotConfirmOnSave || claim.policy.is_manually_created ? (
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    Save
                  </Button>
                ) : (
                  <WithConfirmFormik
                    title="Update Policy?"
                    contentText="This change will effect the policy and any claim related to it"
                    primaryButtonName="Yes"
                    shouldCloseOnPrimary={false}
                  >
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                      Save
                    </Button>
                  </WithConfirmFormik>
                )}
              </div>
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

EditConfigurableCoverage.propTypes = {
  coveredEntity: PropTypes.object.isRequired,
  coverageConfig: PropTypes.object,
  missingCoverageConfigs: PropTypes.array,
  shouldNotConfirmOnSave: PropTypes.bool,
  onCoverageUpdate: PropTypes.func.isRequired,
  triggerButton: PropTypes.func.isRequired,
};

function EditConfigurableSharedCoverage(props) {
  const {
    missingSharedCoverages = [],
    coveredEntity,
    onSharedCoverageUpdate,
    shouldNotConfirmOnSave,
    triggerButton,
  } = props;
  const { claim, onAsyncClaimUpdate } = useClaim();
  const classes = useStyles();
  const { user } = useCms();
  const [showEditCoverage, setShowEditCoverage] = useState(false);
  let { sharedCoverageConfig } = props;
  const isAddingMissing = missingSharedCoverages.length > 0;

  const [sharedCoverageKey, setSharedCoverageKey] = useState(sharedCoverageConfig?.shared_coverage_key ?? '');
  if (isAddingMissing) {
    sharedCoverageConfig = missingSharedCoverages.find(
      (coverageConfig) => coverageConfig.shared_coverage_key === sharedCoverageKey
    );
  }

  const limitPerIncident = coveredEntity.shared_coverages_dict[sharedCoverageKey]?.limit_per_incident;

  let initialValues = {};
  let yupShape = {};

  if (isAddingMissing) {
    initialValues['shared_coverage_key'] = sharedCoverageKey;
    yupShape['shared_coverage_key'] = Yup.string().required('Required');
  }

  if (sharedCoverageConfig?.is_limit_per_incident) {
    initialValues['limit_per_incident'] = limitPerIncident ?? '';
    yupShape['limit_per_incident'] = Yup.number(); // not required to allow 'empty' (unlimited)
  }

  return (
    <>
      {!user.role.is_view_only && triggerButton(() => setShowEditCoverage(true))}
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape(yupShape)}
        onSubmit={async (values, formikProps) => {
          try {
            const valuesToSend = { ...values };
            if (valuesToSend['limit_per_incident'] === '') {
              valuesToSend['limit_per_incident'] = null;
            }
            if (valuesToSend['shared_coverage_key']) {
              delete valuesToSend['shared_coverage_key'];
            }
            await onSharedCoverageUpdate(valuesToSend, sharedCoverageKey);
            await onAsyncClaimUpdate();
            setShowEditCoverage(false);
            formikProps.setSubmitting(false);
          } catch (error) {
            formikProps.setSubmitting(false);
            reportAxiosError(error);
          }
        }}
        enableReinitialize
      >
        {(formikProps) => {
          const { isSubmitting, handleSubmit, resetForm } = formikProps;

          return (
            <CardDialog
              isDialog
              open={showEditCoverage}
              title={isAddingMissing ? 'Add Shared Limit' : `Edit ${sharedCoverageConfig.display_name} shared coverage`}
              maxWidth="xs"
              fullWidth
              onClose={() => {
                setShowEditCoverage(false);
                resetForm();
                setSharedCoverageKey('');
              }}
              preventClose={isSubmitting}
            >
              <Grid container spacing={1}>
                {isAddingMissing && (
                  <Grid item xs={12}>
                    <TextFieldFormik
                      id="shared_coverage_key"
                      label="Select a Coverage"
                      select
                      fullWidth
                      onChange={(evt) => setSharedCoverageKey(evt.target.value)}
                    >
                      {missingSharedCoverages.map((missingCoverage, i) => (
                        <MenuItem key={i} value={missingCoverage.shared_coverage_key}>
                          {missingCoverage.display_name}
                        </MenuItem>
                      ))}
                    </TextFieldFormik>
                  </Grid>
                )}
                {sharedCoverageConfig?.is_limit_per_incident && (
                  <Grid item xs={12}>
                    <MonetaryValueTextFieldFormik id="limit_per_incident" label="Limit Per Incident" fullWidth />
                  </Grid>
                )}
              </Grid>
              <div className={classes.buttonsContainer}>
                {shouldNotConfirmOnSave || claim.policy.is_manually_created ? (
                  <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                    Save
                  </Button>
                ) : (
                  <WithConfirmFormik
                    title="Update Policy?"
                    contentText="This change will effect the policy and any claim related to it"
                    primaryButtonName="Yes"
                    shouldCloseOnPrimary={false}
                  >
                    <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
                      Save
                    </Button>
                  </WithConfirmFormik>
                )}
              </div>
            </CardDialog>
          );
        }}
      </Formik>
    </>
  );
}

EditConfigurableSharedCoverage.propTypes = {
  coveredEntity: PropTypes.object.isRequired,
  sharedCoverageConfig: PropTypes.object,
  missingSharedCoverages: PropTypes.array,
  shouldNotConfirmOnSave: PropTypes.bool,
  onSharedCoverageUpdate: PropTypes.func.isRequired,
  triggerButton: PropTypes.func.isRequired,
};

export default CoveredEntityCoveragesTable;
