import React, { useMemo } from 'react';

import type { ActionFooterProps } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import { DismissButton } from '~/components/AiChat/SideBarDialog/Actions/Action/DismissButton';
import { ExecuteButton } from '~/components/AiChat/SideBarDialog/Actions/Action/ExecuteButton';
import type { ReserveUpdateTaskData } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import mixpanel from '~/components/CmsMain/mixpanel';
import ReserveChangeRequestContainer from '~/components/exposures/ReserveChangeRequestContainer';
import useFetchExposures from '~/components/exposures/useFetchExposures';
import { CLIVE_MIXPANEL_EVENTS } from '~/pocs/mixpanel';

export const UpdateReserveFooter: React.FC<ActionFooterProps> = ({ action, onExecute, onDismiss, isDone }) => {
  const { claim } = useClaim();
  const [isOpen, setIsOpen] = React.useState(false);
  const { payable_type, related_exposure_id, reserve_amount } = action.task_data as ReserveUpdateTaskData;

  const { exposures } = useFetchExposures();

  const exposure = useMemo(
    () =>
      related_exposure_id
        ? exposures.find(
            (exp: { claim_internal_id: number }) => exp.claim_internal_id === parseInt(related_exposure_id)
          )
        : null,
    [exposures, related_exposure_id]
  );

  const payableWithReserve = useMemo(() => {
    return exposure ? exposure[payable_type] : null;
  }, [exposure, payable_type]);

  return (
    <div className="flex">
      {onDismiss && <DismissButton onDismiss={onDismiss} disabled={isDone} />}

      <ExecuteButton
        onExecute={() => {
          mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_OPEN_CLICKED, {
            type: AI_ACTION_TYPES.UPDATE_RESERVE,
            is_done: isDone,
          });

          setIsOpen(true);
        }}
        isDone={isDone}
      />
      {isOpen && payableWithReserve && (
        <ReserveChangeRequestContainer
          claim={claim}
          onUpdate={() => {
            if (onExecute) {
              onExecute();
            }
          }}
          payableWithReserve={{
            ...payableWithReserve,
            reserve: reserve_amount,
          }}
          exposure={exposure}
          reserveChangeRequest={payableWithReserve?.reserve_change_request_pending}
          cardDialogProps={{
            isDialog: true,
            open: isOpen,
          }}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};
