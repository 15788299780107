import React from 'react';

import CardDialog from '~/components/CardDialog';
import ClaimLink from '~/components/ClaimLink';
import type { Recommendation } from '~/components/communications/CommunicationAiBanner';
import { ActionButton, CommunicationAiBanner } from '~/components/communications/CommunicationAiBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import type { FnolAnalysisExtra } from '~/components/Fnol/FnolAiInbox/types';
import PlainTable from '~/components/PlainTable';

interface RelatedClaim {
  claim_id: number;
  claim_id_display: string;
}

interface FnolAnalysisExtraActions {
  onAttachToClaim?: (claimId: number, extraParams: Record<string, unknown>) => void;
}

interface FnolAnalysisExtraBannerProps {
  communicationType: string;
  fnolAnalysisExtra: FnolAnalysisExtra;
  fnolAnalysisExtraActions: FnolAnalysisExtraActions;
  isFnolProcessingComplete: boolean;
  onNewLoss?: () => void;
}

const retrieveRecommendation = (
  analysis: FnolAnalysisExtra,
  communicationType: string,
  isFnolProcessingComplete: boolean,
  onAttachToClaim?: (claimId: number, extraParams: Record<string, unknown>) => void,
  onViewClaims?: () => void,
  onNewLoss?: () => void
): Recommendation | null => {
  const { related_claims: relatedClaims } = analysis;

  const relatedClaimsCount = relatedClaims?.length || 0;
  const firstClaim = relatedClaims ? relatedClaims[0] : null;
  if (firstClaim && relatedClaimsCount === 1) {
    return {
      title: `This ${communicationType} refers to an existing claim`,
      explanation_component: (
        <>
          <ul>
            <li> {'This email seems to be related to an existing claim '}</li>
          </ul>
          <ClaimLink
            claimId={relatedClaims?.[0].claim_id as number}
            linkText={relatedClaims?.[0].claim_id_display as string}
          />
        </>
      ),
      classification_reasons: [],
      actionComponent: onAttachToClaim && (
        <ActionButton text="Attach to Claim" onClick={() => onAttachToClaim(firstClaim.claim_id, {})} />
      ),
    };
  }

  if (relatedClaimsCount > 1) {
    return {
      title: 'A few matches were found to existing claims',
      explanation_component: (
        <>
          This email seems to be related to an existing claim, a few matches were found. View the claims and attach the
          email to the correct claim.
        </>
      ),
      classification_reasons: [],
      actionComponent: <ActionButton text="View claims" onClick={onViewClaims} />,
    };
  }

  if (isFnolProcessingComplete) {
    return {
      title: `This ${communicationType} is a new claim`,
      explanation_component: 'This email seems to be a new claim',
      classification_reasons: [],
      actionComponent: <ActionButton text="New Loss" onClick={onNewLoss} />,
    };
  }

  return null;
};

export const FnolAnalysisExtraBanner: React.FC<FnolAnalysisExtraBannerProps> = ({
  fnolAnalysisExtra,
  communicationType,
  fnolAnalysisExtraActions,
  isFnolProcessingComplete,
  onNewLoss,
}) => {
  const [relatedClaimsOpen, setRelatedClaimsOpen] = React.useState(false);

  if (!fnolAnalysisExtra) {
    return null;
  }

  const onViewClaims = () => setRelatedClaimsOpen(true);

  const recommendation = retrieveRecommendation(
    fnolAnalysisExtra,
    communicationType,
    isFnolProcessingComplete,
    fnolAnalysisExtraActions?.onAttachToClaim,
    onViewClaims,
    onNewLoss
  );

  return (
    <>
      <CommunicationAiBanner
        communicationType={communicationType}
        recommendation={recommendation}
        summary={fnolAnalysisExtra.summary}
      />
      {relatedClaimsOpen && fnolAnalysisExtra.related_claims && fnolAnalysisExtra.related_claims.length > 0 ? (
        <RelatedClaimsCardDialog
          claims={fnolAnalysisExtra.related_claims}
          onClose={() => setRelatedClaimsOpen(false)}
          onAttachToClaim={fnolAnalysisExtraActions.onAttachToClaim}
        />
      ) : null}
    </>
  );
};

function RelatedClaimsCardDialog({
  claims,
  onClose,
  onAttachToClaim,
}: {
  claims: RelatedClaim[];
  onClose: () => void;
  onAttachToClaim: ((claimId: number, extraParams: Record<string, unknown>) => void) | undefined;
}) {
  const columnData = [
    {
      id: 'claim_id',
      numeric: false,
      label: 'Claim',
      specialCell: (claim: RelatedClaim) => <ClaimLink claimId={claim.claim_id} linkText={claim.claim_id_display} />,
    },
    {
      id: 'action',
      numeric: false,
      label: '',
      specialCell: (claim: RelatedClaim) => (
        <ActionButton
          text="Attach to Claim"
          onClick={async () => {
            onAttachToClaim && (await onAttachToClaim(claim.claim_id, { should_create_new_contact: true }));
            onClose();
          }}
        />
      ),
    },
  ];

  return (
    <CardDialog
      isDialog
      title="Claims Found"
      subheader="This email might be related to one of the following claims"
      maxWidth="sm"
      fullWidth
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PlainTable columns={columnData} rows={claims} />
        </Grid>
      </Grid>
    </CardDialog>
  );
}

export default FnolAnalysisExtraBanner;
