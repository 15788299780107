import React from 'react';
import { getIn, useFormikContext } from 'formik';
import type { FormikValues } from 'formik/dist/types';

import { useStyles } from '~/assets/styles';
import SubPercentageSum from '~/components/exposures/ReinsuranceTable/SubPercentageSum';
import { useCms } from '~/components/hooks/useCms';
import { MonetaryValueTextFieldFormik } from '~/components/TextFieldFormik';
import type { ClaimModel } from '~/components/types/claim-types';
import type { ExposureModel } from '~/components/types/exposure-types';
import { isReinsuranceManagementClaim } from '~/Utils/ClaimUtils';

interface ReinsuranceShareFormikProps {
  id: string;
  claim: ClaimModel;
  exposure: ExposureModel;
  value?: number;
}

function ReinsuranceShareFormik({
  id,
  claim,
  exposure,
  value,
}: ReinsuranceShareFormikProps): React.ReactElement | null {
  const { values } = useFormikContext<FormikValues>();
  const { userOrganization } = useCms();
  const amountVal = value ?? getIn(values, id);

  if (!amountVal || !isReinsuranceManagementClaim(claim, userOrganization)) return null;

  return (
    <div>
      <span className="pr-4 text-sm text-grey-700">{`${exposure?.coverage?.coinsurance_insurer_percentage}% of 100% -`}</span>
      <SubPercentageSum exposure={exposure} value={amountVal} />
    </div>
  );
}
interface ReserveMonetaryValueTextFieldFormikProps {
  id: string;
  label: string;
  value?: number;
  showOnly?: boolean;
  exposure?: ExposureModel;
  claim: ClaimModel;
}

function ExposureMonetaryValueTextFieldFormik({
  id,
  label,
  value,
  showOnly,
  exposure,
  claim,
}: ReserveMonetaryValueTextFieldFormikProps): React.ReactElement {
  const classes = useStyles();
  const valueProp = value !== undefined ? { value } : {};

  return (
    <>
      <MonetaryValueTextFieldFormik
        id={id}
        label={label}
        {...valueProp}
        showOnly={showOnly}
        className={classes.textField}
      />
      {exposure ? <ReinsuranceShareFormik id={id} claim={claim} exposure={exposure} {...valueProp} /> : null}
    </>
  );
}

export { ReinsuranceShareFormik };

export default ExposureMonetaryValueTextFieldFormik;
