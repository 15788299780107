import React from 'react';

import Button from '~/components/core/Atomic/Buttons/Button';
import Divider from '~/components/core/Atomic/Divider/Divider';
import Text from '~/components/core/TextComponents/Text';
import CloseIcon from '~/components/icons/CloseIcon';

interface BulkSelectionPanelProps {
  selectedCounter: number;
  totalCounter: number;
  onClearSelection: () => void;
  actions: {
    key: string;
    Icon: React.ElementType;
    buttonText: string;
    onClickAction: () => void;
  }[];
  disabled: boolean;
}

const BulkSelectionPanel: React.FC<BulkSelectionPanelProps> = ({
  selectedCounter,
  totalCounter,
  onClearSelection,
  actions,
  disabled,
}) => {
  return (
    <div className="flex items-center p-24">
      <Text className="mr-24">{`${selectedCounter}/${totalCounter} Selected`}</Text>

      {actions.map(({ key, Icon, onClickAction, buttonText }) => {
        return (
          <Button
            disabled={disabled}
            startIcon={<Icon size={20} iconColor="currentColor" />}
            key={key}
            onClick={onClickAction}
            color="primary"
            variant="text"
          >
            {buttonText}
          </Button>
        );
      })}
      <Divider orientation="vertical" className="ml-20 mr-24 h-auto self-stretch" />
      <Button
        startIcon={<CloseIcon size={16} iconColor="currentColor" />}
        className="mr-12 normal-case"
        onClick={onClearSelection}
      >
        Clear Selection
      </Button>
    </div>
  );
};

export default BulkSelectionPanel;
