import React from 'react';

import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { DismissIcon } from '~/components/icons';
import cn from '~/Utils/cn';

import Text from '../core/TextComponents/Text';

interface DismissButtonProps {
  notification: ClaimNotificationModel;
  onClick: () => Promise<void>;
  tooltipTextDismiss?: string;
  tooltipTextUndismiss?: string;
  disabled?: boolean;
  textDismiss?: string;
  textUndismiss?: string;
  noLeftPadding?: boolean;
}

const DismissButton: React.FC<DismissButtonProps> = ({
  notification,
  onClick,
  tooltipTextDismiss = 'Dismiss',
  tooltipTextUndismiss = 'Unismiss',
  disabled,
  textDismiss,
  textUndismiss,
  noLeftPadding = false,
}) => {
  return (
    <Tooltip
      title={notification.datetime_dismissed ? tooltipTextUndismiss : tooltipTextDismiss}
      disabled={disabled || !!textDismiss || !!textUndismiss}
    >
      <button
        className={cn('group flex cursor-pointer items-center border-none bg-transparent outline-none', {
          ['pl-0']: noLeftPadding,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        <DismissIcon
          size={20}
          className={cn('cursor-pointer group-hover:stroke-teal-700', {
            'stroke-green-700': notification.datetime_dismissed && !textUndismiss && !textDismiss,
          })}
        />

        {textDismiss && !notification.datetime_dismissed && (
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD} className="px-4 group-hover:text-teal-700">
            {textDismiss}
          </Text>
        )}
        {textUndismiss && notification.datetime_dismissed && (
          <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.SEMI_BOLD} className="px-4 group-hover:text-teal-700">
            {textUndismiss}
          </Text>
        )}
      </button>
    </Tooltip>
  );
};

export default DismissButton;
