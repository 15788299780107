import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { isReinsuranceManagementClaim } from '~/Utils/ClaimUtils';

import { reportAxiosError } from '../../Utils';
import AssignHandlingAdjusterPopover from '../AssignHandlingAdjusterPopover';
import { useClaim } from '../ClaimContainer';
import { PERMISSION_ACTIONS, PERMISSION_VERBS, RestrictedPermissions } from '../core';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';

import { isExposureWriteDisabled } from './ExposureUtils';

function ExposureOwnerContainer(props) {
  const { exposure } = props;
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(undefined);
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();
  const isReinsuranceEnabled = isReinsuranceManagementClaim(claim, userOrganization);
  const handleAdjusterAssignment = async (values) => {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/assign_adjuster`, values);
      await onAsyncClaimUpdate();
      setPopoverAnchorEl(undefined);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  let handlingAdjusterText = '';

  if (exposure.handling_adjuster) {
    handlingAdjusterText = exposure.handling_adjuster;
  } else if (claim.handling_adjuster) {
    handlingAdjusterText = `${isReinsuranceEnabled ? '' : 'File Owner:'} ${claim.handling_adjuster}`;
  }

  const readOnly = isExposureWriteDisabled(exposure, user, { allowOnClosedExposure: true, enableIfDenied: true });

  return (
    <RestrictedPermissions action={PERMISSION_ACTIONS.CHANGE_EXPOSURE_OWNER} verb={PERMISSION_VERBS.WRITE}>
      <HoverActionField onAction={(e) => setPopoverAnchorEl(e.currentTarget)} disabled={readOnly}>
        {handlingAdjusterText}
      </HoverActionField>
      <AssignHandlingAdjusterPopover
        anchorEl={popoverAnchorEl}
        currentAdjusterId={exposure.handling_adjuster_id}
        onAssignAdjuster={handleAdjusterAssignment}
        onClose={() => setPopoverAnchorEl(undefined)}
        title="Assign Adjuster"
        noneText={claim.handling_adjuster ? claim.handling_adjuster : undefined}
        noneValue={claim.handling_adjuster ? 'None' : undefined}
      />
    </RestrictedPermissions>
  );
}

ExposureOwnerContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
};

export default ExposureOwnerContainer;
