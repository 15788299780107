import { v4 as uuidv4 } from 'uuid';

import type { AiAction, Exposure, ReserveActionData } from '~/components/AiChat/types';

const generateBaseActionData = (date: Date = new Date()): AiAction => {
  return {
    is_executed: false,
    is_ignored: false,
    action_id: `DEMO_${uuidv4()}`,
    created_at: date,
    updated_at: date,
    task_data: {
      task_id: `DEMO_${Date.now()}`,
      task_text: '',
      explanation: '',
      additional_actions: '',
      done_action: undefined,
      task_type: '',
    },
  };
};

export const generateCommunicationActionData = (claimCreationDate: Date): AiAction => {
  const baseActionData = generateBaseActionData(claimCreationDate);
  return {
    ...baseActionData,
    task_data: {
      task_text: 'Send Acknowledgement Letter',
      explanation: 'The claimant needs to be contacted to acknowledge the claim',
      additional_actions: '',
      done_action: undefined,
      task_type: 'communicate',
      content: '',
      subject: 'Acknowledgment of Claim',
      recipient_name: 'Claimant',
      recipient_email: '',
      recipient_id: '',
    },
  };
};

export const generateAdjusterAssignmentActionData = (claimCreationDate: Date, handlingAdjuster: string): AiAction => {
  const baseActionData = generateBaseActionData(claimCreationDate);
  return {
    ...baseActionData,
    is_executed: true,
    task_data: {
      task_text: `Adjuster **${handlingAdjuster}** was assigned to handle this claim.`,
      explanation: '',
      additional_actions: '',
      done_action: undefined,
      task_type: 'adjuster_assignment',
    },
  };
};

export const generateOpenExposureActionData = (claimCreationDate: Date, exposure: Exposure): AiAction => {
  const baseActionData = generateBaseActionData(claimCreationDate);
  return {
    ...baseActionData,
    is_executed: true,
    task_data: {
      task_text: `Open Exposure: **${exposure.label_text}**`,
      explanation: '',
      additional_actions: '',
      done_action: undefined,
      task_type: 'open_exposure',
    },
  };
};
export const generateSetReserveActionData = (claimCreationDate: Date, reserve: ReserveActionData): AiAction => {
  const baseActionData = generateBaseActionData(claimCreationDate);

  const task_text = `Set Reserve for exposure: **${reserve.label_text}**\n`;
  let explanation = '';
  if (reserve.expense_reserve) {
    explanation += `Expense Reserve: **${reserve.expense_reserve}**\n`;
  }
  if (reserve.indemnity_reserve) {
    explanation += `Indemnity Reserve: **${reserve.indemnity_reserve}**\n`;
  }
  return {
    ...baseActionData,
    is_executed: true,
    task_data: {
      task_text,
      explanation,
      additional_actions: '',
      done_action: undefined,
      task_type: 'set_reserve',
    },
  };
};
