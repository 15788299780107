import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const AtIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon disableStrokeColor width="20" height="20" viewBox="0 0 16 16" {...props}>
    <path
      d="M8 1.5a6.5 6.5 0 1 0 0 13c1.3444 0 2.7562-.405 3.7769-1.0831a.5008.5008 0 0 0 .2139-.513.5006.5006 0 0 0-.2119-.3188.499.499 0 0 0-.3753-.0756.5023.5023 0 0 0-.1805.0742c-.8481.565-2.085.9163-3.2231.9163a5.5004 5.5004 0 0 1-5.0813-3.3952 5.5002 5.5002 0 0 1 7.1861-7.1861A5.5002 5.5002 0 0 1 13.5 8c0 1.6531-.68 2-1.25 2S11 9.6531 11 8V5.5a.5002.5002 0 0 0-.5-.5.5002.5002 0 0 0-.5.5v.2663a3 3 0 1 0 .3706 4.0687c.375.75 1.0219 1.165 1.8794 1.165 1.4088 0 2.25-1.1212 2.25-3A6.5067 6.5067 0 0 0 8 1.5ZM8 10a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      fill={iconColor}
    />
  </SvgIcon>
);

export default AtIcon;
