import moment from 'moment-timezone';

type TimeUnit = 'seconds' | 'minutes' | 'hours' | 'days' | 'months' | 'years';

interface DateComparisonResult {
  readonly BEFORE: -1;
  readonly EQUAL: 0;
  readonly AFTER: 1;
}

type DateInput = string | Date;

const DATE_COMPARISON: DateComparisonResult = {
  BEFORE: -1,
  EQUAL: 0,
  AFTER: 1,
} as const;

const DEFAULT_MOCK_DATE = '2018-01-01';
const MINIMUM_AGE = 18;

export enum DateFormat {
  ISO = 'YYYY-MM-DD',
  US = 'MM/DD/YYYY',
  TIME_24H = 'HH:mm',
  FULL_DATE_TIME = 'MMMM Do YYYY, h:mm A',
  LOCAL_DATE_TIME = 'L LT',
  LOCAL_DATE = 'L',
  LOCAL_TIME = 'LT',
}

// most of the function below will return the current DateTime when given 'undefined', hiding many bugs
function verifyParam(param: unknown, paramName = 'parameter'): void {
  if (!param) {
    throw new Error(`Empty ${paramName} given`);
  }
}

export function getLocalDateToday(): string {
  return moment().format(DateFormat.ISO);
}

export function getAmericanDateFormat(date: DateInput): string {
  // verifyParam(date, 'date');
  return moment(date).format(DateFormat.US);
}

export function getDateFromServerDateTime(date: DateInput): string {
  verifyParam(date, 'date');
  return moment(date).format(DateFormat.LOCAL_DATE);
}

export function getLocalDateTime(date: DateInput): string {
  verifyParam(date, 'date');
  return moment(date).format(DateFormat.ISO);
}

export function isoDateToUs(isoDate: DateInput): string {
  verifyParam(isoDate, 'isoDate');
  return moment.utc(isoDate).format(DateFormat.LOCAL_DATE);
}

export function isoUtcDateTimeToLocal(isoDate: DateInput): string {
  verifyParam(isoDate, 'isoDate');
  return moment.utc(isoDate).local().format(DateFormat.LOCAL_DATE_TIME);
}

export function isoDateTimeToLocal(isoDate: DateInput): string {
  verifyParam(isoDate, 'isoDate');
  return moment(isoDate).local().format(DateFormat.LOCAL_DATE_TIME);
}

export function timeToLocalTime(time: string): string {
  verifyParam(time, 'time');
  // pretending a fictive date before the time for moment to work
  return moment.utc(`${DEFAULT_MOCK_DATE} ${time}`).format(DateFormat.LOCAL_TIME);
}

export function serverDateToLocal(serverDate: DateInput): string {
  verifyParam(serverDate, 'serverDate');
  return moment.utc(serverDate).local().format(DateFormat.LOCAL_DATE);
}

export function serverDateFormatWithoutTZChange(serverDate: DateInput): string {
  verifyParam(serverDate, 'serverDate');
  return moment.utc(serverDate).format(DateFormat.LOCAL_DATE);
}

export function serverDateTimeToLocal(serverDateTime: DateInput): string {
  verifyParam(serverDateTime, 'serverDateTime');
  return moment.utc(serverDateTime).local().format(DateFormat.LOCAL_DATE_TIME);
}

export function serverDateTimeToLocalMoment(serverDateTime: DateInput): moment.Moment {
  verifyParam(serverDateTime, 'serverDateTime');
  return moment.utc(serverDateTime).local();
}

export function serverDateToLocalMoment(serverDate: DateInput, showTimeZone = true): string {
  verifyParam(serverDate, 'serverDate');
  if (moment.locale() === 'en') {
    return moment.utc(serverDate).tz('America/New_York').format(DateFormat.LOCAL_DATE) + (showTimeZone ? ' EST' : '');
  }

  return moment.utc(serverDate).local().format(DateFormat.LOCAL_DATE);
}

export function serverDateTimeToLocalDate(serverDateTime: DateInput): string {
  verifyParam(serverDateTime, 'serverDateTime');
  return moment.utc(serverDateTime).local().format(DateFormat.ISO);
}

export function localTimeToViewTime(localTime: string): string {
  verifyParam(localTime, 'localTime');
  return moment.utc(`${DEFAULT_MOCK_DATE} ${localTime}`).format(DateFormat.LOCAL_TIME);
}

export function compareDatesStrings(
  date1: string | Date,
  date2: string | Date
): DateComparisonResult[keyof DateComparisonResult] {
  // verifyParam(date1, 'date1');
  // verifyParam(date2, 'date2');

  if (moment(date1).isBefore(moment(date2))) return DATE_COMPARISON.BEFORE;
  if (moment(date1).isAfter(moment(date2))) return DATE_COMPARISON.AFTER;
  return DATE_COMPARISON.EQUAL;
}

export function datesYearsDiff(date1: DateInput, date2: DateInput): number | undefined {
  if (!date1 || !date2) {
    return undefined;
  }
  return moment(date1).diff(date2, 'years', true);
}

export function isDatesDiffLessThan18Years(date1: DateInput, date2: DateInput): boolean {
  if (!date1 || !date2) {
    return false;
  }
  return Math.abs(datesYearsDiff(date1, date2) ?? 0) < MINIMUM_AGE;
}

export const datesDiffFromUtcNow = (dateUtc: string, unitOfTime: TimeUnit = 'seconds'): number | undefined => {
  if (!dateUtc) {
    return undefined;
  }

  return moment.utc().diff(dateUtc, unitOfTime, true);
};

export const convertSecondsToHours = (seconds: number): number => {
  if (seconds < 0) {
    throw new Error('Seconds must be a positive number');
  }
  return Math.round(seconds / 3600);
};

export const stringDateIsOfFormat = (date: string, format: DateFormat): boolean => moment(date, format, true).isValid();

export const isISOFormat = (date?: string): boolean => {
  return !!date?.includes('T');
};

export const isDateFormat = (date?: string): boolean => {
  return !!date?.match(/^\d{4}-\d{2}-\d{2}$/);
};
