import type { ReactElement } from 'react';
import React, { useState } from 'react';
import axios from 'axios';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';

import type { ReminderClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import Button from '~/components/core/Atomic/Buttons/Button';
import { useHasPermission } from '~/components/hooks/useHasPermission';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';

import { isFeatureEnabled, reportAxiosError } from '../../Utils';
import { useFetchClaim } from '../../Utils/ClaimUtils';
import { PERMISSION_ACTIONS, PERMISSION_VERBS } from '../core';
import { useCms } from '../hooks/useCms';
import LoadingDialog from '../LoadingDialog';

import type { ReminderFormValues, ReminderRequestParams } from './formHelpers';
import {
  reminderFormValuesToRequestParams,
  TARGET_CLAIM_HANDLER,
  TARGET_MYSELF,
  TARGET_ORGANIZATION_UNIT_LEADER,
  TARGET_OTHER_USER,
} from './formHelpers';
import { ReminderDialogCard, reminderValidationSchema } from '.';

import { useStyles } from '../../assets/styles';

interface ReminderDisplayProps {
  reminder: ReminderClaimNotificationModel;
  open: boolean;
  onClose: () => void;
  onUpdate: () => void | Promise<void>;
  AdditionalDialogHeader?: ReactElement;
}

const ReminderDisplay: React.FC<ReminderDisplayProps> = (props) => {
  const { open, onClose, reminder, onUpdate, AdditionalDialogHeader = <React.Fragment /> } = props;
  const classes = useStyles();
  const { user } = useCms();
  const [showOnly, setShowOnly] = useState(true);
  const [title, setTitle] = useState('View Notification');
  const [reminderClaim, isLoading, isError] = useFetchClaim(reminder.claim_id);
  const hasNotificationPermission = useHasPermission({
    action: PERMISSION_ACTIONS.NOTIFICATION,
    verb: PERMISSION_VERBS.WRITE,
  });
  const { userOrganization } = useCms();
  const isNotifications2Enabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.NOTIFICATIONS_2);

  const getInitialValues = (): ReminderFormValues => {
    // Currently only one category per notification is supported
    const category_id = reminder.categories?.[0]?.id;
    const notification: ReminderFormValues = {
      ...reminder,
      category_id,
      target_id: '',
    };
    if (showOnly) {
      return {
        ...notification,
        target_id: reminder.user ? TARGET_MYSELF : reminder.exposure_id ? reminder.exposure_id : TARGET_CLAIM_HANDLER,
      };
    }
    if (reminder.organization_unit_id) {
      return {
        ...notification,
        target_id: TARGET_ORGANIZATION_UNIT_LEADER,
        organization_unit_id: reminder.organization_unit_id,
      };
    }
    if (reminder.user && reminder.user_id !== user.id) {
      return {
        ...notification,
        target_id: TARGET_OTHER_USER,
        other_adjuster_id: '', // Other adjuster may not be possible to reselect
      };
    }
    return {
      ...notification,
      target_id: reminder.user
        ? TARGET_MYSELF
        : reminder.exposure_id
        ? reminder.exposure_id
        : reminderClaim.handling_adjuster_id
        ? TARGET_CLAIM_HANDLER
        : '',
      other_adjuster_id: '',
    };
  };

  const handleEdit = () => {
    setShowOnly(false);
    setTitle('Reset Notification');
  };

  const submitResetReminder = async (
    values: ReminderFormValues,
    { setSubmitting, resetForm }: FormikHelpers<ReminderFormValues>
  ) => {
    setSubmitting(true);
    const processedValues: ReminderRequestParams = reminderFormValuesToRequestParams(values, isNotifications2Enabled);
    try {
      await axios.patch(`/api/v1/claims/${reminderClaim.id}/reminders/${reminder.id}`, processedValues);
      await onUpdate();
      resetForm();
      onClose();
    } catch (error) {
      setSubmitting(false);
      await reportAxiosError(error);
    }
  };

  if (isLoading) {
    return <LoadingDialog isError={isError} track={title} />;
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={reminderValidationSchema}
      enableReinitialize
      onSubmit={submitResetReminder}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        const cardDialogProps = {
          open,
          isDialog: true,
          fullWidth: true,
          maxWidth: 'sm',
          onClose,
          title,
          preventClose: isSubmitting,
        };

        const buttonsComponent = hasNotificationPermission ? (
          showOnly ? (
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleEdit()}>
                Edit notification
              </Button>
            </div>
          ) : (
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                Set notification
              </Button>
            </div>
          )
        ) : (
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" onClick={onClose}>
              Close
            </Button>
          </div>
        );

        return (
          <ReminderDialogCard
            buttonsComponent={reminderClaim && buttonsComponent} //If reminder is not related to claim, don't allow to edit the reminder
            cardDialogProps={cardDialogProps}
            claim={reminderClaim}
            showOnly={showOnly}
            targetUser={reminder.user ? reminder.user : undefined}
            onUpdate={onUpdate}
            AdditionalDialogHeader={AdditionalDialogHeader}
          />
        );
      }}
    </Formik>
  );
};

export default ReminderDisplay;
