import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray, getIn, useFormikContext } from 'formik';

import Button from '~/components/core/Atomic/Buttons/Button';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import { AddIcon } from '~/components/deprecatedMuiIcons';

import cn from '../../../../Utils/cn';
import CheckboxFormik from '../../../CheckboxFormik';
import { FsIconButton, Text } from '../../../core';
import { TrashIcon } from '../../../icons';
import InlineIconButton from '../../../InlineIconButton';
import { DatePickerTextFieldFormik, TextFieldFormik } from '../../../TextFieldFormik';
import { ErrorHelperTextFormik } from '../ErrorHelperTextFormik';

import { getReactKeyFromArgs } from './arrayFieldUtils';

import { useStyles } from '../../../../assets/styles';
import styles from './ArrayFieldFormik.module.scss';

const ArrayFieldFormik = ({
  fieldId,
  innerObjectConfig,
  initialNewItemValues,
  label = '',
  plusButton,
  addNewItemButtonText,
  disabled,
  allowDeletingInitialValues = false,
  addNewItemButtonStartIcon,
}) => {
  const classes = useStyles();
  const { values, initialValues, errors } = useFormikContext();

  const initialValue = getIn(initialValues, fieldId);
  const currentValue = getIn(values, fieldId);

  const initialValuesLastIndex =
    !allowDeletingInitialValues && Array.isArray(initialValue) && initialValue.length > 0
      ? initialValue.length - 1
      : -1;

  const getFieldByType = (fieldConfig, index) => {
    const { id, type, label, disableEditAfterInitialSet, additionalProps, containerClassName, customComponent } =
      fieldConfig;
    const path = `${fieldId}.${index}.${id}`;
    const args = {
      id: path,
      label,
      ...additionalProps,
    };

    const commonProps = {
      disableEditAfterInitialSet,
      containerClassName,
    };

    switch (type) {
      case 'text':
        return {
          Comp: TextFieldFormik,
          args: { ...args, fullWidth: true },
          ...commonProps,
          containerClassName,
        };
      case 'switch':
        return {
          Comp: SwitchFormik,
          args: {
            ...args,
            className: classes.formsSwitch,
            color: 'primary',
            labelStyle: { margin: '10px 0 0 0' },
          },
          ...commonProps,
        };
      case 'checkbox':
        return {
          Comp: CheckboxFormik,
          args: { ...args, className: styles.checkbox },
          ...commonProps,
        };
      case 'date':
        return {
          Comp: DatePickerTextFieldFormik,
          args: { ...args, className: cn(classes.textField, styles.date) },
          ...commonProps,
        };
      case 'custom':
        return {
          Comp: customComponent,
          args: { ...args },
          ...commonProps,
        };
    }
  };

  const fieldLevelError = typeof errors[fieldId] === 'string' ? errors[fieldId] : null;

  return (
    <FieldArray
      name={fieldId}
      render={(arrayHelpers) => (
        <div className={cn(`mr-6 flex flex-col`)}>
          <Text>{label}</Text>
          {Array.isArray(currentValue) &&
            currentValue.map((value, i) => (
              <div className={cn(`row-auto flex w-full justify-between`)} key={getReactKeyFromArgs(fieldId, i)}>
                {innerObjectConfig.map((field, j) => {
                  const { Comp, args, disableEditAfterInitialSet, containerClassName } = getFieldByType(field, i);
                  return (
                    <div
                      key={getReactKeyFromArgs(fieldId, field.id, i, j)}
                      className={cn('w-100 row-auto flex', styles.rowItem, containerClassName, {
                        [styles.first]: j === 0,
                      })}
                    >
                      <Comp
                        {...args}
                        disabled={disabled || (disableEditAfterInitialSet && i <= initialValuesLastIndex)}
                      />
                    </div>
                  );
                })}
                {plusButton && (
                  <div
                    key={getReactKeyFromArgs(fieldId, 'add', i)}
                    className="ml-auto flex w-40 content-center items-center justify-center"
                  >
                    <FsIconButton
                      icon={AddIcon}
                      tooltipTitle="Add"
                      iconStyle={{ display: 'flex' }}
                      className={cn(classes.textIcon, classes.hoverableIcon)}
                      onClick={() => arrayHelpers.insert(i + 1, initialNewItemValues)}
                      disabled={disabled}
                    />
                  </div>
                )}
                <div className="ml-auto flex w-40 content-center items-center justify-center">
                  {allowDeletingInitialValues || i > initialValuesLastIndex ? (
                    <InlineIconButton
                      icon={TrashIcon}
                      tooltipTitle="Remove"
                      iconStyle={{ display: 'flex' }}
                      className={cn(classes.textIcon, classes.hoverableNonFilledIcon)}
                      onClick={() => {
                        arrayHelpers.remove(i);
                      }}
                      disabled={disabled}
                    />
                  ) : null}
                </div>
              </div>
            ))}
          {fieldLevelError && <ErrorHelperTextFormik id={fieldId} />}
          <div className="flex">
            {addNewItemButtonText && (
              <Button
                variant="text"
                color="primary"
                onClick={() => arrayHelpers.push(initialNewItemValues)}
                disabled={disabled}
                startIcon={addNewItemButtonStartIcon}
              >
                {addNewItemButtonText}
              </Button>
            )}
          </div>
        </div>
      )}
    />
  );
};

ArrayFieldFormik.propTypes = {
  fieldId: PropTypes.string.isRequired,
  innerObjectConfig: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['text', 'switch', 'checkbox', 'date', 'custom']).isRequired,
      additionalProps: PropTypes.object,
      disableEditAfterInitialSet: PropTypes.bool,
      containerClassName: PropTypes.string,
      customComponent: PropTypes.element,
    })
  ).isRequired,
  initialNewItemValues: PropTypes.object.isRequired,
  label: PropTypes.string,
  allowDeletingInitialValues: PropTypes.bool,
  plusButton: PropTypes.bool,
  addNewItemButtonText: PropTypes.node,
  disabled: PropTypes.bool,
  addNewItemButtonStartIcon: React.ReactNode,
};

export default ArrayFieldFormik;
