import React, { useState } from 'react';

import ClaimLink from '~/components/ClaimLink';
import { getClaimNotificationIcon } from '~/components/ClaimNotificationsCard/ClaimNotificationCardUtils';
import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import { TooltipIcon } from '~/components/core';
import Checkbox from '~/components/core/Atomic/Checkboxes/Checkbox';
import SortableTable from '~/components/core/Tables/SortableTable';
import type { PaginationProps } from '~/components/core/Tables/SortableTable/TablePaginationSection/types';
import { ExposureDetailedTitle } from '~/components/exposures/ExposureDetailedTitle';
import { useCms } from '~/components/hooks/useCms';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { ThreeDotsHorizontalIcon } from '~/components/icons';
import NotificationAssignee from '~/components/Notifications/Assignee';
import CategoryEditableChip from '~/components/Notifications/CategoryEditableChip';
import DismissButton from '~/components/Notifications/DismissButton';
import NotificationDueDate from '~/components/Notifications/NotificationDueDate';
import PriorityEditableChip from '~/components/Notifications/PriorityEditableChip';
import type { NotificationsTableDisplayOptions } from '~/components/Notifications/types';
import {
  changeClaimNotificationState,
  isRestrictedPredicate,
  restrictedContentMessage,
} from '~/components/Notifications/utils';
import { serverDateToLocal } from '~/DateTimeUtils';
import { getLobIcon } from '~/Utils/lobUtils';

import type { ExposureModel } from '../types/exposure-types';

interface NotificationsTableProps {
  notifications: ClaimNotificationModel[];
  displayOptions: NotificationsTableDisplayOptions;
  exposuresDict: { [id: number]: ExposureModel };
  pagination: PaginationProps;
  onUpdateNotification: () => Promise<void> | undefined;
  onSortByColumn: (e: React.MouseEvent<HTMLSpanElement>, sortProps: { id: string; order: 'desc' | 'asc' }) => void;
  disableSort?: boolean;
  defaultSortOrder: 'desc' | 'asc';
  onHandleShowNotification: (id: number) => Promise<void>;
  notificationsReadStatusDict: { [notification_id: number]: boolean };
  onlyDismissed?: boolean;
  notificationIdToIsSelectedMap: { [notification_id: number]: boolean };
  setNotificationIdToIsSelectedMap: (newValue: { [notification_id: number]: boolean }) => void;
  defaultSortColumn: string;
}

const NotificationsTable: React.FC<NotificationsTableProps> = ({
  notifications,
  displayOptions,
  exposuresDict,
  onUpdateNotification,
  pagination,
  onSortByColumn,
  disableSort,
  defaultSortOrder,
  onHandleShowNotification,
  notificationsReadStatusDict,
  onlyDismissed,
  notificationIdToIsSelectedMap,
  setNotificationIdToIsSelectedMap,
  defaultSortColumn,
}) => {
  const [notificationIdsSubmitting, setNotificationIdsSubmitting] = useState<number[]>([]);

  const { userOrganization } = useCms();
  const { isLoading: isLoadingLobConfiguration, lobConfigurationsDict } = useLobConfiguration();

  function claimNotificationClaimTypeIcon(notification: ClaimNotificationModel) {
    if (!notification.claim_id || !notification.claim_lob || isLoadingLobConfiguration) {
      return null;
    }

    return getLobIcon({ lob: notification.claim_lob, lobConfigurationsDict });
  }

  const setNotificationAsSubmitted = (id: number) => setNotificationIdsSubmitting((prev) => [...prev, id]);
  const setNotificationAsNotSubmitted = (id: number) =>
    setNotificationIdsSubmitting((prev) => prev.filter((currId) => currId !== id));

  React.useEffect(() => {
    setNotificationIdsSubmitting([]);
  }, [notifications]);

  const columns = [
    {
      id: 'select_checkbox',
      label: '',
      width: 25,
      disableSort: true,
      specialCell: (notification: ClaimNotificationModel) => (
        <Checkbox
          color="primary"
          checked={notification && notificationIdToIsSelectedMap[notification.id]}
          onChange={(_event, newValue) => {
            const newState = { ...notificationIdToIsSelectedMap };
            if (newValue) {
              newState[notification.id] = newValue;
            } else {
              delete newState[notification.id];
            }
            setNotificationIdToIsSelectedMap(newState);
          }}
        />
      ),
    },
    {
      id: 'dismiss_action',
      label: '',
      width: 25,
      disableSort: true,
      specialCell: (notification: ClaimNotificationModel) => (
        <DismissButton
          notification={notification}
          onClick={() =>
            changeClaimNotificationState(
              notification,
              () => setNotificationAsSubmitted(notification.id),
              () => setNotificationAsNotSubmitted(notification.id),
              () => onUpdateNotification()
            )
          }
          disabled={notificationIdsSubmitting.includes(notification.id)}
        />
      ),
    },
    {
      id: 'type',
      label: 'Type',
      width: 15,
      disableSort: true,
      specialCell: (notification: ClaimNotificationModel) => getClaimNotificationIcon(notification, undefined, 20),
    },
    {
      id: 'priority',
      label: 'Priority',
      width: 100,
      specialCell: (notification: ClaimNotificationModel) => (
        <PriorityEditableChip
          notification={notification}
          disabled={notificationIdsSubmitting.includes(notification.id)}
          setIsUpdating={(isUpdating: boolean) => {
            if (isUpdating) {
              setNotificationAsSubmitted(notification.id);
            }
          }}
          onUpdate={onUpdateNotification}
        />
      ),
    },
    {
      id: 'due_date',
      label: 'Date',
      specialCell: (notification: ClaimNotificationModel) => (
        <NotificationDueDate
          notification={notification}
          disabled={notificationIdsSubmitting.includes(notification.id)}
          setIsUpdating={(isUpdating) => {
            if (isUpdating) {
              setNotificationAsSubmitted(notification.id);
            } else {
              setNotificationAsNotSubmitted(notification.id);
            }
          }}
          onUpdate={onUpdateNotification}
        />
      ),
    },
    {
      id: 'sub_organization',
      label: 'Sub-Organization',
      specialCell: (notification: ClaimNotificationModel) => notification.sub_organization_name,
      isHidden: !userOrganization.sub_organizations_enabled,
    },
    {
      id: 'claim_id',
      label: 'Claim',
      specialCell: (notification: ClaimNotificationModel) =>
        notification.claim_id ? (
          <div className="flex flex-nowrap items-center">
            {claimNotificationClaimTypeIcon(notification)}
            <span className="pl-4">
              <ClaimLink claimId={notification.claim_id} linkText={notification.claim_id_display || ''} />
            </span>
          </div>
        ) : null,
      isHidden: displayOptions.showClaim,
    },
    {
      id: 'label_text',
      label: 'Exposure',
      disableSort: true,
      specialCell: (notification: ClaimNotificationModel) =>
        notification.exposure_id && exposuresDict[notification.exposure_id] ? (
          <ExposureDetailedTitle exposure={exposuresDict[notification.exposure_id]} />
        ) : null,
      isHidden: !displayOptions.showExposure,
      hideRestrictedContent: true,
    },
    {
      id: 'title',
      label: 'Title',
      hideRestrictedContent: true,
    },
    {
      id: 'recipient_user',
      label: 'Assignee',
      specialCell: (claimNotification: ClaimNotificationModel) => {
        return <NotificationAssignee notification={claimNotification} />;
      },
    },
    {
      id: 'created_by_username',
      label: 'Creator',
      disableSort: true,
    },
    {
      id: 'category',
      label: 'Category',
      specialCell: (notification: ClaimNotificationModel) => (
        <CategoryEditableChip
          claimNotification={notification}
          disabled={notificationIdsSubmitting.includes(notification.id)}
          setIsUpdating={() => setNotificationAsSubmitted(notification.id)}
          onUpdate={onUpdateNotification}
        />
      ),
      disableSort: true,
    },
    {
      id: 'datetime_dismissed',
      label: 'Date Dismissed',
      specialCell: (notification: ClaimNotificationModel) =>
        notification.datetime_dismissed ? serverDateToLocal(notification.datetime_dismissed) : '',
      isHidden: !onlyDismissed,
      hideRestrictedContent: true,
    },
    {
      id: 'dismissing_user',
      label: 'Dismissed By',
      isHidden: !onlyDismissed,
    },
    {
      id: 'view_action',
      width: 25,
      disableSort: true,
      specialCell: (notification: ClaimNotificationModel) => (
        <TooltipIcon title="View more details">
          <ThreeDotsHorizontalIcon
            className="cursor-pointer hover:stroke-teal-700"
            onClick={async () => await onHandleShowNotification(notification.id)}
          />
        </TooltipIcon>
      ),
      hideRestrictedContent: true,
    },
  ];

  const orderByColumnIdx = columns.findIndex((column) => column.id === defaultSortColumn);

  const unreadRowsIds = notificationsReadStatusDict
    ? notifications?.filter((notification) => !notificationsReadStatusDict?.[notification.id]).map((n) => n.id) || []
    : [];

  return (
    <SortableTable
      columns={columns}
      rows={notifications}
      stickyHeader
      defaultOrderColumn={orderByColumnIdx}
      order={defaultSortOrder}
      paginationProps={pagination}
      onSortByColumn={onSortByColumn}
      disableSortByUser={disableSort}
      keepRowsOrder={disableSort}
      paginationLocation="both"
      isRestrictedPredicate={isRestrictedPredicate}
      restrictedContentTooltipText={restrictedContentMessage}
      boldRowsIds={unreadRowsIds}
      selectedRowsIds={unreadRowsIds}
      greyedOutRowsIds={notificationIdsSubmitting}
    />
  );
};

export default NotificationsTable;
