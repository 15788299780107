import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const ChevronDownIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon width="24" height="24" viewBox="0 0 20 20" fill="none" iconColor={iconColor} {...props}>
    <path
      d="M3.75 7.5L10 13.75L16.25 7.5"
      stroke={iconColor}
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default ChevronDownIcon;
