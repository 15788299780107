import React, { useRef, useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { get, isEmpty, set, unset } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { localeDetails } from '~/components/CmsMain/localeGlobals';
import ViewCommunicationCardContainer from '~/components/communications/ViewCommunicationCardContainer';
import ExternalClaimNumber from '~/components/Fnol/NewFnolUI/FnolExternalClaimNumber';
import { FnolTriage } from '~/components/Fnol/NewFnolUI/FnolTriage';
import { useCms } from '~/components/hooks/useCms';
import { useIncidentConfiguration } from '~/components/hooks/useIncidentConfiguration';
import useIsConfigurationFieldSupportedBySubtype from '~/components/hooks/useIsConfigurationFieldSupportedBySubtype';
import { useLob } from '~/components/hooks/useLob';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { cleanEmptyValues, getBasicFnolValues, getObjectPaths, isFeatureEnabled, reportAxiosError } from '~/Utils';

import { FsIndexPanel } from '../../../core';
import {
  cleanConfiguredFieldsFormikValuesBeforeSend,
  getConfiguredFieldsEmptyFormikInitialValues,
  getConfiguredFieldsValidations,
} from '../../../IncidentConfiguration/ConfiguredFields';
import { usePolicy } from '../../../PolicyContainer';
import AdditionalInformation, {
  getIsAdditionalInformationActive,
  getSectionsValidationSchema,
} from '../AdditionalInformation';
import {
  mapSupplementaryInformationToIncident,
  SUPPLEMENTARY_INFORMATION_PATH,
} from '../AdditionalInformation/Fragments/SupplementaryInformationFragment';
import FnolFiles from '../FnolFiles';
import FnolFooter, { getFooterInitialValues, getFooterValidation } from '../FnolFooter';
import FnolHeader from '../FnolHeader';
import FnolHeadline, { getVerifiedInsuredInfoValidation } from '../FnolHeadline';
import FnolWitnesses from '../FnolWitnesses';
import {
  getWitnessesIncidentInitialValues,
  getWitnessesIncidentValidationSchema,
} from '../FnolWitnesses/WitnessSummary';
import {
  getIncidentDetailsValidationSchema,
  getPredefinedIncidentDetailsFieldsValidations,
  IncidentDetailsCardFormik,
  incidentDetailsInitialValues,
  mapValuesToIncidentDetails,
} from '../IncidentDetails';
import {
  buildLobSpecificParties,
  getLobSpecificPartiesInitialValues,
  getLobSpecificPartiesValidationSchema,
  InvolvedPartiesCardFormik,
} from '../InvolvedParties';
import SectionWrapper from '../SectionWrapper';

const createContactIdsToRefIdMapping = (valuesToSend, contactIdsPaths) => {
  const idToRefId = {};
  contactIdsPaths.forEach((path) => {
    const contactId = get(valuesToSend, path);
    if (!contactId) {
      return;
    }
    const contactPath = path.slice(0, -1 * '_id'.length);
    const contact = get(valuesToSend, contactPath);
    if (!contact) {
      return;
    }
    if (!contact.ref_id) {
      contact.ref_id = contactId.toString();
    }
    idToRefId[contactId] = contact.ref_id;
  });

  return idToRefId;
};

const prepareContactToSend = (contact) => {
  const emails = contact?.emails?.map((email) => ({ email_address: email.email, ...email })) || [];
  return {
    ...contact,
    emails,
    address1: contact.street_address1,
    address2: contact.street_address2,
    creation_mode: 'existing',
  };
};

const populateContactsInValuesToSend = (valuesToSend, policy) => {
  const contactIdsPaths = getObjectPaths(valuesToSend).filter((path) => path.endsWith('contact_id'));
  const idToRefId = createContactIdsToRefIdMapping(valuesToSend, contactIdsPaths);

  contactIdsPaths.forEach((path) => {
    const contactId = get(valuesToSend, path);
    if (!contactId) {
      unset(valuesToSend, path);
      return;
    }
    const contactPath = path.slice(0, -1 * '_id'.length);
    const contact = get(valuesToSend, contactPath);
    const contactRefId = idToRefId[contactId];

    const pathWithRefId = path.replace('contact_id', 'contact_ref_id');
    unset(valuesToSend, path);
    set(valuesToSend, pathWithRefId, contactRefId);

    if (!isEmpty(contact) && !valuesToSend.contacts.find((contact) => contact.ref_id === contactRefId)) {
      valuesToSend.contacts.push(prepareContactToSend(contact));
    }
  });

  policy.contacts.forEach((policyContact) => {
    if (valuesToSend.contacts.find((contact) => contact.id === policyContact.id)) {
      return;
    }
    policyContact['ref_id'] = policyContact.id.toString();
    valuesToSend.contacts.push(prepareContactToSend(policyContact));
  });

  return valuesToSend;
};

const FnolScreen = ({
  partiesStore,
  onChangePartiesStore,
  onSaveDraft,
  onSubTypeChange,
  communicationId,
  showCliveWarning,
}) => {
  const { incidentConfiguration } = useIncidentConfiguration();
  const { userOrganization } = useCms();
  const { lob } = useLob();
  const isFnolExternalClaimNumberFeatureEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.FNOL_EXTERNAL_CLAIM_NUMBER_SECTION
  );

  return (
    <div className="h-auto">
      <PanelGroup direction="horizontal" id="group-fnol-form" className="absolute inset-0 flex w-full gap-4">
        <Panel id="fnol-form-display" defaultSize={65}>
          <FnolHeader />
          <div className="h-screen overflow-scroll">
            <div className="mt-40">
              <FnolHeadline showCliveWarning={showCliveWarning} />
              <FsIndexPanel
                sections={[
                  {
                    id: 'incident_details',
                    title: 'Incident Details',
                    node: <IncidentDetailsCardFormik onSubTypeChange={onSubTypeChange} />,
                  },
                  {
                    id: 'involved_parties',
                    title: 'Involved Parties',
                    node: (
                      <InvolvedPartiesCardFormik
                        partiesStore={partiesStore}
                        onChangePartiesStore={onChangePartiesStore}
                      />
                    ),
                  },
                  {
                    id: 'additional_information',
                    title: 'Additional Information',
                    node: <AdditionalInformation />,
                    isHidden: !getIsAdditionalInformationActive(incidentConfiguration, lob),
                  },
                  {
                    id: 'witnesses',
                    title: 'Witnesses',
                    node: <FnolWitnesses />,
                    isHidden: !incidentConfiguration?.witnesses?.active,
                  },
                  {
                    id: 'triage',
                    title: 'Triage',
                    node: <FnolTriage />,
                    isHidden: !incidentConfiguration?.triage?.active,
                  },
                  {
                    id: 'external_claim_numbers',
                    title: 'External Claim Numbers',
                    node: <ExternalClaimNumber />,
                    isHidden: !isFnolExternalClaimNumberFeatureEnabled,
                  },
                  { id: 'files', title: 'Files', node: <FnolFiles /> },
                ]}
                panelTitle="FNOL Content"
                SectionWrapperComponent={SectionWrapper}
              />
              {/* Gap between end of sections and the footer */}
              {Array.from(Array(16).keys()).map((item, index) => (
                <SectionWrapper key={index} />
              ))}
            </div>
          </div>
          <FnolFooter onSaveDraft={onSaveDraft} partiesStore={partiesStore} />
        </Panel>
        {communicationId && (
          <>
            <PanelResizeHandle id="resize-handle" className="mx-12 w-2 rounded-lg bg-slate-500" />
            <Panel
              id="communication-display"
              className="mt-40 bg-white pt-32"
              defaultSize={35}
              minSize={25}
              maxSize={80}
            >
              <div className="h-full overflow-scroll">
                <div className="mb-80">
                  <ViewCommunicationCardContainer
                    communicationId={communicationId}
                    disableAdditionalActions
                    displayAttachClaim={false}
                    isDialog={false}
                  />
                </div>
              </div>
            </Panel>
          </>
        )}
      </PanelGroup>
    </div>
  );
};

FnolScreen.propTypes = {
  policy: PropTypes.object.isRequired,
  incidentConfiguration: PropTypes.object.isRequired,
  partiesStore: PropTypes.object.isRequired,
  onChangePartiesStore: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  onSubTypeChange: PropTypes.func,
  communicationId: PropTypes.number,
  fnolRecommendationId: PropTypes.string,
  showCliveWarning: PropTypes.bool,
};

const FnolScreenFormik = ({
  onSubmitDraft,
  fnolDraft,
  subOrganizationId,
  claimValues,
  onSubmitFnol,
  communicationId,
  fnolAiRecommendationId = null,
  showCliveWarning,
}) => {
  const { userOrganization } = useCms();
  const { policy } = usePolicy();
  const { claimType, lob } = useLob();
  const { incidentConfiguration } = useIncidentConfiguration();
  const [partiesStore, setPartiesStore] = useState(fnolDraft ? fnolDraft.draft.partiesStore || {} : {});
  const { isFieldSupportedBySubtype } = useIsConfigurationFieldSupportedBySubtype();
  const [subType, setSubType] = useState();
  const [type, setType] = useState();
  const external_id = useRef(uuidv4());

  const handleSubmitDraft = async (values, setSubmitting) => {
    await onSubmitDraft({ ...values, partiesStore });
    setSubmitting(false);
  };

  const extractExternalClaimNumberForSave = (externalClaimNumber) => {
    return {
      external_claim_number: externalClaimNumber.external_claim_number,
      external_contact_id: externalClaimNumber.external_contact?.id,
      description: externalClaimNumber.description,
    };
  };

  const handleSubmitFnol = async (values) => {
    const valuesToSend = {
      external_id: external_id.current,
      sub_organization_external_id: policy?.sub_organization?.external_id,
      lob,
      fnol_policyholder_contact_id: policy.insured_contact_id,
      submission_mode: values?.submission_mode,
      reported_date: values?.reported_date,
      policy_retrieval_object: {
        retrieval_mode: policy?.is_manual ? 'manual' : 'policy_api_uid',
        policy_holder_contact_id: policy?.insured_contact_id,
        policy_holder_contact: policy?.insured_contact,
        policy_api_uid: policy?.api_uid,
        policy: {
          is_claims_made: policy?.is_claims_made,
        },
      },
      incident_details: {
        ...cleanEmptyValues(mapValuesToIncidentDetails(values)),
        ...cleanEmptyValues(mapSupplementaryInformationToIncident(values)),
        additional_fields: cleanConfiguredFieldsFormikValuesBeforeSend(
          incidentConfiguration.incident_details.configured_fields,
          {
            ...values.configured_fields_values,
            ...get(values, SUPPLEMENTARY_INFORMATION_PATH, {})?.configured_fields_values,
          }
        ),
        auto_incident_additional: {
          fault_assessment: {
            ...cleanEmptyValues(values?.incident_details?.auto_incident_additional?.fault_assessment),
          },
          theft_follow_up: {
            ...cleanEmptyValues(values?.incident_details?.auto_incident_additional?.theft_follow_up),
          },
          accident_follow_up: {
            ...cleanEmptyValues(values?.incident_details?.auto_incident_additional?.accident_follow_up),
          },
          police_and_fire_department: {
            ...cleanEmptyValues(values?.incident_details?.auto_incident_additional?.police_and_fire_department),
          },
          ...cleanEmptyValues(get(values, SUPPLEMENTARY_INFORMATION_PATH, {})),
        },
        witnesses: values?.witnesses || [],
      },
      involved_parties: buildLobSpecificParties(claimType)({ values }),
      contacts: [],
      stored_files_ids: values?.stored_files_ids || [],
      triage: values?.triage || {},
      external_claim_numbers: values?.external_claim_numbers.map(extractExternalClaimNumberForSave) || [],
    };

    // for when the AI decides on claim number
    if (values.claim_number) {
      valuesToSend.claim_number = values.claim_number;
    }

    // for when the AI decides on external claim numbers
    if (values.external_claim_numbers) {
      valuesToSend.external_claim_numbers = values.external_claim_numbers.map((externalClaimNumber) => ({
        external_claim_number: externalClaimNumber.external_claim_number,
        external_contact_id: externalClaimNumber.external_contact?.id,
        description: externalClaimNumber.description,
      }));
    }

    // If the FNOL created from Fnol Ai Inbox, save it with the correct api
    const url = fnolAiRecommendationId
      ? `/api/v1/claims/fnol_ai/inbox/${fnolAiRecommendationId}/fnol`
      : `/api/v1/claims/fnol/${userOrganization.id}`;

    try {
      const res = await axios.post(url, populateContactsInValuesToSend(valuesToSend, policy));
      onSubmitFnol(res.data.id);
    } catch (error) {
      reportAxiosError(error);
    }
  };

  const mapPropsToFormikInitialValues = () => ({
    ...getBasicFnolValues(),
    policy,
    policy_id: policy.id,
    is_manual_policy: !!policy.is_manual,
    sub_organization_id: subOrganizationId ?? '',
    primary_contact_id: '',
    witnesses: [],
    triage: {},
    external_claim_numbers: [],
    stored_files_ids: [],
    verified_supplementary_information: false,
    verified_initial_fault_assessment: false,
    verified_police_and_fire_department: false,
    verified_initial_theft_follow_up: false,
    verified_initial_accident_follow_up: false,
    verified_insured_info: !!policy.is_manual,
    fnol_policyholder_contact_id: policy.insured_contact_id,
    ...incidentDetailsInitialValues(claimValues, policy),
    loss_location: {
      country: policy?.insured_property_location?.country || localeDetails.locale.region,
      description: policy?.insured_property_location?.description || '',
      address1: policy?.insured_property_location?.address1 || '',
      address2: policy?.insured_property_location?.address2 || '',
      city: policy?.insured_property_location?.city || '',
      county: policy?.insured_property_location?.county || '',
      state: policy?.insured_property_location?.state || '',
      zipcode: policy?.insured_property_location?.zipcode || '',
      country_in_uk: policy?.insured_property_location?.country_in_uk || '',
      is_highway: policy?.insured_property_location?.is_highway || false,
    },
    ...getWitnessesIncidentInitialValues(incidentConfiguration),
    ...getLobSpecificPartiesInitialValues(claimType),
    configured_fields_values: getConfiguredFieldsEmptyFormikInitialValues(
      incidentConfiguration.incident_details.configured_fields,
      'incident_details',
      (field) => isFieldSupportedBySubtype(field)
    ),
    ...getFooterInitialValues(incidentConfiguration, claimValues),
    ...fnolDraft?.draft,
  });

  const handleSubTypeChange = (value, formikContext) => {
    setSubType(value);
    setType(formikContext.values.incident_type);
    formikContext.setFieldValue('configured_fields_values', {
      ...getConfiguredFieldsEmptyFormikInitialValues(
        incidentConfiguration.incident_details.configured_fields,
        'incident_details',
        (field) => isFieldSupportedBySubtype(field, formikContext.values.incident_type, value)
      ),
      ...formikContext.values.configured_fields_values,
    });
  };

  return (
    <>
      <Formik
        initialValues={mapPropsToFormikInitialValues()}
        validationSchema={Yup.object().shape({
          ...getPredefinedIncidentDetailsFieldsValidations(incidentConfiguration),
          ...getIncidentDetailsValidationSchema(),
          configured_fields_values: Yup.object().shape({
            ...getConfiguredFieldsValidations(incidentConfiguration, 'incident_details', (field) =>
              isFieldSupportedBySubtype(field, type, subType)
            ),
          }),
          ...getLobSpecificPartiesValidationSchema(claimType, partiesStore),
          ...getFooterValidation(incidentConfiguration),
          ...getWitnessesIncidentValidationSchema(incidentConfiguration),
          verified_insured_info: getVerifiedInsuredInfoValidation(incidentConfiguration),
          ...getSectionsValidationSchema(lob, incidentConfiguration),
        })}
        onSubmit={async (values, formikProps) => {
          const { setSubmitting } = formikProps;
          setSubmitting(true);
          await handleSubmitFnol(values);
          setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <FnolScreen
            {...formikProps}
            policy={policy}
            partiesStore={partiesStore}
            onChangePartiesStore={setPartiesStore}
            onSubTypeChange={handleSubTypeChange}
            onSaveDraft={handleSubmitDraft}
            fnolDraft={fnolDraft}
            subOrganizationId={subOrganizationId}
            incidentConfiguration={incidentConfiguration}
            communicationId={communicationId}
            showCliveWarning={showCliveWarning}
          />
        )}
      </Formik>
    </>
  );
};

FnolScreenFormik.propTypes = {
  onSubmitFnol: PropTypes.func.isRequired,
  claimValues: PropTypes.object,
  onSubmitDraft: PropTypes.func.isRequired,
  fnolDraft: PropTypes.object,
  subOrganizationId: PropTypes.number,
  onSubTypeChange: PropTypes.func,
  communicationId: PropTypes.number,
  fnolAiRecommendationId: PropTypes.string,
  showCliveWarning: PropTypes.bool,
};

export default FnolScreenFormik;
