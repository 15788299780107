import React, { Fragment } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import DialogFooterActions from '~/components/core/DialogFooterActions';
import { useCms } from '~/components/hooks/useCms';
import LoadingDialog from '~/components/LoadingDialog';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';
import { isLocaleRegionIsUs } from '~/Utils/regionUtils';

import CardDialog from '../../../../CardDialog';
import WithConfirm from '../../../../ConfirmModal';
import {
  FsButton,
  FsIconButton,
  PERMISSION_ACTIONS,
  PERMISSION_VERBS,
  PermissionsButtonWrapper,
  RestrictedPermissions,
} from '../../../../core';
import { useIncidentConfiguration } from '../../../../hooks/useIncidentConfiguration';
import { TrashIcon } from '../../../../icons';
import { PreDefinedField, preparePreDefinedFields } from '../../../../IncidentConfiguration/ConfiguredFields';
import { getAdditionalDataValidations } from '../../../../IncidentConfiguration/CustomFieldsContext';
import InvolvedWrapper from '../../../../InvolvedWrapper';
import { usePolicy } from '../../../../PolicyContainer';
import TextFieldFormik from '../../../../TextFieldFormik';

import { useStyles } from '../../../../../assets/styles';
import styles from '../index.module.scss';

const getInvolvedVehiclePreDefinedFields = (incidentConfiguration) => ({
  vehicle: { ...preparePreDefinedFields(incidentConfiguration.policy.covered_vehicle.vehicle) },
  ...preparePreDefinedFields(incidentConfiguration.involved_parties.involved_property),
  ...preparePreDefinedFields(incidentConfiguration.involved_parties.involved_vehicle),
});

const vehicleFields = {
  year: '',
  make: '',
  model: '',
  series: '',
  make_ncic_code: '',
  vin: '',
  plate_number: '',
  vehicle_plate_state: '',
  vehicle_type: '',
  mileage: '',
  ownership_status: '',
  color: '',
  fuel_type: '',
  vehicle_value: '',
  vehicle_cost_new: '',
  engine_type: '',
  engine_capacity: '',
  number_of_seats: '',
};

const vehicleInvolvedFields = {
  covered_vehicle_id: null,
  vehicle: null,
  owner_contact_id: '',
  owner_contact: null,
  owner_contact_full_name: '',
  attorney_contact_id: '',
  attorney_contact_full_name: '',
  attorney_reference_number: '',
  insurer_contact: null,
  insurer_contact_id: '',
  insurer_contact_full_name: '',
  insurer_reference_number: '',
  insurer_policy_number: '',
  is_drivable: '',
  scheduling_inspection_contact_id: '',
  scheduling_inspection_contact: null,
  inspection_location_id: '',
  inspection_location: null,
  damage_description: '',
  note: '',
  damage_areas: [],
  is_vehicle_camera_installed: '',
  is_vehicle_camera_active: '',
  point_of_impact: '',
  damage_level: '',
  is_tow: '',
  tower_contact_id: '',
  tower_contact: '',
  is_car_safe_to_drive: '',
  auto_repair_facility_contact_id: '',
  auto_repair_facility_contact: null,
  appraiser_contact_id: '',
  appraiser_contact: null,
  does_the_vehicle_have_any_modifications: '',
  modification_description: '',
  did_vehicle_have_previous_damage: '',
  previous_damage_description: '',
  previous_damage_level: '',
  vehicle_speed: '',
  child_seats: '',
  is_total_loss: '',
  vehicle_location: {},
};

const getVehicleInvolvedValidationSchema = (incidentConfiguration) => {
  const vehicleConfig = incidentConfiguration.policy.covered_vehicle.vehicle;
  const shouldValidateYear = vehicleConfig?.year?.active && vehicleConfig?.year?.mandatory;
  // values which are '', appear as undefined in Yup test function
  const yearValidator = (year) => year >= 1920 && year <= new Date().getFullYear() + 1;

  return Yup.object().shape({
    ...getAdditionalDataValidations({
      ...preparePreDefinedFields(incidentConfiguration.involved_parties.involved_property),
      ...preparePreDefinedFields(incidentConfiguration.involved_parties.involved_vehicle),
    }),
    vehicle: Yup.object().shape({
      ...getAdditionalDataValidations(preparePreDefinedFields(vehicleConfig)),
      year: shouldValidateYear
        ? Yup.mixed().test('is-valid', 'Valid year is required', yearValidator)
        : Yup.mixed()
            .nullable()
            .test(
              'is-valid',
              'Invalid year',
              (year) => year === '' || year === undefined || year === null || yearValidator(year)
            ),
    }),
  });
};

const isManualVehicle = (values) => values?.covered_vehicle_id === 0; // Unlisted Vehicle

const VehicleInvolvedFragment = ({ buttonsComponent, disabled, isInsured, values, setFieldValue, showOnly }) => {
  const classes = useStyles();
  const { policy } = usePolicy();
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInvolvedVehiclePreDefinedFields(incidentConfiguration);
  const coveredVehicles = policy?.covered_vehicles || [];
  const vehicleId = values?.vehicle?.id; // insured vehicle must be set
  const coveredVehicle = vehicleId && policy?.covered_vehicles?.find((nd) => nd?.vehicle?.id === vehicleId);
  const policyVehicle = isInsured && !isManualVehicle(values) ? coveredVehicle?.vehicle : {};

  if (!showOnly && !policy) {
    return <></>;
  }

  const handleSelectCoveredVehicle = (e) => {
    const coveredVehicleId = e.target.value;
    if (values['covered_vehicle_id'] === coveredVehicleId) {
      return;
    }

    setFieldValue('covered_vehicle_id', coveredVehicleId);

    if (coveredVehicleId === 0) {
      // Unlisted Vehicle
      setFieldValue('vehicle', vehicleFields);
      setFieldValue('owner_contact_id', '');
      setFieldValue('owner_contact_full_name', '');
      setFieldValue('note', '');
      return;
    }

    const coveredVehicle = coveredVehicles.find((cv) => cv.id === coveredVehicleId);

    setFieldValue('vehicle', coveredVehicle.vehicle);
    setFieldValue('owner_contact_id', coveredVehicle.vehicle.vehicle_owner_contact?.id || policy.insured_contact?.id);
    setFieldValue(
      'owner_contact_full_name',
      coveredVehicle.vehicle.vehicle_owner_contact?.full_name || policy.insured_contact?.full_name
    );
  };

  return (
    <Grid container spacing={2}>
      {policy && isInsured && !policy.is_manual && (
        <Grid item xs={12}>
          <TextFieldFormik
            id="covered_vehicle_id"
            label="Vehicle"
            select
            fullWidth
            disabled={disabled}
            className={classes.textField}
            onChange={handleSelectCoveredVehicle}
            showOnly={showOnly}
          >
            {coveredVehicles
              .concat([{ id: 0, vehicle: { display_name: 'Unlisted Vehicle' } }])
              .map((coveredVehicle) => (
                <MenuItem key={coveredVehicle.id} value={coveredVehicle.id}>
                  {coveredVehicle.vehicle.display_name}
                </MenuItem>
              ))}
          </TextFieldFormik>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography display="block" variant="subtitle1">
          Vehicle Details
        </Typography>
      </Grid>
      <VehicleIdentityFragment showOnly={showOnly} isInsured={isInsured} policyVehicle={policyVehicle} />
      {!isInsured && (
        <Grid item xs={12} container spacing={1}>
          <PreDefinedField
            id="insurer_contact_id"
            fields={preDefinedFields}
            inline
            readOnly={showOnly}
            fixedSearchResults
          />
          <PreDefinedField id="insurer_reference_number" fields={preDefinedFields} inline readOnly={showOnly} />
          <PreDefinedField id="insurer_policy_number" fields={preDefinedFields} inline readOnly={showOnly} />
        </Grid>
      )}
      <PreDefinedField
        id="scheduling_inspection_contact_id"
        fields={preDefinedFields}
        inline
        readOnly={showOnly}
        disabled={disabled || showOnly}
        acceptedRoles={
          isInsured
            ? ['insured', 'named_driver', 'spouse', 'family_member', 'body_shop', 'tow_lot', 'other']
            : ['claimant', 'body_shop', 'tow_lot', 'other']
        }
        contactSearchProps={{ newContactRole: 'tow_lot' }}
        fixedSearchResults
        updateOnInit
      />
      <PreDefinedField
        id="vehicle_location"
        fields={preDefinedFields}
        inline
        defaultValues={values?.inspection_location}
        readOnly={showOnly}
        countriesConfigurationKey="vehicle_location"
      />
      <PreDefinedField
        id="attorney_contact_id"
        fields={preDefinedFields}
        inline
        fixedSearchResults
        readOnly={showOnly}
      />
      <PreDefinedField id="attorney_reference_number" fields={preDefinedFields} inline readOnly={showOnly} />
      <Grid item xs={12}>
        <Typography display="block" variant="subtitle1">
          Damage Details
        </Typography>
      </Grid>
      <PreDefinedField id="damage_description" fields={preDefinedFields} inline gridXs={12} readOnly={showOnly} />
      <PreDefinedField id="vehicle_speed" fields={preDefinedFields} inline readOnly={showOnly} />
      <Grid item xs={6} />
      <PreDefinedField id="point_of_impact" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="damage_level" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="damage_areas" fields={preDefinedFields} inline gridXs={12} readOnly={showOnly} />
      <PreDefinedField id="is_car_safe_to_drive" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="is_drivable" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="is_tow" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="tower_contact_id" fields={preDefinedFields} inline gridXs={12} readOnly={showOnly} />
      <PreDefinedField id="is_total_loss" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="auto_repair_facility_contact_id" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="appraiser_contact_id" fields={preDefinedFields} inline readOnly={showOnly} />
      <Grid item xs={12}>
        <Typography display="block" variant="subtitle1">
          Previous Conditions
        </Typography>
      </Grid>
      <PreDefinedField id="is_vehicle_camera_installed" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="is_vehicle_camera_active" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="child_seats" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField
        id="does_the_vehicle_have_any_modifications"
        fields={preDefinedFields}
        inline
        readOnly={showOnly}
      />
      <PreDefinedField id="modification_description" fields={preDefinedFields} inline gridXs={12} readOnly={showOnly} />
      <PreDefinedField id="did_vehicle_have_previous_damage" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField id="previous_damage_level" fields={preDefinedFields} inline readOnly={showOnly} />
      <PreDefinedField
        id="previous_damage_description"
        fields={preDefinedFields}
        inline
        gridXs={12}
        readOnly={showOnly}
      />
      <PreDefinedField id="note" fields={preDefinedFields} inline gridXs={12} readOnly={showOnly} />

      {buttonsComponent && (
        <Grid item xs={12}>
          <div className={classes.buttonsContainer}>{buttonsComponent}</div>
        </Grid>
      )}
    </Grid>
  );
};

VehicleInvolvedFragment.propTypes = {
  isInsured: PropTypes.bool,
  buttonsComponent: PropTypes.node,
  disabled: PropTypes.bool,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  showOnly: PropTypes.bool,
};

const EditVehicleDialog = ({ vehicleInvolved, onCancel, onSaveVehicleDetails, onDeleteVehicle, isInsured }) => {
  const { policy } = usePolicy();
  const { incidentConfiguration } = useIncidentConfiguration();
  const vehicleId = vehicleInvolved?.vehicle?.id; // insured vehicle must be set
  const coveredVehicle = vehicleId && policy?.covered_vehicles?.find((nd) => nd?.vehicle?.id === vehicleId);

  let initialValues = { ...vehicleInvolvedFields, ...vehicleInvolved };
  if (isInsured) {
    if (coveredVehicle) {
      initialValues = { ...initialValues, covered_vehicle_id: coveredVehicle.id };
    } else {
      // Unlisted or rental
      initialValues = { ...initialValues, covered_vehicle_id: 0 };
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getVehicleInvolvedValidationSchema(incidentConfiguration)}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSaveVehicleDetails(values);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        const dialogAction = onDeleteVehicle && (
          <PermissionsButtonWrapper verb={PERMISSION_VERBS.FULL} action={PERMISSION_ACTIONS.CONTACT}>
            <WithConfirm title="Delete Vehicle?" primaryButtonName="Delete" shouldCloseOnPrimary={false}>
              <FsIconButton onClick={onDeleteVehicle} disabled={isSubmitting} icon={TrashIcon} />
            </WithConfirm>
          </PermissionsButtonWrapper>
        );

        return (
          <CardDialog
            isDialog={true}
            title={`Edit ${isInsured ? 'First' : 'Third'} Party Vehicle`}
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
            action={dialogAction}
            footerActions={
              <DialogFooterActions disabled={isSubmitting} onClickPrimary={handleSubmit} onClickSecondary={onCancel} />
            }
          >
            <VehicleInvolvedFragment isInsured={isInsured} {...formikProps} />
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditVehicleDialog.propTypes = {
  vehicleInvolved: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveVehicleDetails: PropTypes.func.isRequired,
  onDeleteVehicle: PropTypes.func,
  isInsured: PropTypes.bool,
};

const AddVehicleDialog = ({ isInsured, onCancel, onSaveVehicleDetails }) => {
  const { policy } = usePolicy();
  const { incidentConfiguration } = useIncidentConfiguration();

  const coveredVehicles = policy.covered_vehicles;

  let initialValues = { ...vehicleInvolvedFields, vehicle: { ...vehicleFields } };
  if (isInsured && coveredVehicles) {
    initialValues = {
      ...initialValues,
      vehicle: { ...vehicleFields, ...coveredVehicles[0].vehicle },
      is_vehicle_camera_installed: coveredVehicles[0].is_dashcam_installed ? 'yes' : '',
      covered_vehicle_id: coveredVehicles[0].id,
      owner_contact: coveredVehicles[0].vehicle.vehicle_owner_contact || policy.insured_contact,
      owner_contact_id: coveredVehicles[0].vehicle.vehicle_owner_contact?.id || policy.insured_contact.id,
      owner_contact_full_name:
        coveredVehicles[0].vehicle.vehicle_owner_contact?.full_name || policy.insured_contact.full_name,
    };
  } else if (isInsured && policy.is_manual) {
    initialValues.covered_vehicle_id = 0;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getVehicleInvolvedValidationSchema(incidentConfiguration)}
      onSubmit={(values, formikProps) => {
        onSaveVehicleDetails(values).catch(() => {
          formikProps.setSubmitting(false);
        });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title={`Set ${isInsured ? 'First' : 'Third'} Party Vehicle`}
            maxWidth="sm"
            onClose={onCancel}
            preventClose={isSubmitting}
            footerActions={
              <DialogFooterActions
                disabled={isSubmitting}
                onClickPrimary={handleSubmit}
                onClickSecondary={onCancel}
                primaryLabel="Add"
              />
            }
          >
            <VehicleInvolvedFragment isInsured={isInsured} {...formikProps} />
          </CardDialog>
        );
      }}
    </Formik>
  );
};

AddVehicleDialog.propTypes = {
  isInsured: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSaveVehicleDetails: PropTypes.func.isRequired,
};

const VehicleDisplayName = (vehicle, isCommercialEnabled) => {
  if (isCommercialEnabled) {
    return [
      vehicle?.year,
      vehicle?.make,
      vehicle?.model,
      vehicle?.series,
      isLocaleRegionIsUs() ? vehicle?.vin?.slice(0, 6) : vehicle?.plate_number,
    ]
      .filter(Boolean)
      .join('-');
  }

  return [vehicle?.year, vehicle?.make, vehicle?.model, vehicle?.series].filter(Boolean).join(' ');
};

const VehicleInvolvedDetails = (props) => {
  const [editVehicleDialogOpen, setEditVehicleDialogOpen] = React.useState(false);
  const [involvedFetched, setInvolvedFetched] = React.useState();
  const [addVehicleDialogOpen, setAddVehicleDialogOpen] = React.useState(false);
  const { userOrganization } = useCms();
  const isCommercialEnabled = isFeatureEnabled(userOrganization, CONFIGURATION_FEATURES_NAMES.COMMERCIAL_POLICIES);

  const filterOutPassengerValues = (values) => {
    let filteredValues = {};
    Object.keys(vehicleInvolvedFields).forEach((k) => (filteredValues[k] = values[k]));
    return filteredValues;
  };

  async function openEditDialog() {
    setEditVehicleDialogOpen(true);
    try {
      const vehicleInvolved = await onFetchVehicleDetails();
      setInvolvedFetched(vehicleInvolved);
    } catch {
      setEditVehicleDialogOpen(false);
    }
  }

  function closeEditDialog() {
    setInvolvedFetched(undefined);
    setEditVehicleDialogOpen(false);
  }

  const {
    classes,
    disabled,
    isInsured,
    vehicleInvolvedHeader,
    onFetchVehicleDetails,
    onDeleteVehicle,
    onSetVehicleDetails,
    onSaveVehicleDetails,
    error,
  } = props;

  return (
    <Fragment>
      <RestrictedPermissions action={PERMISSION_ACTIONS.CONTACT} verb={PERMISSION_VERBS.WRITE}>
        <Grid container spacing={0}>
          <Grid item xs={9}>
            {vehicleInvolvedHeader ? (
              <div className={classes.containerCentered}>
                <InvolvedWrapper
                  onEdit={openEditDialog}
                  disabled={disabled}
                  involved={vehicleInvolvedHeader}
                  involvedType="property"
                >
                  {vehicleInvolvedHeader.display_name ||
                    vehicleInvolvedHeader?.vehicle?.display_name ||
                    VehicleDisplayName(vehicleInvolvedHeader.vehicle, isCommercialEnabled)}
                </InvolvedWrapper>
              </div>
            ) : (
              <Grid item xs={6} className={styles.buttonWrapper}>
                <FsButton
                  className={styles.marginedSetButton}
                  disabled={disabled}
                  onClick={() => setAddVehicleDialogOpen(true)}
                >
                  Set Vehicle
                </FsButton>
              </Grid>
            )}
            {error && <FormHelperText error>{error}</FormHelperText>}
          </Grid>
        </Grid>
      </RestrictedPermissions>
      {addVehicleDialogOpen && (
        <AddVehicleDialog
          isInsured={isInsured}
          onCancel={() => setAddVehicleDialogOpen(false)}
          onSaveVehicleDetails={async (values) => {
            await onSetVehicleDetails(filterOutPassengerValues(values));
            setAddVehicleDialogOpen(false);
          }}
        />
      )}
      {editVehicleDialogOpen &&
        (!involvedFetched ? (
          <LoadingDialog isError={false} track="Edit Vehicle Dialog" />
        ) : (
          <EditVehicleDialog
            classes={classes}
            vehicleInvolved={involvedFetched}
            isInsured={isInsured}
            onSaveVehicleDetails={async (values) => {
              await onSaveVehicleDetails(filterOutPassengerValues(values));
              closeEditDialog();
            }}
            onDeleteVehicle={
              onDeleteVehicle
                ? async () => {
                    await onDeleteVehicle();
                    closeEditDialog();
                  }
                : undefined
            }
            onCancel={closeEditDialog}
          />
        ))}
    </Fragment>
  );
};

VehicleInvolvedDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isInsured: PropTypes.bool,
  vehicleInvolvedHeader: PropTypes.object,
  onFetchVehicleDetails: PropTypes.func.isRequired,
  onSetVehicleDetails: PropTypes.func.isRequired,
  onSaveVehicleDetails: PropTypes.func.isRequired,
  onDeleteVehicle: PropTypes.func,
  error: PropTypes.string,
};

const VehicleIdentityFragment = ({ showOnly, isInsured, policyVehicle }) => {
  const shouldDisableVehicleFields = !isEmpty(policyVehicle);
  const { incidentConfiguration } = useIncidentConfiguration();
  const preDefinedFields = getInvolvedVehiclePreDefinedFields(incidentConfiguration);

  return (
    <>
      <PreDefinedField
        id="vehicle.year"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
        format="####"
        mask="_"
      />
      <PreDefinedField
        id="vehicle.make"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
      />
      <PreDefinedField
        id="vehicle.model"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
      />
      <PreDefinedField
        id="vehicle.series"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
      />
      <PreDefinedField
        id="vehicle.vin"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={4}
      />
      <PreDefinedField
        id="vehicle.plate_number"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={4}
      />
      <PreDefinedField
        id="vehicle.vehicle_plate_state"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={4}
      />
      <PreDefinedField
        id="owner_contact_id"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly || shouldDisableVehicleFields}
        acceptedRoles={
          isInsured ? ['insured', 'named_driver', 'spouse', 'family_member', 'other'] : ['claimant', 'other']
        }
        contactSearchProps={isInsured ? undefined : { newContactRole: 'claimant' }}
        fixedSearchResults
      />
      <PreDefinedField
        id="vehicle.vehicle_type"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
      />
      <PreDefinedField
        id="vehicle.mileage"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
      />
      <PreDefinedField
        id="vehicle.ownership_status"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
      />
      <PreDefinedField
        id="vehicle.color"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
      />
      <PreDefinedField
        id="vehicle.fuel_type"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
      />
      <PreDefinedField
        id="vehicle.vehicle_value"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
      />
      <PreDefinedField
        id="vehicle.vehicle_cost_new"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
      />
      <PreDefinedField
        id="vehicle.engine_type"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
      />
      <PreDefinedField
        id="vehicle.engine_capacity"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
      />
      <PreDefinedField
        id="vehicle.number_of_seats"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={3}
      />
      <PreDefinedField
        id="vehicle.make_ncic_code"
        fields={preDefinedFields}
        inline
        disabled={shouldDisableVehicleFields}
        showOnly={showOnly}
        gridXs={12}
      />
    </>
  );
};

VehicleIdentityFragment.propTypes = {
  showOnly: PropTypes.bool,
  isInsured: PropTypes.bool,
  policyVehicle: PropTypes.object,
};

const NewShowOnlyVehicleDialog = ({ vehicleInvolved, onCancel, isInsured }) => {
  const { policy } = usePolicy();

  let initialValues = { ...vehicleInvolved };
  if (isInsured && policy) {
    const vehicleId = vehicleInvolved.vehicle.id; // insured vehicle must be set
    const coveredVehicle = policy.covered_vehicles.find((nd) => nd.id === vehicleId);
    if (coveredVehicle) {
      initialValues = { ...initialValues, covered_vehicle_id: coveredVehicle.id };
    } else {
      initialValues = { ...initialValues, covered_vehicle_id: 0 };
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={() => {}}>
      {(formikProps) => (
        <CardDialog isDialog={true} title="Vehicle Details" maxWidth="sm" onClose={onCancel} closeOnBackdropClick>
          <VehicleInvolvedFragment isInsured={isInsured} {...formikProps} showOnly />
        </CardDialog>
      )}
    </Formik>
  );
};

NewShowOnlyVehicleDialog.propTypes = {
  vehicleInvolved: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  isInsured: PropTypes.bool,
};

const NewShowOnlyVehicleDetailsInfoDialog = ({ vehicle, open, onClose }) => {
  return (
    <Formik initialValues={{ ...vehicle }} onSubmit={() => {}}>
      {() => {
        return (
          <CardDialog isDialog open={open} title="Vehicle Info" maxWidth="sm" onClose={onClose}>
            <Grid container spacing={1}>
              <Grid container spacing={1}>
                <VehicleIdentityFragment showOnly hideOwner />
              </Grid>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

NewShowOnlyVehicleDetailsInfoDialog.propTypes = {
  vehicle: requiredIf(PropTypes.object, (props) => props.open),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

const NewEditVehicleDetailsInfoDialog = (props) => {
  const { vehicle, open, onClose, OnSaveVehicleDetails } = props;
  const classes = useStyles();
  const { incidentConfiguration } = useIncidentConfiguration();

  return (
    <Formik
      initialValues={vehicle}
      validationSchema={getVehicleInvolvedValidationSchema(incidentConfiguration)}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await OnSaveVehicleDetails(values);
        } catch {
          setSubmitting(false);
        }
      }}
    >
      {({ resetForm, handleSubmit, isSubmitting }) => {
        return (
          <CardDialog
            isDialog
            open={open}
            title="Vehicle Info"
            maxWidth="sm"
            onClose={onClose}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              <VehicleIdentityFragment policyVehicle={vehicle?.vehicle} />
            </Grid>
            <div className={classes.buttonsContainer}>
              <CancelButton
                disabled={isSubmitting}
                onClick={() => {
                  resetForm();
                  onClose();
                }}
              />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

NewEditVehicleDetailsInfoDialog.propTypes = {
  vehicle: requiredIf(PropTypes.object, (props) => props.open),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  OnSaveVehicleDetails: PropTypes.func.isRequired,
};

export {
  AddVehicleDialog,
  NewEditVehicleDetailsInfoDialog,
  NewShowOnlyVehicleDetailsInfoDialog,
  NewShowOnlyVehicleDialog,
  vehicleInvolvedFields,
};

export default VehicleInvolvedDetails;
