import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';

import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import useOrganization from '../OrganizationContext';
import TextFieldFormik from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function ReopenExposureDialog(props) {
  const { claim, exposure, onClose, onUpdate, title } = props;
  const { reopenExposureOptions } = useOrganization();
  const classes = useStyles();
  const handleReopenExposure = async (values) => {
    try {
      await axios.post(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/reopen`, { reason: values.reason });
      onUpdate();
      onClose();
    } catch (error) {
      reportAxiosError(error);
      throw error;
    }
  };

  return (
    <Formik
      initialValues={{ reason: '', extra: '' }}
      validationSchema={Yup.object().shape({
        reason: Yup.string().required('Required'),
      })}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        handleReopenExposure(values)
          .then(() => formikProps.resetForm())
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleReset, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            open={true}
            title={title || 'Reopen Exposure'}
            fullWidth
            maxWidth="sm"
            onClose={() => {
              handleReset();
              onClose();
            }}
            preventClose={isSubmitting}
          >
            <Grid container spacing={1}>
              {claim.is_closed && <Typography>This will result in reopening the claim</Typography>}
              <Grid item xs={9}>
                {reopenExposureOptions.is_reason_free_text ? (
                  <TextFieldFormik id="reason" label="Reason" multiline fullWidth className={classes.textField} />
                ) : (
                  <TextFieldFormik id="reason" label="Reason" className={classes.textField} select fullWidth>
                    {Object.keys(reopenExposureOptions.reason_options_dict[claim.type]).map((reason) => (
                      <MenuItem key={reason} value={reason}>
                        {reopenExposureOptions.reason_options_dict[claim.type][reason]['desc']}
                      </MenuItem>
                    ))}
                  </TextFieldFormik>
                )}
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button disabled={isSubmitting} variant="contained" color="primary" onClick={handleSubmit}>
                Reopen Exposure
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

ReopenExposureDialog.propTypes = {
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default ReopenExposureDialog;
