import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { isReinsuranceManagementClaim } from '~/Utils/ClaimUtils';

import { useClaim } from '../ClaimContainer';
import { useCms } from '../hooks/useCms';
import HoverActionField from '../HoverActionField';

import SumContainer from './ExposuresTable/SumContainer';
import ExposureTooltipedLock from './ExposureTooltipedLock';
import {
  getPayableWithReserveType,
  isExposureWriteDisabled,
  isPayableWithReservePending,
  PENDING_COLOR,
} from './ExposureUtils';
import ReserveChangeRequestContainer from './ReserveChangeRequestContainer';

function ReserveContainer(props) {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const { claim, onClaimUpdate } = useClaim();
  const { user, userOrganization } = useCms();

  const { exposure, payableWithReserve, title } = props;
  const {
    reserve,
    are_pending_reserve_change_requests_exist,
    reserve_change_request_pending,
    are_pending_payment_requests_exist,
    pending_sum,
  } = payableWithReserve;

  const payableType = getPayableWithReserveType(exposure, payableWithReserve);

  function handleClosePopover() {
    setPopoverAnchorEl(null);
  }

  function getReserveToShow() {
    if (are_pending_reserve_change_requests_exist) return reserve_change_request_pending.request_amount;

    if (are_pending_payment_requests_exist && !isReinsuranceManagementClaim(claim, userOrganization)) {
      const reserveAfterApproval = reserve - pending_sum;
      if (reserveAfterApproval < 0) return 0;
      return reserveAfterApproval;
    }

    return reserve;
  }

  const disableChangeReserve =
    are_pending_payment_requests_exist ||
    isExposureWriteDisabled(exposure, user, { enableIfDenied: payableType === 'expenses' });

  if (exposure.is_coverage_issue_exists) {
    return <ExposureTooltipedLock exposure={exposure} />;
  }

  const cardProps = { isPopover: true, open: Boolean(popoverAnchorEl), anchorEl: popoverAnchorEl };

  return (
    <>
      <HoverActionField
        onAction={(e) => setPopoverAnchorEl(e.currentTarget)}
        disabled={disableChangeReserve}
        permanent
        actionAriaLabel={`Set ${payableType} reserve`}
      >
        <SumContainer
          sum={getReserveToShow()}
          color={isPayableWithReservePending(payableWithReserve) ? PENDING_COLOR : undefined}
          fontWeight={exposure?.isGeneralExpenses ? 700 : undefined}
        />
      </HoverActionField>
      <ReserveChangeRequestContainer
        claim={claim}
        onUpdate={onClaimUpdate}
        payableWithReserve={payableWithReserve}
        exposure={exposure}
        reserveChangeRequest={reserve_change_request_pending}
        cardDialogProps={title ? { ...cardProps, title } : cardProps}
        onClose={handleClosePopover}
      />
    </>
  );
}

ReserveContainer.propTypes = {
  exposure: PropTypes.object.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default ReserveContainer;
