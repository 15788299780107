import React from 'react';

import { AiChat } from '~/components/AiChat/AiChat';
import {
  claimPageBarClass,
  headlineClass,
  tableCellClass,
  tableCellFullClass,
} from '~/components/ClaimPage/ClaimPageBar/ClaimPageBarUtils';
import Tooltip from '~/components/core/Atomic/Tooltip';
import { useCms } from '~/components/hooks/useCms';
import { useLob } from '~/components/hooks/useLob';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { getReinsuranceTextIfNeeded, isReinsuranceManagementClaim } from '~/Utils/ClaimUtils';
import cn from '~/Utils/cn';

import { isPolicyNotFound } from '../../../Utils';
import { getLobDescription } from '../../../Utils/lobUtils';
import { useClaim } from '../../ClaimContainer';
import ClaimOwnerWithEdit from '../../ClaimOwnerWithEdit';
import { ClaimActionButtonContainer, PreferredContactContainer, TimeOfLossContainer } from '../../ClaimPage';
import CloseClaimContainer from '../../CloseClaimContainer';
import { ContactEntity } from '../../Contact';
import DateOfReportContainer from '../../Fnol/NewFnolUI/IncidentDetails/DateOfReportContainer';
import { usePolicy } from '../../PolicyContainer';
import { useClaimSearch } from '../../SystemConfiguration/Integrations/ClaimSearch/hooks/useClaimSearch';

import ClaimAlerts from './ClaimAlerts';
import ClaimNumberContainer from './ClaimNumberContainer';
import DividerCell from './DividerCell';

import { useStyles } from '../../../../src/assets/styles';

const NewUIClaimPageBar = () => {
  const classes = useStyles();
  const { lob } = useLob();
  const { userOrganization } = useCms();
  const { lobConfigurationsDict } = useLobConfiguration();
  const { policy } = usePolicy();
  const { claim, onAsyncClaimUpdate } = useClaim();
  const { isEnabled: isClaimSearchEnabled, getClaimSearchScoreRoute } = useClaimSearch();

  const lobName = getLobDescription(lob, lobConfigurationsDict);
  const subOrganization = policy?.sub_organization;
  const isClaimsMade = policy?.is_claims_made || false;

  const isReinsuranceEnabled = isReinsuranceManagementClaim(claim, userOrganization);

  return (
    <div className={cn(claimPageBarClass, 'flex flex-row items-center justify-between')}>
      <div className={cn('flex items-stretch overflow-auto bg-slate-100 text-sm')}>
        <div className={cn(tableCellFullClass)}>
          <div className={headlineClass}>
            <ClaimNumberContainer />
          </div>
        </div>

        <DividerCell />
        <div className={tableCellFullClass}>
          <div className={cn(headlineClass)}>
            {lobName}
            {' Policy'}
          </div>
          <div className="pt-4">{isPolicyNotFound(claim) ? 'Policy Not Found' : policy.policy_number}</div>
        </div>

        {subOrganization && (
          <>
            <DividerCell />
            <div className={tableCellClass}>
              <div className={headlineClass}>Sub Organization</div>
              <div className="pt-4">{subOrganization.name}</div>
            </div>
          </>
        )}

        <DividerCell />
        <div className={tableCellClass}>
          <div className={headlineClass}>
            <span className="pb-4">
              {getReinsuranceTextIfNeeded({ key: 'policyholder', isReinsuranceEnabled, defaultText: 'Policyholder' })}
            </span>
          </div>
          <ContactEntity
            classes={classes}
            contactId={policy.insured_contact_id}
            contactDisplayName={policy.insured_contact_full_name}
          />
        </div>

        <DividerCell />
        <div className={tableCellClass}>
          <div className={headlineClass}>
            {getReinsuranceTextIfNeeded({
              key: 'preferred_contact',
              isReinsuranceEnabled,
              defaultText: 'Preferred Contact',
            })}
          </div>

          {isReinsuranceEnabled ? (
            policy.configured_fields_values?.broker_name || ''
          ) : (
            <PreferredContactContainer claim={claim} onClaimUpdate={onAsyncClaimUpdate} />
          )}
        </div>

        <DividerCell />
        <Tooltip title={`${claim.days_open} days open`} placement="top" arrow>
          <span>
            <div className={tableCellFullClass}>
              <div className={headlineClass}>{isClaimsMade ? 'Date of Report' : 'Time of Loss'}</div>
              {/*hack *: is child, this targets child of child*/}
              <div className="*:*:text-nowrap">
                {isClaimsMade ? (
                  <DateOfReportContainer claim={claim} onUpdate={onAsyncClaimUpdate} withoutLabelOnReadOnly />
                ) : (
                  <TimeOfLossContainer claim={claim} onClaimUpdate={onAsyncClaimUpdate} />
                )}
              </div>
            </div>
          </span>
        </Tooltip>

        <DividerCell />
        <div className={tableCellClass}>
          <div className={headlineClass}>File Owner</div>
          <span>
            <ClaimOwnerWithEdit classes={classes} claim={claim} />
          </span>
        </div>
        {isClaimSearchEnabled ? (
          <ClaimAlerts claimSearchScoreRoute={getClaimSearchScoreRoute(claim.id)} claim={claim} />
        ) : null}
      </div>
      <div className="flex shrink-0 flex-row items-center justify-end gap-20">
        <div className="pl-24">
          <ClaimActionButtonContainer />
        </div>
        <div className="">
          <CloseClaimContainer />
        </div>
      </div>

      <AiChat />
    </div>
  );
};

export default NewUIClaimPageBar;
