import React from 'react';

import { SortableTable } from '~/components/core';
import EmptyState from '~/components/core/EmptyState';
import { PolicySelectionButton } from '~/components/Fnol/FnolAiInbox/PolicySelectionButton/PolicySelectionButton';
import { PolicySelectionCard } from '~/components/Fnol/FnolAiInbox/PolicySelectionCard/PolicySelectionCard';
import { FnolAiInboxStatusChip } from '~/components/Fnol/FnolAiInbox/StatusChip/FnolAiInboxStatusChip';
import type { FnolAiInboxRecord, Policy } from '~/components/Fnol/FnolAiInbox/types';
import { DISABLED_STATUSES_SET } from '~/components/Fnol/FnolAiInbox/utils';
import { AttachmentIcon } from '~/components/icons';
import { isoDateToUs, isoUtcDateTimeToLocal } from '~/DateTimeUtils';

interface FnolAiInboxTableProps {
  rows: FnolAiInboxRecord[];
  onRowClick: (row: FnolAiInboxRecord) => void;
  withClaimColumn?: boolean;
  withPolicyColumn?: boolean;
  selectedItemId?: string | number;
  onPolicyChange: (inboxItemId: string, policy?: Policy) => Promise<void>;
}
const FnolAiInboxTable: React.FC<FnolAiInboxTableProps> = ({
  rows,
  onRowClick,
  withClaimColumn,
  selectedItemId,
  withPolicyColumn,
  onPolicyChange,
}) => {
  const [editPolicyInboxItem, setEditPolicyInboxItem] = React.useState<FnolAiInboxRecord | undefined>();
  const onRowClickHandler = (id: string) => {
    const row = rows.find((r) => r.id === id);
    if (row) onRowClick(row);
  };

  const onPolicySelectionCancel = () => {
    setEditPolicyInboxItem(undefined);
  };

  const onPolicySelection = (inboxItem: FnolAiInboxRecord) => {
    const disableDialog = !inboxItem.policy && DISABLED_STATUSES_SET.has(inboxItem.status);
    if (disableDialog) return;

    setEditPolicyInboxItem(inboxItem);
  };

  const columns = [
    {
      id: 'status',
      label: 'Status',
      specialCell: (row: FnolAiInboxRecord) => <FnolAiInboxStatusChip status={row.status} />,
    },
    { id: 'claim_number', label: 'Claim Number', isHidden: !withClaimColumn },
    { id: 'email_address', label: 'Email Address' },
    {
      id: 'date_received',
      label: 'Date Received',
      specialCell: (row: FnolAiInboxRecord) => row.date_received && isoUtcDateTimeToLocal(row.date_received),
    },
    {
      id: 'date_of_loss',
      label: 'Date Of Loss',
      specialCell: (row: FnolAiInboxRecord) => row.date_of_loss && isoDateToUs(row.date_of_loss),
    },
    {
      id: 'policy',
      label: 'Policy',
      disableSort: true,
      isHidden: !withPolicyColumn,
      specialCell: (row: FnolAiInboxRecord) => (
        <PolicySelectionButton
          policyNumber={row.policy?.policy_number}
          viewOnly={DISABLED_STATUSES_SET.has(row.status)}
          onClick={() => onPolicySelection(row)}
        />
      ),
    },
    { id: 'subject', label: 'Subject', disableSort: true, maxWidthPercentage: 25, overflow: true },
    { id: 'type', label: 'Type' },
    { id: 'sub_type', label: 'Sub Type' },
    { id: 'location', label: 'Location' },
    {
      id: 'attachments',
      label: 'Attachments',
      specialCell: (row: FnolAiInboxRecord) => (row.attachments ? <AttachmentIcon /> : null),
    },
  ];
  return (
    <>
      <SortableTable
        columns={columns}
        rows={rows}
        stickyHeader
        onRowClick={onRowClickHandler}
        selectedRowsIds={[selectedItemId]}
        emptyStateComponent={<EmptyState subtitle="No records found" />}
        defaultOrderColumn={columns.findIndex((column) => column.id === 'date_received')}
        order="desc"
      />
      {!!editPolicyInboxItem && (
        <PolicySelectionCard
          policyNumber={editPolicyInboxItem?.policy?.policy_number}
          inboxItemId={editPolicyInboxItem.id}
          onCancel={onPolicySelectionCancel}
          onSubmit={onPolicyChange}
          viewOnly={DISABLED_STATUSES_SET.has(editPolicyInboxItem.status)}
        />
      )}
    </>
  );
};

export default FnolAiInboxTable;
