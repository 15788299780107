import React from 'react';
import { isEmpty } from 'lodash';

import { ActionPanel } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionPanel';
import { useActionsFeatureFlags } from '~/components/AiChat/SideBarDialog/Actions/useActionsFeatureFlags';
import EmptyStateIcon from '~/components/AiChat/SideBarDialog/icons/EmptyStateIcon';
import type { AiAction } from '~/components/AiChat/types';
import EmptyState from '~/components/core/EmptyState';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';

interface ActionsProps {
  isLoading: boolean;
  actions: AiAction[];
  reloadActions: () => Promise<void>;
}

const sortActionsByCreationDate = (a: AiAction, b: AiAction) => ((a.created_at || '') > (b.created_at || '') ? -1 : 1);

export const Actions: React.FC<ActionsProps> = ({ isLoading, actions = [], reloadActions }) => {
  const { actionFlagEnabledMapping } = useActionsFeatureFlags();

  const filteredActions = React.useMemo(() => {
    if (!actions) return [];

    return actions
      .filter((action: AiAction) => {
        const taskType = action.task_data?.task_type;
        return !action.is_ignored && taskType && actionFlagEnabledMapping[taskType];
      })
      .sort(sortActionsByCreationDate);
  }, [actions, actionFlagEnabledMapping]);

  return (
    <div className="__ACTIONS_ROOT__ mt-16 flex h-[calc(100vh-230px)] flex-col gap-20 overflow-scroll">
      <div className="__ACTIONS_HEADER__ h-min-[100px] gap-100 flex flex-col">
        <h2 className="mx-20" hidden={isEmpty(filteredActions)}>
          Claim Actions
        </h2>
        {isLoading && <SkeletonTable rowsCount={5} columnsCount={1} />}
        {!isLoading && isEmpty(filteredActions) && (
          <div className="flex h-[calc(100vh-650px)] w-full flex-col items-center justify-center px-[100px]">
            <EmptyState
              className="text-center"
              title="No recommended actions yet"
              illustration={<EmptyStateIcon height={200} width={200} />}
            />
          </div>
        )}
        {!isLoading && !isEmpty(filteredActions) && (
          <ul className="my-0 ml-20 flex flex-col gap-20 p-0">
            {filteredActions?.map((action: AiAction) => (
              <ActionPanel action={action} key={action.action_id} onActionUpdate={reloadActions} />
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
