import React, { useState } from 'react';

import ClaimLink from '~/components/ClaimLink';
import { getClaimNotificationIcon } from '~/components/ClaimNotificationsCard/ClaimNotificationCardUtils';
import type { ClaimNotificationModel } from '~/components/ClaimNotificationsCard/types';
import Text from '~/components/core/TextComponents/Text';
import { ExposureDetailedTitle } from '~/components/exposures/ExposureDetailedTitle';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import ThreeDotsIcon from '~/components/icons/ThreeDotsIcon';
import DismissButton from '~/components/Notifications/DismissButton';
import NotificationDueDate from '~/components/Notifications/NotificationDueDate';
import PriorityEditableChip from '~/components/Notifications/PriorityEditableChip';
import { changeClaimNotificationState } from '~/components/Notifications/utils';
import OverflowTextWithToolTip from '~/components/OverflowTextWithToolTip';
import type { ExposureModel } from '~/components/types/exposure-types';
import { getLobIcon } from '~/Utils/lobUtils';

interface NotificationDueDateWrapperProps {
  notification: ClaimNotificationModel;
  isUpdating: boolean;
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  onUpdateNotification: () => Promise<void> | undefined;
}

const NotificationDueDateWrapper: React.FC<NotificationDueDateWrapperProps> = ({
  notification,
  isUpdating,
  setIsUpdating,
  onUpdateNotification,
}) => (
  <div className="h-24 w-full select-text rounded-full border-1 border-solid border-blue-200 bg-white px-8 pt-2">
    <NotificationDueDate
      notification={notification}
      disabled={isUpdating}
      setIsUpdating={setIsUpdating}
      onUpdate={onUpdateNotification}
    />
  </div>
);

interface NotificationHeaderProps {
  notification: ClaimNotificationModel;
  onUpdateNotification: () => Promise<void> | undefined;
  onClick: () => void;
}

const NotificationHeader: React.FC<NotificationHeaderProps> = ({ notification, onUpdateNotification, onClick }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const handleDismiss = async () => {
    await changeClaimNotificationState(
      notification,
      () => setIsUpdating(true),
      () => setIsUpdating(false),
      onUpdateNotification
    );
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-8">
        <DismissButton
          notification={notification}
          onClick={handleDismiss}
          tooltipTextDismiss="Dismiss and close"
          tooltipTextUndismiss="Undismiss and close"
          disabled={isUpdating}
          noLeftPadding
        />

        <PriorityEditableChip
          notification={notification}
          setIsUpdating={setIsUpdating}
          onUpdate={onUpdateNotification}
          disabled={isUpdating}
        />

        <NotificationDueDateWrapper
          notification={notification}
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
          onUpdateNotification={onUpdateNotification}
        />
      </div>

      <ThreeDotsIcon className="px-6 cursor-pointer hover:stroke-teal-700" onClick={onClick} />
    </div>
  );
};

interface NotificationTitleProps {
  notification: ClaimNotificationModel;
}

const NotificationTitle: React.FC<NotificationTitleProps> = ({ notification }) => {
  return (
    <div className="flex items-center justify-start space-x-4">
      <div className="mr-4 rounded-[4px] bg-blue-100 fill-blue-600 p-4">
        {getClaimNotificationIcon(notification, undefined, 16)}
      </div>
      <OverflowTextWithToolTip maxWidth={220}>{notification?.title || ''}</OverflowTextWithToolTip>
    </div>
  );
};

interface NotificationClaimInfoProps {
  notification: ClaimNotificationModel;
}

const NotificationClaimInfo: React.FC<NotificationClaimInfoProps> = ({ notification }) => {
  const { lobConfigurationsDict } = useLobConfiguration();

  if (!notification?.claim_id) {
    return null;
  }

  return (
    <div className="flex items-center justify-start space-x-4">
      <div className="flex flex-nowrap items-center pl-4">
        {notification?.claim_lob &&
          getLobIcon({
            lob: notification.claim_lob,
            lobConfigurationsDict,
          })}
        <div className="pl-8">
          <ClaimLink claimId={notification.claim_id} linkText={notification.claim_id_display || ''} />
        </div>
      </div>
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR} className="px-12">
        <OverflowTextWithToolTip maxWidth={100}>{notification.recipient_label}</OverflowTextWithToolTip>
      </Text>
    </div>
  );
};

interface NotificationExposureInfoProps {
  exposureId?: number;
  exposuresDict: { [id: number]: ExposureModel };
}

const NotificationExposureInfo: React.FC<NotificationExposureInfoProps> = ({ exposureId, exposuresDict }) => {
  if (!exposureId || !exposuresDict[exposureId]) {
    return null;
  }

  return (
    <div className="flex items-center justify-start space-x-4">
      <ExposureDetailedTitle
        exposure={exposuresDict[exposureId]}
        separator={<div className="px-8 text-slate-600">|</div>}
        withInfoIcon={false}
        withDescOverflow
      />
    </div>
  );
};

export interface NotificationKanbanCardProps {
  notification: ClaimNotificationModel;
  onUpdateNotification: () => Promise<void> | undefined;
  exposuresDict: { [id: number]: ExposureModel };
  onShowNotification: (notificationId: number) => void;
}

const NotificationKanbanCard: React.FC<NotificationKanbanCardProps> = ({
  notification,
  onUpdateNotification,
  exposuresDict,
  onShowNotification,
}) => {
  return (
    <div className="mb-16 flex max-w-sm flex-col space-y-12 rounded-md border-1 border-solid border-slate-500 bg-white p-16 shadow-sm">
      <NotificationHeader
        notification={notification}
        onUpdateNotification={onUpdateNotification}
        onClick={() => onShowNotification(notification.id)}
      />

      <NotificationTitle notification={notification} />

      <NotificationClaimInfo notification={notification} />

      <NotificationExposureInfo exposureId={notification.exposure_id} exposuresDict={exposuresDict} />
    </div>
  );
};

export default NotificationKanbanCard;
