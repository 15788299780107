import React, { useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import ExternalClaimNumberFragment from '~/components/ClaimPage/ExternalClaimNumber/ExternalClaimNumberFragment';
import ExternalClaimNumbersTable from '~/components/ClaimPage/ExternalClaimNumber/ExternalClaimNumbersTable';
import Button from '~/components/core/Atomic/Buttons/Button';
import BackTextButton from '~/components/core/Buttons/BackTextButton';
import { reportAxiosError } from '~/Utils';

const MAIN_TITLE = 'External Claim Numbers';
const ADD_TITLE = 'Add External Claim Number';
const EDIT_TITLE = 'Edit External Claim Number';

const generateInitialValues = () => ({
  id: uuidv4(),
  external_claim_number: '',
  external_contact: null,
  external_contact_id: null,
  description: '',
});

const ExternalClaimNumber = () => {
  const { setFieldValue, values } = useFormikContext();
  const hasWritePermission = true; // todo: do we need it ?
  const externalClaimNumbers = values.external_claim_numbers;

  const isEmptyExternalClaimNumbers = isEmpty(externalClaimNumbers);

  const [isAddMode, setIsAddMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  useEffect(() => {
    if (isEmptyExternalClaimNumbers && !isAddMode && !isEditMode) {
      setIsAddMode(true);
    }
  }, [isAddMode, isEditMode, isEmptyExternalClaimNumbers]);

  const handleDelete = async (externalClaimNumberId) => {
    try {
      const result = [...externalClaimNumbers.filter((item) => item.id !== externalClaimNumberId)];
      setFieldValue('external_claim_numbers', result);
      setCurrentRecord(null);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleEdit = async (externalClaimNumberId, values) => {
    try {
      const result = [...externalClaimNumbers.filter((item) => item.id !== externalClaimNumberId), values];

      setFieldValue('external_claim_numbers', result);

      setIsEditMode(false);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const handleAdd = async (values) => {
    try {
      const result = [...externalClaimNumbers, values];
      setFieldValue('external_claim_numbers', result);
      setIsAddMode(false);
    } catch (error) {
      await reportAxiosError(error);
    }
  };

  const getDialogTitle = () => {
    if (isAddMode && hasWritePermission) {
      return (
        <>
          {!isEmptyExternalClaimNumbers && (
            <BackTextButton title={`Back to ${MAIN_TITLE}`} onClick={() => setIsAddMode(false)} upperCase={false} />
          )}
          {ADD_TITLE}
        </>
      );
    }

    if (isEditMode && hasWritePermission) {
      return (
        <>
          <BackTextButton
            title={`Back to ${MAIN_TITLE}`}
            onClick={() => {
              setIsEditMode(false);
              setCurrentRecord(null);
            }}
            upperCase={false}
          />
          {EDIT_TITLE}
        </>
      );
    }

    return <>{MAIN_TITLE}</>;
  };

  const onClickPrimary = async (values) => {
    if (isAddMode) {
      await handleAdd(values);
    } else if (isEditMode) {
      await handleEdit(values.id, values);
    }
  };

  const onCancel = () => {
    setIsAddMode(false);
    setIsEditMode(false);
    setCurrentRecord(null);
  };

  const initialValues = currentRecord ? currentRecord : generateInitialValues();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        external_claim_number: Yup.string().test(
          'not-empty-string',
          'Required',
          (external_claim_number) => external_claim_number?.trim()?.length > 0
        ),
        external_contact_id: Yup.number().nullable(),
        description: Yup.string().nullable(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        await onClickPrimary(values);
        return formikProps.resetForm();
      }}
    >
      {(formikProps) => {
        const { resetForm, handleSubmit, isValid } = formikProps;

        return (
          <CardDialog title={getDialogTitle()} maxWidth="md" fullWidth>
            <div className="flex flex-col gap-12">
              {((!isAddMode && !isEditMode) || !hasWritePermission) && (
                <ExternalClaimNumbersTable
                  rows={externalClaimNumbers}
                  onDeleteClick={handleDelete}
                  onAddClick={() => {
                    resetForm();
                    setCurrentRecord(null);
                    setIsAddMode(true);
                  }}
                  onEditClick={(record) => {
                    resetForm();
                    setIsEditMode(true);
                    setCurrentRecord(record);
                  }}
                />
              )}
              {hasWritePermission && (isAddMode || isEditMode) && <ExternalClaimNumberFragment />}
              {(isAddMode || isEditMode) && (
                <div className="flex justify-end gap-8">
                  <Button variant="outlined" onClick={onCancel}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleSubmit} disabled={!isValid}>
                    Add
                  </Button>
                </div>
              )}
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

export default ExternalClaimNumber;
