import _ from 'lodash';
import moment from 'moment/moment';

import { useClaim } from '~/components/ClaimContainer';
import useDataFetcher from '~/components/useDataFetcher';
import { serverDateTimeToLocalMoment } from '~/DateTimeUtils';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled } from '~/Utils';

export const useReplyEmailText = (communication, userOrganization) => {
  const { claim } = useClaim();

  const isEmailConfigurationEnabled = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.EMAIL_CONFIGURATION
  );

  const getSignatureUrl = isEmailConfigurationEnabled
    ? `/api/v1/email_configuration/organizations/${userOrganization.id}/email_signature_and_title`
    : `/api/v1/organizations/${userOrganization.id}/email_signature`;
  const {
    isLoading: isLoadingEmailSignature,
    isError: isErrorEmailSignature,
    data: userSignatureHtmlData,
  } = useDataFetcher(getSignatureUrl, {
    params: { claim_id: claim?.id, referred_communication_id: communication.id },
  });

  const userSignatureHtml =
    !isLoadingEmailSignature && !isErrorEmailSignature && userSignatureHtmlData
      ? userSignatureHtmlData.user_email_signature_html
      : '';

  const emailHtmlBody = communication.body_html
    ? communication.body_html
    : '<div>' + _.escape(communication.body_text).replace(/(\r\n|\n)/g, '<br/>') + '</div>';

  let origEmailSender = undefined;
  if (communication.direction === 'Outgoing') {
    origEmailSender = `${communication.adjuster} <${communication.adjuster_email}>`;
  } else {
    //Incoming
    origEmailSender = communication.contact // can be undefined in case of reply to unknown email
      ? `${communication.contact.full_name} <${communication.contact_email.email}>`
      : `${communication.specific_identifier} <${communication.specific_identifier}>`;
  }

  const emailText =
    `<br>${userSignatureHtml}<br/>` +
    '<span>------------------------------------------------------------------------</span><br/>' +
    `On ${moment(serverDateTimeToLocalMoment(communication.datetime)).format(
      'dddd, MMMM D, YYYY [at] h:mm:ss A'
    )} ${_.escape(origEmailSender)} wrote:` +
    `<br/><br/>${emailHtmlBody}`;

  return { emailText, isLoadingEmailSignature, isErrorEmailSignature };
};
