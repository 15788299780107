import React from 'react';
import axios from 'axios';

import { useClaim } from '~/components/ClaimContainer';
import MenuItem from '~/components/core/Atomic/MenuItem';
import { reportAxiosError } from '~/Utils';

export interface Exposure {
  id: string;
  exposure_extra: Record<string, string>;
}

interface ExposureMarkAsAdvisedMenuItemProps {
  exposure: Exposure;
  onUpdate: () => void;
}

export const ExposureMarkAsAdvisedMenuItem: React.FC<ExposureMarkAsAdvisedMenuItemProps> = ({ exposure, onUpdate }) => {
  const { claim } = useClaim();
  const isMarkedAsAdvised = !!exposure.exposure_extra?.mark_as_advised;

  const setExposureStatus = React.useCallback(
    async (markAsAdvised: boolean) => {
      try {
        const command = markAsAdvised ? 'mark_as_advised' : 'mark_as_not_advised';
        await axios.put(`/api/v1/claims/${claim.id}/exposures/${exposure.id}/${command}`);
        onUpdate && onUpdate();
      } catch (error) {
        reportAxiosError(error);
      }
    },
    [claim, exposure.id, onUpdate]
  );

  return (
    <MenuItem
      onClick={() => {
        setExposureStatus(!isMarkedAsAdvised);
      }}
    >
      {isMarkedAsAdvised ? 'Mark as Not Advised' : 'Mark as Advised'}
    </MenuItem>
  );
};
