import React, { useEffect, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import MenuItem from '~/components/core/Atomic/MenuItem';
import Typography from '~/components/core/Atomic/Typography';

import { isoUtcDateTimeToLocal } from '../../DateTimeUtils';
import { isInshurClaim, isQoverClaim } from '../../Utils';
import { findInvolvedProperty, getFirstPartyVehicleFromClaim } from '../../Utils/ClaimUtils';
import CardDialog from '../CardDialog';
import CheckboxFormik from '../CheckboxFormik';
import { useClaim } from '../ClaimContainer';
import { getAllOrganizationWideRoles } from '../communications/ContactUtils';
import ContactTextFieldFormik from '../ContactTextFieldFormik';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import { getMoiOptionsForClaimType } from '../exposures/moi/MoiUtils';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import RadioButtonFormik from '../RadioButtonFormik';
import { ShowOnlyTextField, TextFieldFormik } from '../TextFieldFormik';

import { FormikEstimationDocuments } from './EstimationDocumentsContainer';

import { useStyles } from '../../assets/styles';

const moiFields = {
  method: '',
  manual_vendor_id: '',
  manual_vendor: '',
  exposure_ids: [],
  assignment_type: '',
};

const moiVendors = {
  winn: 'winn',
  agl: 'agl',
};

const getMoiValidationFields = (moiOptions) => ({
  method: Yup.string().required('Required'),
  manual_vendor_id: Yup.number().when('method', {
    is: (method) => moiOptions[method]?.required_vendor,
    then: Yup.number().required('Required'),
  }),
  assignment_type: Yup.string().when(['method', 'manual_vendor'], {
    is: (method, manual_vendor) =>
      moiOptions[method]?.assignment_type &&
      manual_vendor &&
      !(
        manual_vendor.full_name.toLowerCase().startsWith('medlogix') ||
        manual_vendor.full_name.toLowerCase().startsWith('claimsolution')
      ),
    then: Yup.string().required('Required'),
  }),
  exposure_ids: Yup.array(),
});

function moiMethodToString(moi, claim, moiOptions) {
  const moiOptionsForClaimType = getMoiOptionsForClaimType(claim.type, moiOptions);
  if (moi?.method && moiOptionsForClaimType[moi.method].required_vendor) {
    return moi.contact.full_name;
  }
  return moi?.method ? moiOptionsForClaimType[moi.method].title : '';
}

const doesMoiMethodRequiresEmail = (method, moiOptions, claimType) => {
  const moiOptionsForClaimType = getMoiOptionsForClaimType(claimType, moiOptions);
  return moiOptionsForClaimType[method].required_vendor && !moiOptionsForClaimType[method].record_only;
};

const isVendorWithNoNeedForEmail = (vendor, claim) => {
  if (
    isInshurClaim(claim) &&
    (vendor.full_name.toLowerCase().startsWith('medlogix') ||
      vendor.full_name.toLowerCase().startsWith('claimsolution'))
  ) {
    return true;
  } else {
    return false;
  }
};

const getJobStatusOrNull = (exposure, vendor) => {
  try {
    //This logic is marshmallow specific and does not support the new moi experience
    return JSON.parse(exposure.methods_of_inspection?.at(-1) ? getJobStatus(exposure, vendor) : null);
  } catch (error) {
    return null;
  }
};

const getJobStatus = (exposure, vendor) => {
  switch (vendor) {
    //This logic is marshmallow specific and does not support the new moi experience
    case moiVendors.winn:
      return exposure.methods_of_inspection?.at(-1)?.winn_job_update;
    case moiVendors.agl:
      return exposure.methods_of_inspection?.at(-1)?.agl_job_update;
    default:
      return null;
  }
};

const dynamicRolesList = {
  all_wide_roles: getAllOrganizationWideRoles,
};

const specificRequirementForMethod = {
  involved_vehicle_exists: (exposure) =>
    exposure.involved_property && exposure.involved_property.type === 'involved_vehicle',
};

const allRequirementsAreMet = (requirements, exposure) => {
  const nonMetRequirement = requirements.find((requirement) =>
    specificRequirementForMethod[requirement] ? !specificRequirementForMethod[requirement](exposure) : false
  );
  return !nonMetRequirement;
};

const coverageIsFit = (specificCoverages, exposure) =>
  !specificCoverages || specificCoverages.find((coverage) => coverage === exposure.coverage_type);
const requirementsAreMet = (specialRequirements, exposure) =>
  !specialRequirements || allRequirementsAreMet(specialRequirements, exposure);

function MoiCard(props) {
  const { cardDialogProps, moi, exposure, exposuresToSuggest, onCancel, onSelectMoi, onSelectNewMoi, readOnly } = props;
  const classes = useStyles();
  const { claim } = useClaim();
  const { isLoading, isError, organizationContactRolesDict, moiOptions, moiOptionsOrder } = useOrganization();
  const [openFieldAdjusterDialog, setOpenFieldAdjusterDialog] = useState(false);
  const [openDeskInspectionAssignmentDialog, setOpenDeskInspectionAssignmentDialog] = useState(false);
  const [openAglStatusDialog, setOpenAglStatusDialog] = useState(false);
  const [openWinnStatusDialog, setOpenWinnStatusDialog] = useState(false);

  const MOIOptions = getMoiOptionsForClaimType(claim.type, moiOptions);

  const setDialog = {
    field_adjuster: setOpenFieldAdjusterDialog,
    desk_inspection: setOpenDeskInspectionAssignmentDialog,
  };

  function existingMoiToFormikFields(moi) {
    let fields = {
      ...moiFields,
      method: moi?.method,
      exposure_ids: claim.exposures
        .filter((exp) => moi.exposure_ids.includes(exp.id) && exp.id !== exposure.id)
        .map((exp) => exp.id),
    };

    if (moi.method && MOIOptions[moi.method].required_vendor) {
      fields = { ...fields, manual_vendor_id: moi.contact.id, manual_vendor_full_name: moi.contact.full_name };
    }

    if (moi.method && MOIOptions[moi.method].assignment_type) {
      fields = { ...fields, assignment_type: moi.assignment_type };
    }

    return fields;
  }

  function getAcceptedRoles(moiOption) {
    if (moiOption.contact_roles_special) {
      return dynamicRolesList[moiOption.contact_roles_special](organizationContactRolesDict);
    } else {
      return moiOption.contact_roles;
    }
  }

  let initialValues = {};

  if (moi) {
    initialValues = existingMoiToFormikFields(moi);
  } else {
    initialValues = { ...moiFields };
    if (isQoverClaim(claim)) {
      initialValues.method = 'self_service';
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape(getMoiValidationFields(MOIOptions))}
      enableReinitialize
      onSubmit={(values, formikProps) => {
        onSelectMoi(values)
          .then(() => {
            formikProps.resetForm();
          })
          .catch(() => {
            formikProps.setSubmitting(false);
          });
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit, values, setFieldValue } = formikProps;
        let additionalExposure = exposuresToSuggest;
        let shouldEmailBeSent = false;
        let wouldEmailBeSent = false;
        if (moi) {
          additionalExposure = claim.exposures.filter((exp) => values['exposure_ids'].includes(exp.id));
        } else {
          shouldEmailBeSent =
            values.method &&
            doesMoiMethodRequiresEmail(values.method, moiOptions, claim.type) &&
            values.manual_vendor &&
            !isVendorWithNoNeedForEmail(values.manual_vendor, claim);
          wouldEmailBeSent = shouldEmailBeSent && values.manual_vendor.emails && values.manual_vendor.emails.length > 0;
        }

        return (
          <>
            <CardDialog
              title="MOI"
              width="500px"
              fullWidth
              {...cardDialogProps}
              onClose={() => {
                formikProps.handleReset();
                onCancel();
              }}
            >
              {isLoading ? (
                <LoadingIndicator isError={isError} />
              ) : (
                <>
                  <MoiOptionsInner
                    MOIOptions={MOIOptions}
                    exposure={exposure}
                    setDialog={setDialog}
                    getAcceptedRoles={getAcceptedRoles}
                    moi={moi}
                    moiOptionsOrder={moiOptionsOrder}
                  />
                  <div>
                    {additionalExposure.map((exposureToSuggest) => (
                      <FormGroup row key={exposureToSuggest.id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values['exposure_ids'].includes(exposureToSuggest.id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setFieldValue('exposure_ids', [...values['exposure_ids'], exposureToSuggest.id]);
                                } else {
                                  setFieldValue(
                                    'exposure_ids',
                                    values['exposure_ids'].filter((exposureId) => exposureId !== exposureToSuggest.id)
                                  );
                                }
                              }}
                              disabled={!!moi}
                            />
                          }
                          label={`Apply to coverage "${exposureToSuggest.coverage_type_desc}" too`}
                        />
                      </FormGroup>
                    ))}
                  </div>
                  {!readOnly &&
                    (!moi ? (
                      <>
                        <Typography
                          display="block"
                          variant="caption"
                          align="right"
                          style={{ color: '#e65100', visibility: shouldEmailBeSent ? 'visible' : 'hidden' }}
                        >
                          {wouldEmailBeSent
                            ? 'Assignment email will be presented for review'
                            : 'To generate an Assignment email - add an email address to this contact'}
                        </Typography>
                        <div className={classes.buttonsContainer}>
                          <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                            Select MOI
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div className={classes.buttonsContainer}>
                        {values['method'] === moiVendors.agl && getJobStatusOrNull(exposure, moiVendors.agl) && (
                          <Button variant="contained" onClick={() => setOpenAglStatusDialog(true)}>
                            AGL Status
                          </Button>
                        )}
                        {values['method'] === moiVendors.winn && getJobStatusOrNull(exposure, moiVendors.winn) && (
                          <Button variant="contained" onClick={() => setOpenWinnStatusDialog(true)}>
                            Winn Status
                          </Button>
                        )}
                        &nbsp;
                        <Button variant="contained" color="primary" disabled={isSubmitting} onClick={onSelectNewMoi}>
                          Select another MOI
                        </Button>
                      </div>
                    ))}
                </>
              )}
            </CardDialog>
            <FieldAdjusterAssignmentDialog
              open={openFieldAdjusterDialog}
              claim={claim}
              exposure={exposure}
              onClose={() => setOpenFieldAdjusterDialog(false)}
              onSubmitFieldAdjuster={async (values) => {
                await onSelectMoi({ ...values, method: 'field_adjuster', exposure_ids: [] });
                setOpenFieldAdjusterDialog(false);
              }}
            />
            <DeskInspectionAssignmentDialog
              open={openDeskInspectionAssignmentDialog}
              claim={claim}
              exposure={exposure}
              onClose={() => setOpenDeskInspectionAssignmentDialog(false)}
              onSubmitDeskInspection={async (values) => {
                await onSelectMoi({ ...values, method: 'desk_inspection', exposure_ids: [] });
                setOpenDeskInspectionAssignmentDialog(false);
              }}
            />
            <VendorStatusDialog
              open={openAglStatusDialog}
              exposure={exposure}
              onClose={() => setOpenAglStatusDialog(false)}
              vendorName={moiVendors.agl}
              fieldToViewMapping={aglFieldToViewMapping}
            />
            <VendorStatusDialog
              open={openWinnStatusDialog}
              exposure={exposure}
              onClose={() => setOpenWinnStatusDialog(false)}
              vendorName={moiVendors.winn}
              fieldToViewMapping={winnFieldToViewMapping}
            />
          </>
        );
      }}
    </Formik>
  );
}

MoiCard.propTypes = {
  cardDialogProps: PropTypes.object.isRequired,
  moi: PropTypes.object,
  exposure: PropTypes.object.isRequired,
  exposuresToSuggest: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSelectMoi: requiredIf(PropTypes.func, (props) => !props.moi),
  onSelectNewMoi: PropTypes.func,
  readOnly: PropTypes.bool,
};

function MoiOptionsInner(props) {
  const { MOIOptions, exposure, setDialog, getAcceptedRoles, moi, moiOptionsOrder } = props;
  const { values, setFieldValue } = useFormikContext();
  const { claim } = useClaim();

  useEffect(() => {
    setFieldValue('manual_vendor', '');
    setFieldValue('manual_vendor_id', '');
    setFieldValue('manual_vendor_full_name', '');
  }, [values.method, setFieldValue]);

  const moiKeys = moiOptionsOrder ? moiOptionsOrder : Object.keys(MOIOptions);

  return (
    <Grid container alignItems="center" spacing={1}>
      {moiKeys
        .filter(
          (methodValue) =>
            !MOIOptions[methodValue].is_removed &&
            coverageIsFit(MOIOptions[methodValue].specific_coverages, exposure) &&
            requirementsAreMet(MOIOptions[methodValue].special_requirement_for_method, exposure)
        )
        .map((methodValue) => {
          const currOption = MOIOptions[methodValue];
          const assignmentTypesOptions = currOption.assignment_type?.options;
          const possibleAssignmentTypes =
            (assignmentTypesOptions &&
              Object.keys(assignmentTypesOptions).filter(
                (assignment_type) =>
                  !assignmentTypesOptions[assignment_type].coverages ||
                  assignmentTypesOptions[assignment_type].coverages.includes(exposure.coverage_type)
              )) ||
            [];
          return (
            <React.Fragment key={methodValue}>
              <Grid item xs={currOption.required_vendor ? 6 : 12}>
                <RadioButtonFormik
                  id="method"
                  label={currOption.title}
                  optionValue={methodValue}
                  disabled={!!moi}
                  onChange={
                    currOption.should_open_dialog_on_change
                      ? () => {
                          setDialog[methodValue](true);
                        }
                      : undefined
                  }
                />
              </Grid>
              {currOption.required_vendor && !(values['method'] === methodValue) && <Grid item xs={6} />}
              {currOption.required_vendor && values['method'] === methodValue && (
                <Grid item xs={6}>
                  <ContactTextFieldFormik
                    key={methodValue}
                    id="manual_vendor"
                    label={currOption.contact_select_title}
                    disabled={!!moi}
                    acceptedRoles={getAcceptedRoles(currOption)}
                    fixedSearchResults
                    fullWidth
                  />
                </Grid>
              )}
              {currOption.assignment_type &&
                ((values['method'] === methodValue &&
                  values['manual_vendor'] &&
                  !isVendorWithNoNeedForEmail(values.manual_vendor, claim)) ||
                  (moi && moi.assignment_type)) && (
                  <>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                      <TextFieldFormik
                        id="assignment_type"
                        label="Assignment Type"
                        style={{ marginTop: '8px' }}
                        disabled={!!moi}
                        fullWidth
                        select
                      >
                        {possibleAssignmentTypes.map((option) => (
                          <MenuItem key={option} value={option}>
                            {currOption.assignment_type.options[option].desc}
                          </MenuItem>
                        ))}
                      </TextFieldFormik>
                    </Grid>
                  </>
                )}
            </React.Fragment>
          );
        })}
      <div>
        <ErrorHelperTextFormik id="method" />
      </div>
    </Grid>
  );
}

MoiOptionsInner.propTypes = {
  MOIOptions: PropTypes.object.isRequired,
  moi: PropTypes.object,
  exposure: PropTypes.object.isRequired,
  setDialog: PropTypes.object.isRequired,
  getAcceptedRoles: PropTypes.func.isRequired,
  moiOptionsOrder: PropTypes.arrayOf(PropTypes.string),
};

function FieldAdjusterAssignmentDialog(props) {
  const { claim, open, exposure, onClose, onSubmitFieldAdjuster } = props;
  const classes = useStyles();
  if (!open) {
    return <></>;
  }

  const involvedVehicle = exposure.involved_property && findInvolvedProperty(claim, exposure.involved_property);
  const isSchedulingInspectionContactExists = !!involvedVehicle.scheduling_inspection_contact_id;

  const firstPartyVehicle = getFirstPartyVehicleFromClaim(claim);
  const isVehicleInvolvedFirstParty =
    firstPartyVehicle && getFirstPartyVehicleFromClaim(claim).involved_vehicle.id === involvedVehicle.id;

  return (
    <Formik
      initialValues={{
        scheduling_inspection_contact_id: '',
        send_assignment_copy: true,
        instructions: '',
      }}
      validationSchema={Yup.object().shape({
        scheduling_inspection_contact_id: isSchedulingInspectionContactExists
          ? undefined
          : Yup.number().required('Required'),
        instructions: Yup.string().required('Required'),
        send_assignment_copy: Yup.boolean().required('Required'),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmitFieldAdjuster(values);
          formikProps.resetForm();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title="Field Adjuster Assignment"
            maxWidth="sm"
            fullWidth
            onClose={onClose}
          >
            <Grid container spacing={1}>
              {!isSchedulingInspectionContactExists && (
                <>
                  <Grid item xs={12}>
                    <ContactTextFieldFormik
                      id="scheduling_inspection_contact"
                      label="Contact for Scheduling Inspection"
                      acceptedRoles={
                        isVehicleInvolvedFirstParty
                          ? ['insured', 'named_driver', 'spouse', 'family_member', 'body_shop', 'tow_lot', 'other']
                          : ['claimant', 'body_shop', 'tow_lot', 'other']
                      }
                      contactSearchProps={{ newContactRole: 'tow_lot' }}
                      className={classes.textField}
                    />
                  </Grid>
                  <Typography display="block" variant="body1" style={{ color: '#e65100' }}>
                    <strong>NOTICE:</strong> No scheduling contact was chosen
                  </Typography>
                </>
              )}
              <Grid item xs={12}>
                <TextFieldFormik
                  id="instructions"
                  label="Instructions"
                  className={classes.textField}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxFormik id="send_assignment_copy" label="Send a copy of the assignment by email" fullWidth />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Create Assignment
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

FieldAdjusterAssignmentDialog.propTypes = {
  claim: requiredIf(PropTypes.object, (props) => props.open),
  onSubmitFieldAdjuster: requiredIf(PropTypes.func, (props) => props.open),
  exposure: requiredIf(PropTypes.object, (props) => props.open),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

function DeskInspectionAssignmentDialog(props) {
  const { claim, open, exposure, onClose, onSubmitDeskInspection } = props;
  const classes = useStyles();

  if (!open) {
    return <></>;
  }

  return (
    <Formik
      initialValues={{
        instructions: '',
        damage_assessment_docs_ids: [],
        send_assignment_copy: true,
      }}
      validationSchema={Yup.object().shape({
        instructions: Yup.string().required('Required'),
        damage_assessment_docs_ids: Yup.array().required().min(1, 'At least one damage document is required'),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          await onSubmitDeskInspection(values);
          formikProps.resetForm();
        } catch {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            open={open}
            title="Desk Inspection Assignment"
            maxWidth="sm"
            fullWidth
            onClose={onClose}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextFieldFormik
                  id="instructions"
                  label="Instructions"
                  className={classes.textField}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>
              <FormikEstimationDocuments
                claim={claim}
                exposureId={exposure.id}
                id="damage_assessment_docs_ids"
                disableAddingEstimation
                disableUploadPhoto
                disableDocumentDate
              />
              <ErrorHelperTextFormik id="damage_assessment_docs_ids" />
              <Grid item xs={12}>
                <CheckboxFormik id="send_assignment_copy" label="Send a copy of the assignment by email" fullWidth />
              </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Create assignment
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
}

DeskInspectionAssignmentDialog.propTypes = {
  claim: requiredIf(PropTypes.object, (props) => props.open),
  exposure: requiredIf(PropTypes.object, (props) => props.open),
  onSubmitDeskInspection: requiredIf(PropTypes.func, (props) => props.open),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

const aglFieldToViewMapping = {
  ReferenceNumber: { label: 'Reference Number', isDate: false },
  Repairer: { label: 'Repairer', isDate: false },
  DeploymentDate: { label: 'Deployment Date', isDate: true },
  WorkflowStep: { label: 'Workflow Step', isDate: false },
  AcceptedDate: { label: 'Repairer accepted', isDate: true },
  HireStartDate: { label: 'CC/HC provided', isDate: true },
  EstimateArranged: { label: 'Vehicle on site', isDate: true },
  EstimateAuthorised: { label: 'Estimate status', isDate: true },
  BID: { label: 'Booking in Date', isDate: true },
  CompletionDate: { label: 'Estimated Completion Date', isDate: true },
  ReturnedToDriver: { label: 'Date customer collects', isDate: true },
  TotalLoss: { label: 'Date TL declared', isDate: true },
};

const winnFieldToViewMapping = {
  winnsCaseRef: { label: 'Winns Case Reference', isDate: false },
  hocPI: { label: 'PI', isDate: false },
  hocHire: { label: 'Hire', isDate: false },
  hocRepairs: { label: 'Repairs', isDate: false },
  '5050Claim': { label: '50/50 Claim', isDate: false },
  workflowStep: { label: 'Workflow Step', isDate: false },
  claimNotProceedingReason: { label: 'Reason', isDate: false },
  claimNotProceedingOther: { label: 'Reason', isDate: false },
  hireStartDate: { label: 'Hire Start', isDate: true },
  hireEndDate: { label: 'Hire End', isDate: true },
  repairInstructedDate: { label: 'Repair Start', isDate: true },
  repairAuthorisedDate: { label: 'Repair Authorised', isDate: true },
  repairEndDate: { label: 'Repair End', isDate: true },
  totalLossPaymentReceived: { label: 'Total Loss Paid', isDate: false },
  liabilityDecision: { label: 'Liability', isDate: false },
  splitLiabilityWSPercentage: { label: 'Split Liability - Marshmallow', isDate: false },
  splitLiabilityDefPercentage: { label: 'Split Liability - TP', isDate: false },
};

function VendorStatusDialog(props) {
  const { open, exposure, onClose, vendorName, fieldToViewMapping } = props;
  const classes = useStyles();

  const jobStatus = getJobStatusOrNull(exposure, vendorName);

  if (!open || !jobStatus) {
    return null;
  }

  const title = vendorName.charAt(0).toUpperCase() + vendorName.slice(1) + ' job status';

  return (
    <CardDialog
      isDialog={true}
      open={open}
      title={title}
      closeOnBackdropClick={true}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
    >
      <Grid container spacing={1}>
        {Object.keys(fieldToViewMapping).map((field, idx) => (
          <Grid item xs={6} key={idx}>
            <ShowOnlyTextField
              label={fieldToViewMapping[field].label}
              classes={classes}
              showOnlyValueComponent={
                jobStatus[field] && fieldToViewMapping[field].isDate
                  ? isoUtcDateTimeToLocal(jobStatus[field])
                  : jobStatus[field]
              }
            />
          </Grid>
        ))}
      </Grid>
    </CardDialog>
  );
}

VendorStatusDialog.propTypes = {
  exposure: requiredIf(PropTypes.object, (props) => props.open),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  vendorName: PropTypes.string,
  fieldToViewMapping: PropTypes.object,
};

export { doesMoiMethodRequiresEmail, isVendorWithNoNeedForEmail, moiMethodToString };
export default MoiCard;
