import type {
  BundleClassification,
  GeneralEmailAnalysisExtra,
} from '~/components/communications/GeneralEmailCommunicationAiAnalysis/generalEmailAnalysisExtra';

export const getFnolCategoryClassification = (
  communicationAiAnalysis: GeneralEmailAnalysisExtra
): BundleClassification | undefined =>
  communicationAiAnalysis.classification_response.bundle_classification.find(
    (bundleClassification) => bundleClassification.category === 'fnol'
  );

export const doesFnolCategoryExists = (communicationAiAnalysis: GeneralEmailAnalysisExtra): boolean =>
  getFnolCategoryClassification(communicationAiAnalysis) !== undefined;
