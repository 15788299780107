import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import AlertBanner from '~/components/core/AlertBanner';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { ExposureDetailedTitle } from '~/components/exposures/ExposureDetailedTitle';
import ExposureMonetaryValueTextFieldFormik, {
  ReinsuranceShareFormik,
} from '~/components/exposures/ExposureMonetaryValueTextFieldFormik';
import { ApplyDeductibleCheckbox } from '~/components/exposures/PaymentRequestContainer/MakePaymentRequestDialog/DeductibleUtils';
import { usePaymentsConfiguration } from '~/components/hooks/usePaymentsConfiguration';
import { isOrganizationAuOrg } from '~/Utils';

import ClaimLink from '../../ClaimLink';
import { ContactShowOnlyTextField } from '../../ContactTextFieldFormik';
import { Text } from '../../core';
import { CurrencyFormatterContextProvider, useCurrencyFormatter } from '../../CurrencyFormatterContext';
import DocumentsAttachment from '../../Documents/DocumentsAttachment';
import { useCms } from '../../hooks/useCms';
import useOrganization from '../../OrganizationContext';
import { TextFieldFormik } from '../../TextFieldFormik';
import DamageAssessmentContainer from '../DamageAssessmentContainer';
import { SubReservePaymentAmountsFormik } from '../SubReservesFormik';

import PayeesFormikShowOnly from './PayeesFormikShowOnly';
import { attachedDocumentsMaxHeight } from './paymentContainerUtils';
import getMethodSpecificPaymentRequest from './PaymentMethodSpecificRequest';

import { useStyles } from '../../../assets/styles';
import styles from './PaymentRequestContainer.module.scss';

const RequestingOrDecidingAdjuster = ({ paymentRequest }) => {
  // TODO: Is this a good way to decide Requested by / Decision made by
  if (!paymentRequest?.decider_user?.username) {
    return (
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
        Requested by: {paymentRequest.requester_user.username}
      </Text>
    );
  } else {
    return (
      <Text variant={Text.VARIANTS.SM} weight={Text.WEIGHTS.REGULAR}>
        Decision made by: {paymentRequest.decider_user.username}
      </Text>
    );
  }
};

RequestingOrDecidingAdjuster.propTypes = {
  paymentRequest: PropTypes.object.isRequired,
};

const PaymentRequestAlertBanners = ({ isDetectedAsDuplicate, wasOfacHitFound, showPolicyExposureLimitDialog }) => {
  return (
    <>
      {isDetectedAsDuplicate ? (
        <div className={styles.paymentApprovalWarningWrapper}>
          <AlertBanner
            alertType={AlertBanner.ALERT_TYPES.WARNING}
            note="Potential duplicate payment detected"
            withIcon
          />
        </div>
      ) : null}
      {wasOfacHitFound ? (
        <div className={styles.paymentApprovalWarningWrapper}>
          <AlertBanner alertType={AlertBanner.ALERT_TYPES.WARNING} note="Sanctions Hit Found" withIcon />
        </div>
      ) : null}
      {showPolicyExposureLimitDialog ? (
        <div className={styles.paymentApprovalWarningWrapper}>
          <AlertBanner
            alert_type={AlertBanner.ALERT_TYPES.WARNING}
            note="Requested amount is above Policy Limit"
            withIcon
          />
        </div>
      ) : null}
    </>
  );
};

PaymentRequestAlertBanners.propTypes = {
  isDetectedAsDuplicate: PropTypes.bool.isRequired,
  wasOfacHitFound: PropTypes.bool.isRequired,
  showPolicyExposureLimitDialog: PropTypes.bool.isRequired,
};

function PaymentRequestDisplay({ paymentRequest, exposure, claim, payableType, newPaidSum, payableWithReserve }) {
  const classes = useStyles();
  const { legacyIndemnityPaymentTypes } = useOrganization();
  const { userOrganization } = useCms();

  const { PaymentDetailsComponent } = getMethodSpecificPaymentRequest(paymentRequest.payment_method, userOrganization);

  const { currencyFormatter } = useCurrencyFormatter();
  const { includeVatInPaymentScreen } = usePaymentsConfiguration();

  const vatLabel = isOrganizationAuOrg(userOrganization) ? 'GST' : 'VAT';

  return (
    <>
      <PaymentRequestAlertBanners
        isDetectedAsDuplicate={paymentRequest.is_detected_as_duplicate}
        wasOfacHitFound={paymentRequest.was_ofac_hit_found}
        showPolicyExposureLimitDialog={paymentRequest.is_override_limit}
      />
      <RequestingOrDecidingAdjuster paymentRequest={paymentRequest} />
      <div className={classes.inputContainer}>
        <ClaimLink claimId={claim.id} linkText={claim.claim_id_display} />
        {claim.policy.insured_contact && (
          <ContactShowOnlyTextField
            contactId={claim.policy.insured_contact.id}
            contactDisplayName={claim.policy.insured_contact.full_name}
            label="Insured"
          />
        )}
      </div>
      <div className={classes.inputContainer}>
        {exposure.isGeneralExpenses ? 'General Expenses' : <ExposureDetailedTitle exposure={exposure} />}
      </div>
      <SubReservePaymentAmountsFormik
        showOnly
        payableType={payableType}
        exposure={exposure}
        payableWithReserve={payableWithReserve}
      />
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <CurrencyFormatterContextProvider currency={paymentRequest?.request_currency}>
            <ExposureMonetaryValueTextFieldFormik
              id={paymentRequest?.request_amount_orig_currency ? 'request_amount_orig_currency' : 'request_amount'}
              label="Requested payment"
              className={classes.textField}
              showOnly
              exposure={exposure}
              claim={claim}
            />
          </CurrencyFormatterContextProvider>
        </Grid>
        <PaymentDetailsComponent payableType={payableType} showOnly paymentRequestStore={{}} />
        {typeof newPaidSum === 'number' ? (
          <>
            <Grid item xs={2} />
            <Grid item>
              <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
                Total payments will be {currencyFormatter.format(newPaidSum)}
              </Text>
              <ReinsuranceShareFormik id="share" exposure={exposure} claim={claim} value={newPaidSum} />
            </Grid>
          </>
        ) : null}
      </Grid>
      {includeVatInPaymentScreen && !isNil(paymentRequest.is_vat_included) && (
        <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
          {paymentRequest.is_vat_included
            ? `${vatLabel} is included: ${currencyFormatter.format(paymentRequest.vat_amount)}`
            : `${vatLabel} is not included`}
        </Text>
      )}

      <ApplyDeductibleCheckbox exposure={exposure} claim={claim} showOnly />
      {payableType === 'indemnity' &&
      legacyIndemnityPaymentTypes?.[claim.type]?.[exposure.coverage_type] &&
      paymentRequest['indemnity_payment_type'] ? (
        <TextFieldFormik
          value={
            legacyIndemnityPaymentTypes[claim.type][exposure.coverage_type][paymentRequest['indemnity_payment_type']][
              'desc'
            ]
          }
          label="Payment type"
          className={classes.textField}
          showOnly
        />
      ) : null}
      {paymentRequest.is_reissued_payment && (
        <Text weight={Text.WEIGHTS.SEMI_BOLD} variant={Text.VARIANTS.SM}>
          Reissued payment
        </Text>
      )}
      {paymentRequest.is_record_only && (
        <Text weight={Text.WEIGHTS.SEMI_BOLD} variant={Text.VARIANTS.SM}>
          Record only
        </Text>
      )}
      {payableType === 'indemnity' && (
        <>
          <div>
            <br />
          </div>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Text weight={Text.WEIGHTS.REGULAR} variant={Text.VARIANTS.SM}>
                Damage assessment:
              </Text>
            </Grid>
            <Grid item xs={2} />
            <Grid item>
              <DamageAssessmentContainer claimId={claim.id} exposure={exposure} viewOnly />
            </Grid>
          </Grid>
        </>
      )}
      <div>
        <br />
      </div>
      <PayeesFormikShowOnly
        claim={claim}
        exposure={exposure}
        paymentRequest={paymentRequest}
        payableType={payableType}
      />
      {paymentRequest.attached_document_ids.length > 0 && (
        <DocumentsAttachment
          showOnly
          attachedDocuments={claim.documents.filter((document) =>
            paymentRequest.attached_document_ids.includes(document.id)
          )}
          maxHeight={attachedDocumentsMaxHeight}
          hideAttachmentFilename
        />
      )}
      {paymentRequest.internal_attached_document_ids.length > 0 && (
        <DocumentsAttachment
          showOnly
          attachedDocuments={claim.documents.filter((document) =>
            paymentRequest.internal_attached_document_ids.includes(document.id)
          )}
          maxHeight={attachedDocumentsMaxHeight}
          hideAttachmentFilename
        />
      )}
      {paymentRequest.payment_reason && (
        <>
          <div className={classes.inputContainer}>
            <TextFieldFormik
              id="payment_reason"
              label="Payment Reason"
              rows={2}
              className={classes.textField}
              fullWidth
              multiline
              showOnly
            />
          </div>
          <div>
            <br />
          </div>
        </>
      )}
      {paymentRequest.request_note && (
        <>
          <div className={classes.inputContainer}>
            <TextFieldFormik
              id="request_note"
              label="Description"
              rows={2}
              className={classes.textField}
              fullWidth
              multiline
              showOnly
            />
          </div>
          <div>
            <br />
          </div>
        </>
      )}
    </>
  );
}

PaymentRequestDisplay.propTypes = {
  paymentRequest: PropTypes.object.isRequired,
  payableType: PropTypes.string.isRequired,
  claim: PropTypes.object.isRequired,
  exposure: PropTypes.object.isRequired,
  newPaidSum: PropTypes.number.isRequired,
  payableWithReserve: PropTypes.object.isRequired,
};

export default PaymentRequestDisplay;
