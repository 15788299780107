import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import _ from 'lodash';

import Grid from '~/components/core/Atomic/Grid/Grid';
import ExposureMonetaryValueTextFieldFormik from '~/components/exposures/ExposureMonetaryValueTextFieldFormik';

import { useExposureSubReserves } from '../hooks/useSubReserves';
import { MonetaryValueTextFieldFormik } from '../TextFieldFormik';

import { SubReservesAmountFormik } from './SubReservesFormik';

import { useStyles } from '../../assets/styles';
import colors from '../../assets/colors.module.scss';

function LegacySubReservesFields({ subReserves, id, label, showOnly, isSubmitting, wasPaymentIssued }) {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.financial_request_extra.sub_reserves && !wasPaymentIssued && !isSubmitting) {
      const sumOfSubReserves = Object.keys(values.financial_request_extra.sub_reserves).reduce(
        (total, currSubReserve) => total + values.financial_request_extra.sub_reserves[currSubReserve],
        0
      );
      setFieldValue(id, sumOfSubReserves);
    }
  }, [values.financial_request_extra.sub_reserves, setFieldValue, wasPaymentIssued, isSubmitting, id]);

  return (
    <Grid container>
      <Grid item xs={6}>
        <MonetaryValueTextFieldFormik
          id={id}
          label={`Total ${label}`}
          className={classes.textField}
          disabled={!wasPaymentIssued}
          showOnly={showOnly}
        />
      </Grid>
      <Grid item xs={6}>
        <div style={{ visibility: !wasPaymentIssued ? 'hidden' : 'visible', color: colors.statusPending }}>
          Since a payment was already done, Exposure reserve will no longer be calculated based on sub-reserves
        </div>
      </Grid>
      {subReserves.map((subReserveName) => (
        <Grid item xs={12} key={subReserveName}>
          <MonetaryValueTextFieldFormik
            id={`financial_request_extra.sub_reserves.${_.snakeCase(subReserveName)}`}
            label={subReserveName}
            className={classes.textField}
            fullWidth
            showOnly={showOnly}
          />
        </Grid>
      ))}
    </Grid>
  );
}

LegacySubReservesFields.propTypes = {
  subReserves: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  isSubmitting: PropTypes.bool.isRequired,
  wasPaymentIssued: PropTypes.bool,
};

function RequestAmountFormik({
  id,
  label,
  labelWithSubReserves,
  showOnly,
  isExceedingLimit,
  pendingMessage,
  isSubmitting,
  payableType,
  legacySubReserves,
  wasPaymentIssued,
  children,
  exposure,
  claim,
}) {
  const { isSubReservesConfigEnabled } = useExposureSubReserves(payableType, exposure);

  const PendingMessage = () => {
    const isPending = isExceedingLimit || pendingMessage;
    const message = isExceedingLimit ? 'Reserve exceeds authority level.' : pendingMessage;
    return <div style={{ visibility: isPending ? 'visible' : 'hidden ', color: colors.statusPending }}>{message}</div>;
  };

  if (legacySubReserves) {
    return (
      <div>
        <LegacySubReservesFields
          subReserves={legacySubReserves}
          id={id}
          label={label}
          isSubmitting={isSubmitting}
          wasPaymentIssued={wasPaymentIssued}
          showOnly={showOnly}
        />
        <PendingMessage />
        {children}
      </div>
    );
  }

  if (isSubReservesConfigEnabled) {
    return (
      <SubReservesAmountFormik
        isSubmitting={isSubmitting}
        id="reserve"
        label={labelWithSubReserves}
        isExceedingLimit={isExceedingLimit}
        showOnly={showOnly}
        PendingMessage={PendingMessage}
        exposure={exposure}
        payableType={payableType}
      >
        {children}
      </SubReservesAmountFormik>
    );
  }

  return (
    <Grid container>
      <Grid item xs={6}>
        <div className="flex flex-col">
          <ExposureMonetaryValueTextFieldFormik
            id={id}
            label={label}
            showOnly={showOnly}
            claim={claim}
            exposure={exposure}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        {children}
      </Grid>
      <Grid item xs={12}>
        <PendingMessage />
      </Grid>
    </Grid>
  );
}

RequestAmountFormik.propTypes = {
  id: PropTypes.string.isRequired,
  showOnly: PropTypes.bool,
  label: PropTypes.string.isRequired,
  labelWithSubReserves: PropTypes.string.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isExceedingLimit: PropTypes.bool,
  payableType: PropTypes.string.isRequired,
  legacySubReserves: PropTypes.arrayOf(PropTypes.string),
  wasPaymentIssued: PropTypes.bool,
  children: PropTypes.node,
  pendingMessage: PropTypes.string,
  exposure: PropTypes.object,
  claim: PropTypes.object.isRequired,
};

export default RequestAmountFormik;
