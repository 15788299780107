import React from 'react';

import { FileIcon } from '../icons';
import TranslatedFileIcon from '../icons/TranslatedFileIcon';
import InlineIconButton from '../InlineIconButton';

interface GenericViewDocumentButtonProps {
  isDisabled?: boolean;
  isTranslated: boolean;
  fileUrl: string;
  translatedFileUrl: string;
}

export const GenericViewDocumentButton: React.FC<GenericViewDocumentButtonProps> = ({
  isDisabled,
  isTranslated,
  fileUrl,
  translatedFileUrl,
}) => {
  return (
    <>
      <a href={isDisabled ? '#' : fileUrl} target="_blank" rel="noreferrer">
        <InlineIconButton
          tooltipTitle={
            isDisabled ? 'Document is stll uploading...' : isTranslated ? 'View original document' : 'View document'
          }
          icon={FileIcon}
          ignorePermissions
          disabled={isDisabled}
        />
      </a>
      {isTranslated && (
        <a href={isDisabled ? '#' : translatedFileUrl} target="_blank" rel="noreferrer">
          <InlineIconButton
            tooltipTitle={isDisabled ? 'Document is still uploading...' : 'View translated document'}
            icon={TranslatedFileIcon}
            ignorePermissions
            disabled={isDisabled}
          />
        </a>
      )}
    </>
  );
};

export interface ViewDocumentButtonProps {
  document: {
    id: number;
    claim_id: number;
    is_uploading: boolean;
    is_translated: boolean;
  };
}

const ViewDocumentButton: React.FC<ViewDocumentButtonProps> = ({ document }) => {
  return (
    <GenericViewDocumentButton
      isDisabled={document.is_uploading}
      isTranslated={document.is_translated}
      fileUrl={`/api/v1/claims/${document.claim_id}/documents/${document.id}`}
      translatedFileUrl={`/api/v1/claims/${document.claim_id}/documents/${document.id}/translation`}
    />
  );
};

export default ViewDocumentButton;

export interface ViewEmailAttachmentButtonProps {
  attachment: {
    id: number;
    communication_id: number;
    is_translated: boolean;
  };
}

export const ViewEmailAttachmentButton: React.FC<ViewEmailAttachmentButtonProps> = ({ attachment }) => {
  return (
    <GenericViewDocumentButton
      isTranslated={attachment.is_translated}
      fileUrl={`/api/v1/communications/emails/${attachment.communication_id}/attachments/${attachment.id}/stored_file`}
      translatedFileUrl={`/api/v1/communications/emails/${attachment.communication_id}/attachments/${attachment.id}/translation`}
    />
  );
};
