import React, { useEffect, useState } from 'react';
import requiredIf from 'react-required-if';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Formik } from 'formik';
import { noop } from 'lodash';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import { useClaim } from '~/components/ClaimContainer';
import CommunicationActionsMenu from '~/components/CommunicationActionsMenu';
import { CliveTranslationSwitch } from '~/components/communications/CliveTranslationSwitch/CliveTranslationSwitch';
import EmailCommunicationSpecificBody from '~/components/communications/EmailCommunicationCard/EmailCommunicationSpecificBody';
import { emailCommunicationToFormikValues } from '~/components/communications/EmailCommunicationCard/schemaValidations';
import { getEmailCommunicationSpecificIdentifier } from '~/components/communications/EmailCommunicationCard/utils';
import {
  getPhoneCallCommunicationSpecificIdentifier,
  PhoneCallCommunicationSpecificBody,
  phoneCallCommunicationToFormikValues,
} from '~/components/communications/PhoneCall/components/PhoneCallCommunicationCard/PhoneCallCommunicationCard';
import NewPhoneCallCommunicationCardWithClaimContext from '~/components/communications/PhoneCall/Context/NewPhoneCallCommunicationCardWithClaimContext';
import SmsCommunicationSpecificBody from '~/components/communications/SmsCommunicationCard/SmsCommunicationSpecificBody';
import {
  getSmsCommunicationSpecificIdentifier,
  smsCommunicationToFormikValues,
} from '~/components/communications/SmsCommunicationCard/utils';
import { getLanguageName } from '~/components/communications/utils';
import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import CancelButton from '~/components/core/Buttons/CancelButton';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import TooltipIcon from '~/components/core/TooltipIcon';
import useMixpanelComponentLifetime from '~/components/hooks/useMixpanelComponentLifetime';
import { PhonePlusIcon } from '~/components/icons';
import useDataFetcher from '~/components/useDataFetcher';
import { MIXPANEL_EVENTS } from '~/pocs/mixpanel';
import { reportAxiosError, reportErrorInProductionOrThrow } from '~/Utils';

import VideoCallSpecificBody from './VideoCommunication/VideoCallSpecificBody';
import AttachCommunicationContainer from './AttachCommunicationContainer';
import CommunicationCard from './CommunicationCard';
import {
  getPhysicalCommunicationSpecificIdentifier,
  PhysicalCommunicationSpecificBody,
  physicalMailCommunicationToFormikValues,
} from './PhysicalMailCommunicationCard';
import { VendorCommunicationSpecificBody, vendorCommunicationToFormikValues } from './VendorCommunicationCard';

const ReturnPhoneCallAction = ({ communication }) => {
  const { contact, claim_id, contact_phone } = communication;
  const [showReturnPhoneCallCommunicationCard, setShowReturnPhoneCallCommunicationCard] = useState(false);
  const handleOpenReturnPhoneCallCommunicationCard = () => {
    setShowReturnPhoneCallCommunicationCard(true);
  };

  const handleCloseDialog = () => {
    setShowReturnPhoneCallCommunicationCard(false);
  };

  return (
    <>
      <TooltipIcon title="Call back">
        <IconButton onClick={handleOpenReturnPhoneCallCommunicationCard} className="group p-[8px]">
          <PhonePlusIcon
            iconColor="currentColor"
            className=" text-grey-500 group-hover:text-teal-700"
            onClick={handleOpenReturnPhoneCallCommunicationCard}
            width={16}
            height={16}
          />
        </IconButton>
      </TooltipIcon>

      {showReturnPhoneCallCommunicationCard ? (
        <NewPhoneCallCommunicationCardWithClaimContext
          claimId={claim_id}
          contact={contact}
          contactPhoneId={contact_phone.id}
          handleCloseDialog={handleCloseDialog}
        />
      ) : null}
    </>
  );
};

ReturnPhoneCallAction.propTypes = {
  communication: PropTypes.object.isRequired,
};

const phoneCallBackAction = (communication, isEditing) => {
  const { claim_id } = communication;

  if (isEditing) {
    return undefined;
  }
  return claim_id && <ReturnPhoneCallAction communication={communication} />;
};

function ViewCommunicationCardContainer(props) {
  const {
    communicationId,
    isDialog,
    onClose,
    onUpdate,
    onDelete = noop,
    onRefer = noop,
    onAttach,
    onReply,
    onForward,
    onMoveToFnolQueue = noop,
    isCommunicationNotRelatedToClaimInContext,
    displayAttachClaim = true,
    displayGeneralQueueActions = false,
    hideEmailCommunicationAction = false,
    disableAdditionalActions = false,
    AdditionalDialogHeader = <React.Fragment />,
    lifeTimeMixpanelEvent = MIXPANEL_EVENTS.DEFAULT_VIEW_COMMUNICATION_LIFETIME,
    BannerBelowHeader,
    externalActionsToAdd = [],
  } = props;
  const [isEditing, setIsEditing] = React.useState(props.startIsEditing || false);
  const { claim: claimInContext } = useClaim();
  useMixpanelComponentLifetime({
    eventName: lifeTimeMixpanelEvent,
    enabled: !!lifeTimeMixpanelEvent,
    additionalProperties: { communication_id: communicationId },
  });

  const {
    isLoading,
    isError,
    data: communication,
    reloadData,
  } = useDataFetcher(
    claimInContext && !isCommunicationNotRelatedToClaimInContext
      ? `/api/v1/claims/${claimInContext.id}/communications/${communicationId}`
      : `/api/v1/communications/${communicationId}`
  );

  const [showTranslation, setShowTranslation] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(communication?.language || 'en');

  const languageOptions = React.useMemo(() => {
    if (!communication?.translated_body_html || !communication.language) {
      return null;
    }

    const languageName = getLanguageName(communication.language);
    if (!languageName) {
      reportErrorInProductionOrThrow(`Unknown language code: ${communication.language}`);
      return null;
    }

    return [
      { key: communication.language, value: communication.language, label: 'Original' },
      { key: 'en', value: 'en', label: 'Translated' },
    ];
  }, [communication]);

  useEffect(() => {
    if (communication?.language && communication?.is_translated) {
      setShowTranslation(false);
      setSelectedLanguage(communication?.language || 'en');
    }
  }, [communication]);

  if (isLoading || isError) {
    return (
      <CardDialog isDialog={isDialog} onClose={onClose} noCardTitle height="55vh">
        <SkeletonTable rowsCount={10} columnsCount={1} isError={isError} maxHeight="55vh" />
      </CardDialog>
    );
  }

  const handleUpdate = async () => {
    const updatedCommunication = await reloadData();
    if (onUpdate) await onUpdate(updatedCommunication);
  };

  const emailCommunicationActions = () => {
    if (isEditing) {
      return undefined;
    }

    return (
      <CommunicationActionsMenu
        communication={communication}
        onUpdate={handleUpdate}
        displayGeneralQueueActions={displayGeneralQueueActions}
        displayEmailCommunicationAction={!hideEmailCommunicationAction}
        onDelete={onDelete}
        onRefer={onRefer}
        onAttach={onAttach}
        onForward={onForward}
        onReply={onReply}
        onMoveToFnolQueue={onMoveToFnolQueue}
        attachClaim={displayAttachClaim}
        externalActionsToAdd={externalActionsToAdd}
      />
    );
  };

  const smsCommunicationActions = () => {
    if (isEditing) {
      return undefined;
    }

    return (
      <CommunicationActionsMenu
        communication={communication}
        onUpdate={handleUpdate}
        externalActionsToAdd={externalActionsToAdd}
      />
    );
  };

  const generalCommunicationActions = () => {
    if (isEditing) {
      return undefined;
    }

    return (
      <CommunicationActionsMenu
        communication={communication}
        onUpdate={handleUpdate}
        externalActionsToAdd={externalActionsToAdd}
      />
    );
  };

  let formikValues = { ...communication };
  // let validationSchema;
  let additionalCardActions;
  let communicationIdentifier;
  let CommunicationTypeSpecificBody;
  let leftCardActions;
  let communicationTypeSpecificBodyProps = {
    onUpdate: handleUpdate,
  };
  let CommunicationAction;
  let formikPropsToCommunicationActionProps;

  switch (communication.channel) {
    case 'email':
      formikValues = { ...formikValues, ...emailCommunicationToFormikValues(communication) };
      // validationSchema = getEmailMessageValidation(communication.direction);
      CommunicationTypeSpecificBody = EmailCommunicationSpecificBody;
      communicationIdentifier = getEmailCommunicationSpecificIdentifier(communication);
      CommunicationAction = CommunicationActionEdit;
      additionalCardActions = emailCommunicationActions();
      break;
    case 'sms':
      formikValues = { ...formikValues, ...smsCommunicationToFormikValues(communication) };
      // validationSchema = smsMessageValidation;
      CommunicationTypeSpecificBody = SmsCommunicationSpecificBody;
      communicationIdentifier = getSmsCommunicationSpecificIdentifier(communication);
      CommunicationAction = CommunicationActionEdit;
      additionalCardActions = smsCommunicationActions();
      break;
    case 'phone_call':
      formikValues = { ...formikValues, ...phoneCallCommunicationToFormikValues(communication) };
      // validationSchema = phoneCallMessageValidation;
      CommunicationTypeSpecificBody = PhoneCallCommunicationSpecificBody;
      communicationIdentifier = getPhoneCallCommunicationSpecificIdentifier(communication);
      CommunicationAction = PhoneCallCommunicationActionView;
      additionalCardActions = generalCommunicationActions();
      leftCardActions = phoneCallBackAction(communication, isEditing);
      break;
    case 'physical_mail':
      formikValues = { ...formikValues, ...physicalMailCommunicationToFormikValues(communication) };
      // validationSchema = physicalMailValidation;  - Obsolete, update if necessary according to new function
      CommunicationTypeSpecificBody = PhysicalCommunicationSpecificBody;
      communicationIdentifier = getPhysicalCommunicationSpecificIdentifier(communication);
      CommunicationAction = CommunicationActionEdit;
      additionalCardActions = generalCommunicationActions();
      break;
    case 'video_call':
      formikValues = { ...formikValues };
      CommunicationTypeSpecificBody = VideoCallSpecificBody;
      CommunicationAction = CommunicationActionEdit;
      additionalCardActions = generalCommunicationActions();
      break;
    case 'vendor':
      formikValues = { ...formikValues, ...vendorCommunicationToFormikValues(communication) };
      CommunicationTypeSpecificBody = VendorCommunicationSpecificBody;
      CommunicationAction = CommunicationActionEdit;
      additionalCardActions = generalCommunicationActions();
      break;
    default:
      throw Error(`Unknown communication channel: ${communication.channel}`);
  }

  formikPropsToCommunicationActionProps = (formikProps) => ({
    onCancelEdit: () => {
      formikProps.handleReset();
      setIsEditing(false);
    },
    onSubmit: () => {
      formikProps.handleSubmit();
    },
    isSubmitting: formikProps.isSubmitting,
  });

  // If the communication.contact is null claim_id will be as well, so it's enough to check only communication.claim_id
  const isClaimUnclassified = !communication.claim_id;
  if (isClaimUnclassified && displayAttachClaim) {
    CommunicationAction = AttachCommunicationContainer;
    formikPropsToCommunicationActionProps = () => ({
      communication,
      onAttach: async () => {
        await handleUpdate();
      },
      onAttachOverride: onAttach,
    });
  }

  const onLanguageChangeHandler = (language) => {
    setSelectedLanguage(language);
    setShowTranslation(language === 'en');
  };

  return (
    <Formik
      initialValues={formikValues}
      // validationSchema={validationSchema}  // currently there is no need for validation here
      onSubmit={async (values, formikProps) => {
        const changedValues = Object.keys(values).reduce(
          (acc, valId) => (values[valId] !== formikValues[valId] ? { ...acc, [valId]: values[valId] } : acc),
          {}
        );
        try {
          await axios.patch(`/api/v1/communications/${communication.id}`, changedValues);
          await handleUpdate();
          setIsEditing(false);
          formikProps.setSubmitting(false);
        } catch (error) {
          formikProps.setSubmitting(false);
          reportAxiosError(error);
        }
      }}
      enableReinitialize
    >
      {(formikProps) => {
        return (
          <CommunicationCard
            isDialog={isDialog}
            onClose={onClose}
            communication={communication}
            communicationIdentifier={communicationIdentifier}
            additionalCardActions={disableAdditionalActions ? null : additionalCardActions}
            CommunicationTypeSpecificBody={CommunicationTypeSpecificBody}
            communicationTypeSpecificBodyProps={communicationTypeSpecificBodyProps}
            isEditing={isEditing}
            onRequestEdit={isClaimUnclassified ? undefined : () => setIsEditing(true)}
            CommunicationAction={CommunicationAction}
            communicationActionProps={formikPropsToCommunicationActionProps(formikProps)}
            maxWidth={isDialog ? 'lg' : undefined}
            isCommunicationNotRelatedToClaimInContext={isCommunicationNotRelatedToClaimInContext}
            leftCardActions={leftCardActions}
            onCommunicationUpdate={handleUpdate}
            cardDialogContainerStyle={{ height: '100%' }}
            cardDialogContainerClassName="flex flex-col h-full"
            AdditionalDialogHeader={AdditionalDialogHeader}
            BannerBelowHeader={BannerBelowHeader}
            title={
              languageOptions ? (
                <CliveTranslationSwitch
                  selected={selectedLanguage}
                  options={languageOptions}
                  onChange={onLanguageChangeHandler}
                />
              ) : null
            }
            showTranslation={showTranslation}
          />
        );
      }}
    </Formik>
  );
}

ViewCommunicationCardContainer.propTypes = {
  communicationId: PropTypes.number.isRequired,
  isDialog: PropTypes.bool,
  onClose: requiredIf(PropTypes.func, (props) => props.isDialog),
  startIsEditing: PropTypes.bool,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onRefer: PropTypes.func,
  onAttach: PropTypes.func,
  onForward: PropTypes.func,
  onReply: PropTypes.func,
  onMoveToFnolQueue: PropTypes.func,
  //The case of communication that was detached from the claim (and maybe attached to another claim)
  isCommunicationNotRelatedToClaimInContext: PropTypes.bool,
  displayAttachClaim: PropTypes.bool,
  displayGeneralQueueActions: PropTypes.bool,
  hideEmailCommunicationAction: PropTypes.bool,
  disableAdditionalActions: PropTypes.bool,
  communication: PropTypes.shape({
    id: PropTypes.string,
    channel: PropTypes.string,
    claim_id: PropTypes.string,
  }),
  AdditionalDialogHeader: PropTypes.node,
  lifeTimeMixpanelEvent: PropTypes.string,
  BannerBelowHeader: PropTypes.node,
  externalActionsToAdd: PropTypes.array,
};

function CommunicationActionEdit(props) {
  const classes = useStyles();

  const { isEditing, onCancelEdit, onSubmit, isSubmitting } = props;

  if (!isEditing) {
    return <></>;
  }

  return (
    <div className={classes.buttonsContainer}>
      <CancelButton disabled={isSubmitting} onClick={onCancelEdit} />
      <Button variant="contained" color="primary" onClick={onSubmit} disabled={isSubmitting}>
        Update
      </Button>
    </div>
  );
}

CommunicationActionEdit.propTypes = {
  isEditing: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onCancelEdit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function PhoneCallCommunicationActionView(props) {
  const { communication, isEditing } = props;

  if (communication.channel !== 'phone_call') {
    throw Error(`PhoneCallCommunicationActionView called with ${communication.channel}`);
  }

  if (!communication.is_no_answer || isEditing) {
    return <CommunicationActionEdit {...props} />;
  }

  return <></>;
}

PhoneCallCommunicationActionView.propTypes = {
  communication: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
};

export default ViewCommunicationCardContainer;
