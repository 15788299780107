import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { get } from 'lodash';
import * as Yup from 'yup';

import DialogFooterActions from '~/components/core/DialogFooterActions';
import { getInvolvedGenericNameIfExist } from '~/components/Fnol/NewFnolUI/InvolvedParties/GeneralClaimParties';
import { useLob } from '~/components/hooks/useLob';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import LoadingDialog from '~/components/LoadingDialog';
import cn from '~/Utils/cn';

import { reportAxiosError } from '../../../../../Utils';
import { getEntityIcon } from '../../../../../Utils/ClaimUtils';
import CardDialog from '../../../../CardDialog';
import WithConfirm from '../../../../ConfirmModal';
import { PencilIcon, TrashIcon_Deprecated } from '../../../../icons';
import useCustomFields from '../../../../IncidentConfiguration/CustomFieldsContext';
import InlineIconButton from '../../../../InlineIconButton';
import { InvolvedPartiesInnerCard } from '../InvolvedPartiesInnerCard';

import { useStyles } from '../../../../../assets/styles';

const InvolvedGenericDialog = ({
  dialogAction,
  involvedGeneric,
  onCancel,
  onSubmit,
  open,
  showOnly,
  title,
  InvolvedGenericFragmentOverride,
  involvedGenericValidationFields = undefined,
}) => {
  const { additionalDataValidations } = useCustomFields();

  if (!open) {
    return <></>;
  }

  return (
    <Formik
      initialValues={involvedGeneric}
      validationSchema={Yup.object().shape({
        ...involvedGenericValidationFields,
        configured_fields_values: Yup.object().shape({
          ...additionalDataValidations,
        }),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (error) {
          reportAxiosError(error);
        }
        setSubmitting(false);
      }}
    >
      {(formikProps) => {
        const { isSubmitting, handleSubmit } = formikProps;

        return (
          <CardDialog
            isDialog={true}
            title={title}
            maxWidth="md"
            onClose={onCancel}
            preventClose={isSubmitting}
            action={dialogAction}
            footerActions={
              <DialogFooterActions
                disabled={isSubmitting}
                onClickPrimary={showOnly ? onCancel : handleSubmit}
                onClickSecondary={onCancel}
                primaryLabel={showOnly ? 'Close' : 'Save'}
                showSecondary={!showOnly}
              />
            }
          >
            {InvolvedGenericFragmentOverride}
          </CardDialog>
        );
      }}
    </Formik>
  );
};

InvolvedGenericDialog.propTypes = {
  dialogAction: PropTypes.node,
  disableEditIdentity: PropTypes.bool,
  involvedGeneric: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  open: PropTypes.bool.isRequired,
  showOnly: PropTypes.bool,
  title: PropTypes.string.isRequired,
  InvolvedGenericFragmentOverride: PropTypes.node,
  involvedGenericValidationFields: PropTypes.object,
  involvedGenericFields: PropTypes.func,
};

const EditInvolvedGenericDialog = ({
  open,
  involvedGeneric,
  onCancel,
  onSaveInvolvedDetails,
  propertyLabel,
  InvolvedGenericFragmentOverride = undefined,
  involvedGenericValidationFields = undefined,
}) => {
  return (
    <InvolvedGenericDialog
      disableEditIdentity={involvedGeneric.is_locked}
      involvedGeneric={involvedGeneric}
      onCancel={onCancel}
      onSubmit={onSaveInvolvedDetails}
      open={open}
      title={`Edit ${propertyLabel}`}
      InvolvedGenericFragmentOverride={InvolvedGenericFragmentOverride}
      involvedGenericValidationFields={involvedGenericValidationFields}
    />
  );
};

EditInvolvedGenericDialog.propTypes = {
  open: PropTypes.bool,
  involvedGeneric: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSaveInvolvedDetails: PropTypes.func.isRequired,
  propertyLabel: PropTypes.string.isRequired,
  InvolvedGenericFragmentOverride: PropTypes.func,
  involvedGenericValidationFields: PropTypes.object,
};

const InvolvedGenericSummary = ({
  onSaveInvolvedDetails,
  involvedGeneric,
  propertyLabel,
  onFetchInvolvedDetails,
  InvolvedGenericFragmentOverride = undefined,
  involvedGenericValidationFields = undefined,
}) => {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const classes = useStyles();
  const [involvedFetched, setInvolvedFetched] = React.useState();

  async function openEditDialog() {
    setEditDialogOpen(true);
    try {
      const fullInvolvedDetailsFetched = await onFetchInvolvedDetails(involvedGeneric);
      setInvolvedFetched(fullInvolvedDetailsFetched);
    } catch (error) {
      reportAxiosError(error);
      setEditDialogOpen(false);
    }
  }

  function closeEditDialog() {
    setEditDialogOpen(false);
    setInvolvedFetched(undefined);
  }

  return (
    <>
      <InlineIconButton
        icon={PencilIcon}
        tooltipTitle="Edit"
        className={cn(classes.inLineButtonsContainer, classes.buttonsContainer, classes.hoverableNonFilledIcon)}
        onClick={openEditDialog}
        wrapWithSpan
      />
      {editDialogOpen &&
        (!involvedFetched ? (
          <LoadingDialog isError={false} track="EditInvolvedGenericDialog" />
        ) : (
          <EditInvolvedGenericDialog
            open={editDialogOpen}
            involvedGeneric={involvedFetched}
            propertyLabel={propertyLabel}
            onSaveInvolvedDetails={async (involvedGeneric) => {
              await onSaveInvolvedDetails(involvedGeneric);
              closeEditDialog();
            }}
            onCancel={closeEditDialog}
            InvolvedGenericFragmentOverride={InvolvedGenericFragmentOverride}
            involvedGenericValidationFields={involvedGenericValidationFields}
          />
        ))}
    </>
  );
};

InvolvedGenericSummary.propTypes = {
  involvedGeneric: PropTypes.object.isRequired,
  propertyLabel: PropTypes.string.isRequired,
  onFetchInvolvedDetails: PropTypes.func.isRequired,
  onSaveInvolvedDetails: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  InvolvedGenericFragmentOverride: PropTypes.object,
  involvedGenericValidationFields: PropTypes.object,
};

const InvolvedGenericParty = ({
  title,
  onFetchInvolvedDetails,
  onDeleteInvolvedGenericParty,
  onSaveInvolvedGenericParty,
  propertyLabel,
  InvolvedGenericFragmentOverride = undefined,
  involvedGenericValidationFields = undefined,
  errorHelper = undefined,
  involvedGeneric,
}) => {
  const [addDialogOpen, setAddDialogOpen] = React.useState(false);
  const classes = useStyles();
  const { lob } = useLob();
  const { lobConfigurationsDict } = useLobConfiguration();
  const involvedPartiesConfig = get(lobConfigurationsDict, `${lob}.involved_parties`, []);
  const involvedIconKey = involvedPartiesConfig?.find(
    (involved) => involved?.id === involvedGeneric?.general_claim_involved_type
  )?.icon;

  const action = onDeleteInvolvedGenericParty && (
    <WithConfirm title="Delete Party?" primaryButtonName="Delete" shouldCloseOnPrimary>
      <InlineIconButton
        icon={TrashIcon_Deprecated}
        tooltipTitle="Delete"
        className={cn(classes.marginedIcon, classes.hoverableNonFilledIcon)}
        onClick={onDeleteInvolvedGenericParty}
        wrapWithSpan
      />
    </WithConfirm>
  );

  const involvedGenericNameIfExist = getInvolvedGenericNameIfExist(involvedGeneric);

  const dataToShow = [
    {
      field: 'Name',
      value: involvedGenericNameIfExist,
    },
  ];

  return (
    <>
      <InvolvedPartiesInnerCard
        title={title}
        action={action}
        Icon={involvedIconKey ? getEntityIcon(involvedIconKey) : undefined}
        iconStyle={{ paddingTop: '5px' }}
        dataToShow={dataToShow}
        onSetParty={() => setAddDialogOpen(true)}
        propertyLabel={propertyLabel}
        isSetMode={!involvedGenericNameIfExist}
        errorHelper={errorHelper}
      >
        {involvedGenericNameIfExist && (
          <InvolvedGenericSummary
            involvedGeneric={involvedGeneric}
            propertyLabel={propertyLabel}
            onFetchInvolvedDetails={onFetchInvolvedDetails}
            onSaveInvolvedDetails={async (involvedGeneric) => {
              await onSaveInvolvedGenericParty(involvedGeneric);
              setAddDialogOpen(false);
            }}
            InvolvedGenericFragmentOverride={InvolvedGenericFragmentOverride}
            involvedGenericValidationFields={involvedGenericValidationFields}
          />
        )}
      </InvolvedPartiesInnerCard>

      <InvolvedGenericDialog
        open={addDialogOpen}
        onCancel={() => setAddDialogOpen(false)}
        onSubmit={async (newInvolvedGeneric) => {
          await onSaveInvolvedGenericParty(newInvolvedGeneric);
          setAddDialogOpen(false);
        }}
        title={`Add ${propertyLabel}`}
        InvolvedGenericFragmentOverride={InvolvedGenericFragmentOverride}
        involvedGenericValidationFields={involvedGenericValidationFields}
        involvedGeneric={involvedGeneric}
      />
    </>
  );
};

InvolvedGenericParty.propTypes = {
  title: PropTypes.string.isRequired,
  propertyLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  involvedGeneric: PropTypes.object.isRequired,
  errorHelper: PropTypes.object,
  onFetchInvolvedDetails: PropTypes.func.isRequired,
  onSaveInvolvedGenericParty: PropTypes.func.isRequired,
  onDeleteInvolvedGenericParty: PropTypes.func,
  InvolvedGenericFragmentOverride: PropTypes.node,
  involvedGenericValidationFields: PropTypes.object,
};

export { InvolvedGenericParty };
