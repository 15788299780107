import { get, isEmpty } from 'lodash';

import useSafeFormikContext from '~/components/hooks/useSafeFormikContext';

import { useClaim } from '../ClaimContainer';

import { useIncidentConfiguration } from './useIncidentConfiguration';

const TYPES_CONFIG_PATH = 'incident_details.types';
const DEFAULT_TYPE = 'other';

const useIsConfigurationFieldSupportedBySubtype = () => {
  const formikProps = useSafeFormikContext();
  const { incidentConfiguration } = useIncidentConfiguration();
  const { claim } = useClaim();

  const isFieldSupportedBySubtype = (field, selectedType, selectedSubType) => {
    const supportedTypes = field?.incident_sub_type_categories || [];
    const incidentType = selectedType || formikProps?.values?.incident_type || claim?.incident?.incident_type;
    const incidentSubType =
      selectedSubType || formikProps?.values?.incident_sub_type || claim?.incident?.incident_sub_type;

    const incidentTypeConfig = get(incidentConfiguration, TYPES_CONFIG_PATH) || [];
    const incidentTypeConfigItem = incidentTypeConfig?.find((item) => item.id === incidentType);
    const incidentSubTypes = incidentTypeConfigItem?.sub_types || [];
    const supportedCategory = incidentSubTypes?.find((item) => item.id === incidentSubType)?.category;

    // if the field is not supported by any subtype, it is supported by all
    if (isEmpty(supportedTypes)) {
      return true;
    }

    // if field supports other and current incident_sub_type is not set or without type attribute, it's supported
    if (!supportedCategory && supportedTypes.includes(DEFAULT_TYPE)) {
      return true;
    }

    return supportedTypes.includes(supportedCategory);
  };

  return {
    isFieldSupportedBySubtype,
  };
};

export default useIsConfigurationFieldSupportedBySubtype;
