import React, { useEffect } from 'react';

import CollapsibleWrapper from '~/components/core/Collapsible/CollapsibleWrapper';
import InnerTabs from '~/components/core/Layout/InnerTabs/InnerTabs';
import { FnolAiInboxCollapsibleHeader } from '~/components/Fnol/FnolAiInbox/FnolAiInboxCollapsibleHeader';
import { useFnolAiInbox } from '~/components/Fnol/FnolAiInbox/hooks/useFnolAiInbox';
import { FnolAiInboxTab } from '~/components/Fnol/FnolAiInbox/tabs/FnolAiInboxTab';
import type { InboxType, OnNewLossCallback } from '~/components/Fnol/FnolAiInbox/types';

interface FnolAiInboxContainerProps {
  onNewLoss?: ({ fnolDraft, communicationId, fnolAiRecommendationId, subOrgId, lob }: OnNewLossCallback) => void;
  onLoading?: () => void;
}

export const FnolAiInboxContainer: React.FC<FnolAiInboxContainerProps> = ({ onNewLoss, onLoading }) => {
  const { inboxStatus, reloadInboxStatus, isLoading } = useFnolAiInbox({});

  const onExpandCollapse = () => {
    reloadInboxStatus();
    onLoading && onLoading();
  };

  const tabs = [
    {
      key: 'queue_tab',
      label: 'Queue',
      url: 'queue',
      component: FnolAiInboxTab,
      props: { inboxType: 'in-queue' as InboxType, onNewLoss },
    },
    {
      key: 'done_tab',
      label: 'Done',
      url: 'done',
      component: FnolAiInboxTab,
      props: { inboxType: 'done' as InboxType },
    },
  ];

  useEffect(() => {
    onLoading && onLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <CollapsibleWrapper
      title={<FnolAiInboxCollapsibleHeader count={inboxStatus?.new_items_count || 0} />}
      actionCard
      noBorder
      backgroundColor={CollapsibleWrapper.BACKGROUND_COLOR.WHITE}
      onCollapse={onExpandCollapse}
    >
      <InnerTabs tabs={tabs} renderContainerClassName="bg-white" />
    </CollapsibleWrapper>
  );
};
