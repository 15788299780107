import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useFormikContext } from 'formik';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import YesNoQuestionFormik from '~/components/core/Formik/YesNoQuestionFormik';

import { getLobFromPolicy } from '../../Utils/lobUtils';
import CardDialog from '../CardDialog';
import { ErrorHelperTextFormik } from '../core/Formik/ErrorHelperTextFormik';
import UploadDocument, { getAllowedDocumentTypes, UploadMultipleMedia } from '../Documents/DocumentCard';
import useOrganization from '../OrganizationContext';
import { usePolicy } from '../PolicyContainer';
import TextFieldFormik from '../TextFieldFormik';

import { useStyles } from '../../assets/styles';

function FnolDocumentsFormik({
  organization,
  additionalFilesList = {},
  isPhysicalEvidenceQuestionShown = true,
  isSecurityVideoFilesShown = true,
}) {
  const { setFieldValue, values } = useFormikContext();
  const classes = useStyles();
  const [showUploadPhotos, setShowUploadPhotos] = useState(false);
  const [showUploadVideos, setShowUploadVideos] = useState(false);
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [uploadedVideos, setUploadedVideos] = useState([]);
  const [uploadFileLabel, setUploadFileLabel] = useState(undefined);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { documentTypesDict } = useOrganization();
  const { policy } = usePolicy();

  const lob = getLobFromPolicy(policy);

  // additionalFilesList structure:
  // {<fileLabel> : <required_helper_text_id> || undefined}

  // eslint-disable-next-line react/prop-types
  const AddFileContainer = ({ fileLabel }) => {
    const relevantFiles = uploadedFiles.filter((file) => file.fileLabel === fileLabel);
    return (
      <Grid item>
        <Button color="primary" onClick={() => setUploadFileLabel(fileLabel)}>
          <CloudUploadIcon className={classes.leftButtonIcon} />
          {fileLabel}
        </Button>
        {relevantFiles.length > 0 && (
          <List dense>
            {relevantFiles.map((file) => (
              <ListItem key={file.id}>
                <ListItemText primary={file?.stored_file_extra?.document_name || file?.original_filename} />
              </ListItem>
            ))}
          </List>
        )}
      </Grid>
    );
  };

  return (
    <>
      <CardDialog title="Files">
        <Grid container direction="column" spacing={1}>
          {isPhysicalEvidenceQuestionShown && (
            <>
              <Grid item xs={12}>
                <YesNoQuestionFormik questionText="Was there physical evidence?" id="was_there_physical_evidence" />
              </Grid>
              {values['was_there_physical_evidence'] && (
                <>
                  <TextFieldFormik
                    id="physical_evidence_location"
                    label="Physical Evidence Location"
                    className={classes.textField}
                    fullWidth
                  />
                  <AddFileContainer fileLabel="Physical Evidence" />
                </>
              )}
            </>
          )}
          <Grid item>
            <Button color="primary" onClick={() => setShowUploadPhotos(true)}>
              <CloudUploadIcon className={classes.leftButtonIcon} />
              Photos
            </Button>
            {uploadedPhotos.length > 0 && (
              <List dense>
                {uploadedPhotos.map((photo) => (
                  <ListItem key={photo.id}>
                    <ListItemText primary={photo.stored_file_extra?.document_name || photo.original_filename} />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
          {isSecurityVideoFilesShown && (
            <Grid item>
              <Button color="primary" onClick={() => setShowUploadVideos(true)}>
                <CloudUploadIcon className={classes.leftButtonIcon} />
                Security Video
              </Button>
              {uploadedVideos.length > 0 && (
                <List dense>
                  {uploadedVideos.map((video) => (
                    <ListItem key={video.id}>
                      <ListItemText primary={video.stored_file_extra?.document_name || video.original_filename} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Grid>
          )}
          {additionalFilesList &&
            Object.keys(additionalFilesList).map((fileLabel) => (
              <Fragment key={fileLabel}>
                <AddFileContainer fileLabel={fileLabel} />
                {additionalFilesList[fileLabel] && <ErrorHelperTextFormik id={additionalFilesList[fileLabel]} />}
              </Fragment>
            ))}
        </Grid>
      </CardDialog>
      {showUploadPhotos && (
        <UploadMultipleMedia
          onClose={() => setShowUploadPhotos(false)}
          alternativeUploadUrl={`/api/v1/organizations/${organization.id}/stored_files`}
          mediaType="damage_photo"
          onSubmit={(photos) => {
            setUploadedPhotos([...uploadedPhotos, ...photos]);
            setFieldValue('stored_files_ids', [...values['stored_files_ids'], ...photos.map((photo) => photo.id)]);
            setShowUploadPhotos(false);
          }}
          lob={lob}
          uploadOutsideOfClaim
        />
      )}
      {showUploadVideos && (
        <UploadMultipleMedia
          onClose={() => setShowUploadVideos(false)}
          alternativeUploadUrl={`/api/v1/organizations/${organization.id}/stored_files`}
          mediaType="security_video"
          onSubmit={(videos) => {
            setUploadedVideos([...uploadedVideos, ...videos]);
            setFieldValue('stored_files_ids', [...values['stored_files_ids'], ...videos.map((photo) => photo.id)]);
            setShowUploadVideos(false);
          }}
          lob={lob}
          uploadOutsideOfClaim
        />
      )}
      {uploadFileLabel && (
        <UploadDocument
          onCancel={() => setUploadFileLabel(undefined)}
          alternativeUploadUrl={`/api/v1/organizations/${organization.id}/stored_files`}
          uploadOutsideOfClaim
          onSubmitDocument={(storedFile) => {
            setUploadedFiles([...uploadedFiles, { ...storedFile, fileLabel: uploadFileLabel }]);
            setFieldValue('stored_files_ids', [...values['stored_files_ids'], storedFile.id]);
            setUploadFileLabel(undefined);
            if (additionalFilesList[uploadFileLabel]) {
              setFieldValue(additionalFilesList[uploadFileLabel], true);
            }
          }}
          initialValues={{
            // This is somewhat hacky for now, should be more generic. But this UI probably gonna change soon
            type: uploadFileLabel === 'Physical Evidence' ? 'physical_evidence' : undefined,
          }}
          allowedDocumentTypes={getAllowedDocumentTypes(documentTypesDict, lob)}
        />
      )}
    </>
  );
}

FnolDocumentsFormik.propTypes = {
  organization: PropTypes.object.isRequired,
  additionalFilesList: PropTypes.object,
  isPhysicalEvidenceQuestionShown: PropTypes.bool,
  isSecurityVideoFilesShown: PropTypes.bool,
};

export default FnolDocumentsFormik;
