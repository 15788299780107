import React from 'react';

import type { ActionFooterProps } from '~/components/AiChat/SideBarDialog/Actions/Action/ActionTypes';
import { DismissButton } from '~/components/AiChat/SideBarDialog/Actions/Action/DismissButton';
import { ExecuteButton } from '~/components/AiChat/SideBarDialog/Actions/Action/ExecuteButton';
import type { PaymentTaskData } from '~/components/AiChat/types';
import { AI_ACTION_TYPES } from '~/components/AiChat/types';
import { useClaim } from '~/components/ClaimContainer';
import mixpanel from '~/components/CmsMain/mixpanel';
import InvoicePaymentDialog from '~/components/Documents/GenAiPaymentSuggestion/InvoicePaymentDialog';
import { CLIVE_MIXPANEL_EVENTS } from '~/pocs/mixpanel';

export const PaymentFooter: React.FC<ActionFooterProps> = ({ action, onExecute, onDismiss, isDone }) => {
  const { claim } = useClaim();
  const [isOpen, setIsOpen] = React.useState(false);
  const {
    invoice_doc_id,
    invoice_number,
    payment_amount,
    payment_description,
    related_exposure_id = '',
  } = action.task_data as PaymentTaskData;
  const document = claim?.documents.find((doc: { id: number }) => String(doc.id) === invoice_doc_id);

  return (
    <div className="flex">
      {onDismiss && <DismissButton onDismiss={onDismiss} disabled={isDone} />}

      <ExecuteButton
        onExecute={() => {
          mixpanel.track(CLIVE_MIXPANEL_EVENTS.ACTION_OPEN_CLICKED, {
            type: AI_ACTION_TYPES.PAYMENT,
            is_done: isDone,
          });

          setIsOpen(true);
        }}
        isDone={isDone}
      />
      <InvoicePaymentDialog
        open={!isDone && isOpen}
        setOpen={() => setIsOpen(!isOpen)}
        document={document}
        claim={claim}
        paymentInitialValues={{
          amount: payment_amount,
          invoice_number,
          note: payment_description,
        }}
        onPayment={() => onExecute && onExecute()}
        exposureId={
          claim.exposures.find(
            (exp: { claim_internal_id: number; id: number }) => exp.claim_internal_id === parseInt(related_exposure_id)
          ).id
        }
      />
    </div>
  );
};
