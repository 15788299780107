import React from 'react';

import type { IconProps } from '~/components/icons/types';

import SvgIcon from '../core/SvgIcon';

const TrayIcon: React.FC<IconProps> = ({ iconColor, ...props }) => (
  <SvgIcon viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" disableStrokeColor iconColor={iconColor} {...props}>
    <path d="M20.0547 3H5.05469C4.65686 3 4.27533 3.15804 3.99403 3.43934C3.71272 3.72064 3.55469 4.10218 3.55469 4.5V19.5C3.55469 19.8978 3.71272 20.2794 3.99403 20.5607C4.27533 20.842 4.65686 21 5.05469 21H20.0547C20.4525 21 20.834 20.842 21.1153 20.5607C21.3967 20.2794 21.5547 19.8978 21.5547 19.5V4.5C21.5547 4.10218 21.3967 3.72064 21.1153 3.43934C20.834 3.15804 20.4525 3 20.0547 3ZM20.0547 4.5V14.25H17.3641C17.1672 14.2495 16.9721 14.2881 16.7903 14.3636C16.6084 14.4391 16.4434 14.5499 16.3047 14.6897L14.4944 16.5H10.615L8.80469 14.6897C8.6659 14.5498 8.5007 14.4389 8.31867 14.3634C8.13664 14.2879 7.94143 14.2494 7.74438 14.25H5.05469V4.5H20.0547ZM20.0547 19.5H5.05469V15.75H7.74438L9.55469 17.5603C9.69347 17.7002 9.85868 17.8111 10.0407 17.8866C10.2227 17.9621 10.4179 18.0006 10.615 18H14.4944C14.6914 18.0006 14.8866 17.9621 15.0687 17.8866C15.2507 17.8111 15.4159 17.7002 15.5547 17.5603L17.365 15.75H20.0547V19.5Z" />
  </SvgIcon>
);

export default TrayIcon;
