const MGM_WC_CAUSE_CODE_DICT = {
  '175': {
    desc: 'Air Freshener',
  },
  '180': {
    desc: 'Allergic Reaction',
  },
  '185': {
    desc: 'Ambient Noise',
  },
  '190': {
    desc: 'Assault/Robbery',
  },
  '195': {
    desc: 'Bathroom Cleaner',
  },
  '200': {
    desc: 'Bending/Stooping Over',
  },
  '205': {
    desc: 'Biohazardous Exposure',
  },
  '210': {
    desc: 'Blood Borne Pathogen Exposure',
  },
  '215': {
    desc: 'Burns-Chemical',
  },
  '220': {
    desc: 'Burns-Heat',
  },
  '225': {
    desc: 'Carrying-Box(s)',
  },
  '230': {
    desc: 'Carrying-Other',
  },
  '235': {
    desc: 'Carrying-Trays',
  },
  '240': {
    desc: 'Caught in Door(s)',
  },
  '245': {
    desc: 'Caught in Furniture',
  },
  '250': {
    desc: 'Caught in Machinery',
  },
  '255': {
    desc: 'Caught in Other',
  },
  '260': {
    desc: 'Caught on Kitchen Equipment',
  },
  '265': {
    desc: 'Chemical Contact-Body Part',
  },
  '270': {
    desc: 'Cleaning Floor',
  },
  '275': {
    desc: 'Cleaning Tub/Shower',
  },
  '280': {
    desc: 'Closing/Opening Door(s)',
  },
  '285': {
    desc: 'Contact with Latex',
  },
  '290': {
    desc: 'Contact With Other (Specify)',
  },
  '295': {
    desc: 'Cumulative Trauma',
  },
  '300': {
    desc: 'Dancing/Performing',
  },
  '305': {
    desc: 'Dealing Cards',
  },
  '310': {
    desc: 'Dehydration',
  },
  '315': {
    desc: 'Disinfectant',
  },
  '320': {
    desc: 'Eating in Emp. Dining Room',
  },
  '325': {
    desc: 'Electrocution',
  },
  '330': {
    desc: 'Employee Misconduct',
  },
  '335': {
    desc: 'Exposure to (Specify)',
  },
  '340': {
    desc: 'Falling Debris-Linen Chute',
  },
  '345': {
    desc: 'Foreign Object',
  },
  '350': {
    desc: 'Foreign Object in Eye(s)',
  },
  '355': {
    desc: 'Furniture Cleaner',
  },
  '357': {
    desc: 'Heart Attack',
  },
  '360': {
    desc: 'Heat Exhaustion',
  },
  '365': {
    desc: 'Heat Exposure',
  },
  '370': {
    desc: 'Heat Stroke',
  },
  '375': {
    desc: 'Infection',
  },
  '380': {
    desc: 'Ingestion',
  },
  '385': {
    desc: 'Inhalation-Chemical Fumes',
  },
  '390': {
    desc: 'Inhalation-Dust',
  },
  '395': {
    desc: 'Inhalation-Fumes/Gas',
  },
  '400': {
    desc: 'Inhalation-Smoke',
  },
  '405': {
    desc: 'Injured by Guest',
  },
  '410': {
    desc: 'Insect/Animal-Sting/Bite',
  },
  '415': {
    desc: 'Jumping/Running',
  },
  '420': {
    desc: 'Kneeling on Floor',
  },
  '425': {
    desc: 'Kneeling-Other',
  },
  '430': {
    desc: 'Laceration-Glass Object',
  },
  '435': {
    desc: 'Laceration-Knife',
  },
  '440': {
    desc: 'Laceration-Metal Object',
  },
  '445': {
    desc: 'Laceration-Other',
  },
  '450': {
    desc: 'Laceration-Unknown Object',
  },
  '455': {
    desc: 'Laundry Chemicals',
  },
  '460': {
    desc: 'Lifting-Box(s)',
  },
  '465': {
    desc: 'Lifting-Coins',
  },
  '470': {
    desc: 'Lifting-Coin Drop(s)',
  },
  '475': {
    desc: 'Lifting-Dishes',
  },
  '477': {
    desc: 'LIFTING-EFX CABLES',
  },
  '480': {
    desc: 'Lifting-Equipment',
  },
  '482': {
    desc: 'Lifting-Furniture/Appliances',
  },
  '485': {
    desc: 'Lifting-Glasses/Cups',
  },
  '490': {
    desc: 'Lifting-Linen (Dry)',
  },
  '495': {
    desc: 'Lifting-Linen (Wet)',
  },
  '500': {
    desc: 'Lifting-Luggage',
  },
  '505': {
    desc: 'Lifting-Mattress',
  },
  '510': {
    desc: 'Lifting-Other',
  },
  '515': {
    desc: 'Lifting-Trash Bags',
  },
  '520': {
    desc: 'Lifting-Trash Recepticle',
  },
  '525': {
    desc: 'Lifting-Trays',
  },
  '530': {
    desc: 'Making Beds',
  },
  '535': {
    desc: 'Moving Equipment',
  },
  '540': {
    desc: 'Moving Furniture',
  },
  '545': {
    desc: 'Moving Other (Specify)',
  },
  '550': {
    desc: 'Multi-Purpose Cleaner',
  },
  '555': {
    desc: 'Not Applicable',
  },
  '565': {
    desc: 'Operating Equipment',
  },
  '570': {
    desc: 'Parking Car-Hit in Rear',
  },
  '575': {
    desc: 'Parking Car-Hit Object',
  },
  '580': {
    desc: 'Pre-Existing Condition',
  },
  '585': {
    desc: 'Puncture-Glass/Metal',
  },
  '590': {
    desc: 'Puncture-Needle',
  },
  '595': {
    desc: 'Puncture-Other',
  },
  '600': {
    desc: 'Push/Pull-Bell Cart',
  },
  '605': {
    desc: 'Push/Pull-Change Cart',
  },
  '610': {
    desc: 'Push/Pull-GRA Cart',
  },
  '615': {
    desc: 'Push/Pull-Laundry Hamper',
  },
  '620': {
    desc: 'Push/Pull-Linen Cart',
  },
  '625': {
    desc: 'Push/Pull-Other',
  },
  '630': {
    desc: 'Push/Pull-Trash Cart',
  },
  '635': {
    desc: 'Push/Pull-Vacuum',
  },
  '640': {
    desc: 'Reaching/Stretching',
  },
  '645': {
    desc: 'Repeated Usage',
  },
  '650': {
    desc: 'Riding Bike',
  },
  '655': {
    desc: 'Running',
  },
  '660': {
    desc: 'Sitting',
  },
  '665': {
    desc: 'Slip/Fall Bath Tub',
  },
  '670': {
    desc: 'Slip/Fall Ladder',
  },
  '675': {
    desc: 'Slip/Fall on Liquid/Water',
  },
  '680': {
    desc: 'Slip/Fall Other Substance',
  },
  '685': {
    desc: 'Slip/Trip Down Stairs/Escalator',
  },
  '690': {
    desc: 'Slip/Trip on Elevator',
  },
  '695': {
    desc: 'Slip/Trip Over Object',
  },
  '700': {
    desc: 'Slip/Trip Unknown Reason',
  },
  '705': {
    desc: 'Slip/Trip Up Stairs/Escalator',
  },
  '710': {
    desc: 'Slip/Trip/Fall-Floor',
  },
  '715': {
    desc: 'Slip/Trip/Fall-Other',
  },
  '720': {
    desc: 'Standing',
  },
  '725': {
    desc: 'Stress',
  },
  '730': {
    desc: 'Struck By Car/Truck',
  },
  '735': {
    desc: 'Struck By Cart',
  },
  '740': {
    desc: 'Struck By Door',
  },
  '745': {
    desc: 'Struck By Employee (Non Violent)',
  },
  '750': {
    desc: 'Struck By Employee (Violent)',
  },
  '755': {
    desc: 'Struck By Equipment',
  },
  '760': {
    desc: 'Struck By Falling Object',
  },
  '765': {
    desc: 'Struck By Laundry Hamper',
  },
  '770': {
    desc: 'Struck By Other (Specify)',
  },
  '775': {
    desc: 'Struck By Taxi Cab',
  },
  '780': {
    desc: 'Struck on Object',
  },
  '785': {
    desc: 'Toilet Bowl Cleaner',
  },
  '790': {
    desc: 'Unknown',
  },
  '795': {
    desc: 'Walking to/from Parking Area',
  },
  '800': {
    desc: 'Walking-Casino',
  },
  '805': {
    desc: 'Walking-Emp. Dining Room',
  },
  '810': {
    desc: 'WALKING-GRAND ADVENTURES',
  },
  '815': {
    desc: 'WALKING-GRAND GARDEN',
  },
  '820': {
    desc: 'Walking-Hallway',
  },
  '825': {
    desc: 'Walking-Other',
  },
  '830': {
    desc: 'Walking-Sidewalk',
  },
  '835': {
    desc: 'Walking-Shopping Area',
  },
  '840': {
    desc: 'Window Cleaner',
  },
  '845': {
    desc: 'Escalator',
  },
  '850': {
    desc: 'Elevator',
  },
  '855': {
    desc: 'Stairs',
  },
} as const;

export default MGM_WC_CAUSE_CODE_DICT;
